<template>
    <p class="caption mb-0" v-bind:class="{ required: isRequired }" v-if="component.is_show_label">
        {{ component.component_label }}
        {{ checkForFileTypes() ? `(${checkForFileTypes()})` : null }}
    </p>
</template>

<script>
export default {
    name: 'InputLabelComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        checkForFileTypes() {
            let fileTypes = '';

            if (this.component.allowed_file_types && this.component.allowed_file_types.length > 0) {
                this.component.allowed_file_types.forEach((fileType) => {
                    fileTypes += '.' + fileType + ' / ';
                });
            }

            return fileTypes ? fileTypes.slice(0, -3) : null;
        },
    },
};
</script>
