<template>
    <v-app id="inspire">
        <template v-if="!$route.meta.allowAnonymous">
            <app-bar-admin
                :currentUser="currentUser"
                :logoUrl="logoUrl"
                v-on:logOut="logOut"
                v-on:gotoHome="gotoHome"
                v-if="isAdmin"
            />
            <app-bar-student
                :currentUser="currentUser"
                :logoUrl="logoUrl"
                v-on:logOut="logOut"
                v-on:gotoHome="gotoHome"
                v-if="!isAdmin"
            />
            <v-main v-bind:class="{ 'admin-background': isAdmin }">
                <v-card
                    class="maintenance-message-container px-5 pt-5 rounded-12"
                    v-if="isMaintenanceMode"
                    elevation="0"
                    outlined
                >
                    <v-card-text>
                        <v-row>
                            <v-img :src="maintenance_image" max-height="50vh" contain></v-img>
                        </v-row>
                        <v-alert type="warning" class="mt-8">
                            Application system is under maintenance. We will get back to you soon
                        </v-alert>
                    </v-card-text>
                </v-card>
                <router-view class="mt-15 pt-10" v-if="!isMaintenanceMode"> </router-view>
            </v-main>
        </template>
        <template v-else>
            <v-container :class="`${$route.name != 'login' ? '' : 'ma-0 pa-0'}`">
                <app-bar-common
                    :logoUrl="logoUrl"
                    v-on:gotoHome="gotoHome"
                    v-if="$route.name != 'login'"
                ></app-bar-common>
            </v-container>
            <v-main :class="`${$route.name != 'login' ? '' : 'ma-0 pa-0'}`">
                <router-view></router-view>
            </v-main>
        </template>
        <v-overlay :value="overlay" z-index="500">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <main-footer v-if="$route.name != 'login'" />
    </v-app>
</template>

<script>
import AppBarAdmin from '../src/components/app-bar/AppBarAdmin';
import AppBarStudent from '../src/components/app-bar/AppBarStudent';
import AppBarCommon from '../src/components/app-bar/AppBarCommon';
import moment from 'moment';

export default {
    components: { AppBarAdmin, AppBarStudent, AppBarCommon },
    data() {
        return {
            snackbar: false,
            snackbarTimeout: 5000,
            light_logo_url: require('@/assets/logo/BSE_logo_horizontal_light.png'),
            dark_logo_url: require('@/assets/logo/BSE_logo_horizontal_dark.png'),
            maintenance_image: require('@/assets/maintenance.svg'),
        };
    },
    watch: {
        notifications(payload) {
            if (payload && payload.length) {
                payload.forEach((notification) => {
                    this.$toast.open({
                        message: notification.message,
                        type: notification.type,
                        duration: this.snackbarTimeout,
                    });
                });
                setTimeout(() => {
                    this.$store.commit('common/REMOVE_NOTIFICATIONS');
                }, this.snackbarTimeout);
            }
        },

        primaryColor(color) {
            this.$vuetify.theme.themes.light.primary = color;
            document.querySelector(':root').style.setProperty('--bodyPrimary', color);
        },

        secondaryColor(color) {
            this.$vuetify.theme.themes.light.secondary = color;
        },
    },

    computed: {
        notifications() {
            return this.$store.state.common.notifications;
        },

        site() {
            return this.$store.state.common.site;
        },

        currentUser() {
            return this.$store.state.auth.user;
        },

        isAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return ['ADMIN', 'EDITOR'].includes(this.currentUser.role[0].name);
            }

            return false;
        },

        logoUrl() {
            const logoLight = this.$store.getters['common/logoLight'];
            const logoDark = this.$store.getters['common/logoDark'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? this.isAdmin
                    ? logoLight && logoLight.value && logoLight.value !== ''
                        ? logoLight.value
                        : this.light_logo_url
                    : logoDark && logoDark.value && logoDark.value !== ''
                    ? logoDark.value
                    : this.dark_logo_url
                : undefined;
        },
        overlay() {
            return this.$store.state.common.pendingRequestsCount > 0;
        },
        applicationHeading() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? applicationHeading && applicationHeading.value !== ''
                    ? applicationHeading.value
                    : 'Application System'
                : undefined;
        },
        isMaintenanceMode() {
            return this.$store.getters['common/performMaintenanceMode'];
        },
        primaryColor() {
            const primaryColor = this.$store.getters['common/primaryColor'];
            return primaryColor ? primaryColor.value : '#00859b';
        },
        secondaryColor() {
            const secondaryColor = this.$store.getters['common/secondaryColor'];
            return secondaryColor ? secondaryColor.value : '#df7a0e';
        },
    },

    methods: {
        gotoHome() {
            this.$router.push(`/${this.site}/`);
        },

        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push(`/${this.site}/login`);
        },
    },
    beforeMount() {
        const { utm_source, utm_medium, utm_campaign, utm_content } = this.$route.query;
        const utmData = {
            utmsource: utm_source || null,
            utmmedium: utm_medium || null,
            utmcampaign: utm_campaign || null,
            utmcontent: utm_content || null,
            // utmdate:
            //     utm_source || utm_medium || utm_campaign || utm_content
            //         ? moment().format('YYYY-MM-DD HH:mm:ss')
            //         : null,
        };
        this.$store.commit('auth/setUtmData', utmData);
        this.$store.dispatch('common/loadSettings');
    },
    mounted() {
        if (window.location.host === 'apply.bse.eu') {
            // <!-- Google Tag Manager -->
            let googleScriptHead = document.createElement('script');
            googleScriptHead.append(
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W4N7H8V');"
            );
            document.head.appendChild(googleScriptHead);
            // <!-- End Google Tag Manager -->

            // <!-- Google Tag Manager (noscript) -->
            let googleBodyIframe = document.createElement('iframe');
            googleBodyIframe.setAttribute(
                'src',
                'https://www.googletagmanager.com/ns.html?id=GTM-W4N7H8V'
            );
            googleBodyIframe.setAttribute('height', 0);
            googleBodyIframe.setAttribute('width', 0);
            googleBodyIframe.setAttribute('style', 'display:none;visibility:hidden');

            let googleBodyNoScript = document.createElement('noscript');
            googleBodyNoScript.appendChild(googleBodyIframe);

            document.body.appendChild(googleBodyNoScript);
            // <!-- End Google Tag Manager (noscript) -->

            // Linkedin analytics
            let linkedinAnalytics1 = document.createElement('script');
            linkedinAnalytics1.setAttribute('type', 'text/javascript');
            linkedinAnalytics1.append(
                '_linkedin_partner_id = "3083977"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);'
            );
            document.head.appendChild(linkedinAnalytics1);

            let linkedinAnalytics2 = document.createElement('script');
            linkedinAnalytics2.setAttribute('type', 'text/javascript');
            linkedinAnalytics2.append(
                '(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);'
            );
            document.head.appendChild(linkedinAnalytics2);

            let linkedinImg = document.createElement('img');
            linkedinImg.setAttribute(
                'src',
                'https://px.ads.linkedin.com/collect/?pid=3083977&fmt=gif'
            );
            linkedinImg.setAttribute('height', 1);
            linkedinImg.setAttribute('width', 1);
            linkedinImg.setAttribute('style', 'display:none;');

            let linkedinNoScript = document.createElement('noscript');
            linkedinNoScript.appendChild(linkedinImg);

            document.body.appendChild(linkedinNoScript);
        }
    },

    created() {
        this.$store.dispatch('common/setSite', this.$route.params.site);
    },
};
</script>
