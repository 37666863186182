import { HTTP } from './http-common';

export default {
    getJourneys() {
        return HTTP.get('journey');
    },
    getDeletedJourneys() {
        return HTTP.get('journey?deleted=true');
    },
    searchJourneys(keyword) {
        return HTTP.get('journey?keyword=' + keyword);
    },
    searchDeletedJourneys(keyword) {
        return HTTP.get('journey?deleted=true&keyword=' + keyword);
    },
    getJourneyByUuid(uuid) {
        return HTTP.get(`journey/${uuid}`);
    },
    getJourneyTypes() {
        return HTTP.get('journey-types');
    },
    getAcademicYears() {
        return HTTP.get('common/get-academic-years');
    },
    getEntityTypes() {
        return HTTP.get('common/get-entity-types');
    },
    saveJourney(data) {
        return HTTP.post('journey/create', data);
    },
    updateJourney(data) {
        return HTTP.put(`journey/${data.uuid}`, data);
    },
    deleteJourney(uuid) {
        return HTTP.delete(`journey/${uuid}`);
    },
    changeOrder(action, uuid) {
        return HTTP.get(`journey/${uuid}/${action}`);
    },
    saveJourneyData(data) {
        return HTTP.post(`journey/save-journey-json/${data.uuid}`, data.formData);
    },
    editApplication(applicationId) {
        return HTTP.get(`application/edit/${applicationId}`);
    },
    cloneJourney(journey) {
        return HTTP.post(`journey/${journey.uuid}/clone`, journey);
    },
    restoreDeleteJourney(journey) {
        return HTTP.get(`journey/deleted-journey/${journey.uuid}/restore`);
    },
    getPrograms(data) {
        return HTTP.post(`journey/get-programs`, data);
    },
};
