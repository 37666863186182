import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import axios from 'axios';
import VueMoment from 'vue-moment';
import VueToast from 'vue-toast-notification';

Vue.prototype.$http = axios;

import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(VueToast);

import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);

//Main style sheet
import './sass/main.scss';

// permission directive
import permission from './directives/permission/permission';
Vue.directive('permission', permission);
// use as this in any location:  v-permission="['journey-list']"

import VueCryptojs from 'vue-cryptojs';
Vue.use(VueCryptojs);

Vue.component('main-footer', require('./components/footer/Footer.vue').default);
Vue.component('input-label', require('./components/application/components/InputLabel.vue').default);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    VueMoment,
    render: (h) => h(App),
}).$mount('#app');
