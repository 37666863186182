<template>
    <v-app-bar app flat height="80" color="white" elevation="0">
        <a @click="triggerGotoHome" v-show="logoUrl">
            <div class="d-none d-sm-block">
                <img :src="logoUrl" alt="logo" height="70" class="main-logo" />
            </div>
            <div class="d-sm-none">
                <img :src="logoUrl" alt="logo" height="40" class="main-logo" />
            </div>
        </a>
        <v-spacer></v-spacer>

        <v-menu offset-y content-class="app-menu">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3 mr-2" icon v-on="on" v-bind="attrs">
                    <v-avatar size="44">
                        <v-icon large> mdi-account-outline </v-icon>
                    </v-avatar>
                </v-btn>
            </template>
            <v-list dense class="secondary-font list-compressed" max-width="250" elevation="0">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text font-weight-bold">
                            {{ currentUser.first_name }} {{ currentUser.last_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="tiny-text">
                            {{ currentUser.email }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :to="`/${site}/user/profile`">
                    <v-list-item-content>
                        <v-list-item-title>Update Personal Data</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item :to="`/${site}/user/change-email`">
                    <v-list-item-content>
                        <v-list-item-title>Change email address</v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item :to="`/${site}/user/change-password`">
                    <v-list-item-content>
                        <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click.prevent="triggerLogout">
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: 'AppBarStudent',
    props: {
        logoUrl: {
            type: String,
            default: null,
        },
        currentUser: {
            type: Object,
            default: null,
        },
    },

    computed: {
        site() {
            return this.$store.state.common.site;
        },
    },

    methods: {
        triggerLogout() {
            this.$emit('logOut');
        },

        triggerGotoHome() {
            this.$emit('gotoHome');
        },
    },
};
</script>
