<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-radio-group
            :ref="component.component_name"
            class="mt-0"
            solo
            flat
            outlined
            :value="setDefaultValue()"
            @change="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            :disabled="enability"
        >
            <v-radio
                :label="data.name"
                :value="data.value"
                v-for="(data, index) in component.component_data_set"
                :key="index"
            ></v-radio>
        </v-radio-group>
    </div>
</template>

<script>
export default {
    name: 'RadioComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        setDefaultValue() {
            let defaultValue = this.component.component_default_value;

            if (!defaultValue) {
                let dataSet = this.component.component_data_set;

                if (dataSet && dataSet.length > 0) {
                    let filteredValue = dataSet.filter((value) => {
                        return value.default === true;
                    });

                    if (filteredValue && filteredValue.length > 0) {
                        return filteredValue[0].value;
                    }
                }

                return null;
            }

            return defaultValue;
        },

        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },

    mounted() {
        if (this.component) {
            setTimeout(() => {
                this.inputChanged();
            }, 1000);
        }
    },
};
</script>
