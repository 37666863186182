<template>
    <v-dialog v-model="openDialog" persistent width="600px" height="600px">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 red--text font-weight-black mb-0">Validation Errors</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="secondary-font pb-5">
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(error, key) in errorList" :value="key" :key="key">
                        <v-expansion-panel-header class="font-weight-bold">{{
                            key
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <ul class="secondary-font">
                                <li v-for="(body, key) in error" :value="key" :key="key">
                                    {{ body.section }}
                                    <ul>
                                        <li
                                            v-for="(item, index) in body.components"
                                            :value="key"
                                            :key="index"
                                        >
                                            {{ item }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ValidationPopup.component',
    props: {
        openDialog: Boolean,
        errorList: Object,
    },
    data: () => ({}),
    methods: {
        close() {
            this.$emit('close-error-dialog');
        },
    },
};
</script>

<style scoped></style>
