export default {
    setPasswordResetEmail: (state, email) => {
        state.passwordResetEmail = email;
    },
    loginSuccess(state, payload) {
        state.status.loggedIn = true;
        state.user = payload;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    registerSuccess(state) {
        state.status.loggedIn = false;
    },
    registerFailure(state) {
        state.status.loggedIn = false;
    },
    loginAsUser(state, payload) {
        state.contactId = payload;
    },
    setUtmData(state, payload) {
        state.utmData = payload;
    },
};
