<template>
    <v-container>
        <v-card class="mx-auto rounded-12" flat>
            <v-card-text class="px-6">
                <p class="text-h3 secondary-font mt-3">Untitled Journey</p>
            </v-card-text>
        </v-card>
        <v-row class="mt-4">
            <v-col cols="12" md="4">
                <v-card class="mx-auto rounded-12" flat>
                    <v-card-text class="px-6 secondary-font">
                        <v-list dense expand class="journey-list">
                            <TabItem
                                v-for="(journey, index) in journeys"
                                :key="index"
                                :journey="journey"
                            />
                        </v-list>
                        <v-btn outlined color="primary" block large>
                            <v-icon left> mdi-plus-circle-outline </v-icon>
                            Add Tab
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-card class="rounded-12" flat>
                    <v-card-text>
                        <div class="d-flex mb-5" v-for="(section, index) in sections" :key="index">
                            <div class="mr-2 d-flex flex-column">
                                <v-btn x-small text icon color="darkGray">
                                    <v-icon x-small>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn x-small text icon color="darkGray">
                                    <v-icon x-small>mdi-arrow-down</v-icon>
                                </v-btn>
                            </div>
                            <JourneySectionInput
                                v-if="section.hide"
                                v-on:click.native="show(index)"
                                :section="section"
                            />
                            <JourneySection v-if="!section.hide" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import JourneySection from '../../components/journey/Section';
import JourneySectionInput from '../../components/journey/SectionInput';
import TabItem from '../../components/journey/items/Tab';

export default {
    components: {
        JourneySection,
        JourneySectionInput,
        TabItem,
    },
    data: () => ({
        journeys: [
            {
                tab: 'Tab One',
                sections: ['Section One', 'Section Two', 'Section Three'],
            },
            {
                tab: 'Tab Two',
                sections: ['Section One', 'Section Two'],
            },
            {
                tab: 'Tab Three',
                sections: ['Section One', 'Section Two', 'Section Three', 'Section Four'],
            },
        ],
        sections: [
            {
                id: 1,
                name: 'Section 1',
                hide: true,
            },
            {
                id: 2,
                name: 'Section 2',
                hide: true,
            },
            {
                id: 3,
                name: 'Section 3',
                hide: true,
            },
        ],
    }),
    methods: {
        show(currentIndex) {
            this.sections[currentIndex].hide = !this.sections[currentIndex].hide;

            this.sections.forEach((section, index) => {
                if (index !== currentIndex) {
                    section.hide = true;
                }
            });
        },
    },
};
</script>
