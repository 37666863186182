import UserService from '../../../services/user-service';
import notificationsUtility from '../../../utilities/notifications-utility';

export default {
    loadUsers({ commit }) {
        UserService.getUsers().then((response) => {
            commit('setUsersList', response.data.data);
        });
    },
    updateStatus({ commit }, payload) {
        UserService.updateStatus(payload).then((response) => {
            if (response.data.status_code === 200) {
                commit('updateUser', response.data.data);
                notificationsUtility.showNotifications(response.data);
            }
        });
    },
    updateRole({ commit }, payload) {
        UserService.updateRole(payload).then((response) => {
            if (response.data.status_code === 200) {
                commit('updateUser', response.data.data);
                notificationsUtility.showNotifications(response.data);
            }
        });
    },
};
