import { HTTP } from './http-common';

export default {
    getJourneyTypes() {
        return HTTP.get('journey-types');
    },
    saveJourneyType(data) {
        return HTTP.post('journey-types', data);
    },
    updateJourneyType(data, uuid) {
        return HTTP.put(`journey-types/${uuid}`, data);
    },
    deleteJourneyType(uuid) {
        return HTTP.delete(`journey-types/${uuid}`);
    },
};
