import { HTTP } from './http-common';

export default {
    getPeriods() {
        return HTTP.get('common/get-academic-years');
    },
    savePeriod(data) {
        return HTTP.post('academic-year', data);
    },
    updatePeriod(data, uuid) {
        return HTTP.put(`academic-year/${uuid}`, data);
    },
    deletePeriod(uuid) {
        return HTTP.delete(`academic-year/${uuid}`);
    },
};
