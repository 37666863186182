import { HTTP } from './http-common';

export default {
    getPrograms() {
        return new Promise((resolve, reject) => {
            HTTP.get(`programs/null`)
                .then((res) => {
                    if (res && res.data && res.data.status_code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => reject(err));
        });
    },
    getUserApplications(payload) {
        return new Promise((resolve, reject) => {
            HTTP.post('application/get-user-applications', payload)
                .then((res) => {
                    if (res && res.data && res.data.status_code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => reject(err));
        });
    },
};
