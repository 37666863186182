<template>
    <div>
        <v-card class="mx-auto rounded-lg" elevation="0">
            <v-card-text class="secondary-font pa-0">
                <v-data-table
                    :headers="headers"
                    :items="payments"
                    :items-per-page="-1"
                    class="elevation-0 noborder-table body-primary"
                    hide-default-footer
                >
                    <template v-slot:[`item.deadline`]="{ item }">
                        {{ item.deadline ? moment(item.deadline).format('DD-MM-YYYY') : '' }}
                    </template>
                    <template v-slot:[`item.paymentDate`]="{ item }">
                        <p class="mb-0 d-flex flex-column align-sm-start d-xs-block align-center">
                            <span>
                                {{
                                    item.paymentDate
                                        ? moment(item.paymentDate).format('DD-MM-YYYY HH:mm:ss')
                                        : ''
                                }}
                            </span>
                            <span> {{ item.amountDone ? `${item.amountDone} €` : '' }} </span>
                        </p>
                    </template>
                    <template v-slot:[`item.type`]="{ item }">
                        <p class="mb-0 d-flex flex-column align-sm-start d-xs-block align-center">
                            <span> {{ item.type }} </span>
                            <span> {{ getPaymentAmountConcept(item) }} € </span>
                        </p>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            small
                            :color="getColor(item)"
                            class="text-capitalize lighten-1"
                            dark
                        >
                            {{ getStatus(item) }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <a
                            v-bind:class="{
                                'text-decoration-underline font-weight-bold':
                                    selectedPayment === item,
                            }"
                            v-if="getStatus(item) == 'Pending'"
                            @click="showPaymentOptions(item)"
                        >
                            Make payment ({{ getPaymentAmount(item) }} €)
                        </a>
                    </template>
                </v-data-table>

                <div v-if="Object.keys(selectedPayment).length > 0" class="mt-5">
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-card class="mx-auto" outlined>
                                <v-card-text class="text-center primary--text">
                                    <p>Pay in euros, by debit/credit card</p>
                                    <form
                                        ref="redsysForm"
                                        :action="actionUrl"
                                        method="post"
                                        id="redsysForm"
                                        name="redsysForm"
                                        target="_blank"
                                    >
                                        <input
                                            type="text"
                                            name="Ds_SignatureVersion"
                                            v-model="dsSignatureVersion"
                                            hidden
                                        />
                                        <input
                                            type="text"
                                            name="Ds_MerchantParameters"
                                            v-model="dsMerchantParameters"
                                            hidden
                                        />
                                        <input
                                            type="text"
                                            name="Ds_Signature"
                                            v-model="dsSignature"
                                            hidden
                                        />
                                        <input
                                            type="text"
                                            name="Ds_PaymentId"
                                            v-model="paymentId"
                                            hidden
                                        />
                                        <v-btn
                                            color="secondary"
                                            dark
                                            class="text-capitalize mt-3"
                                            elevation="0"
                                            @click.prevent="checkPayment()"
                                        >
                                            Pay with Card
                                        </v-btn>
                                    </form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-card class="mx-auto" outlined>
                                <v-card-text class="text-center primary--text">
                                    <p>
                                        Pay in any currency (including euro), by debit/credit card,
                                        or bank transfer
                                    </p>

                                    <v-btn
                                        color="secondary"
                                        dark
                                        class="text-capitalize mt-3"
                                        elevation="0"
                                        @click="payFlywire"
                                    >
                                        Pay through Flywire
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <v-alert
                    v-if="paymentStatus"
                    dense
                    text
                    :color="paymentStatus.status === 'success' ? 'green' : 'red'"
                    outlined
                    class="outlined-alert text-center secondary-font mt-5"
                    dismissible
                >
                    Payment
                    {{ paymentStatus.status === 'success' ? 'successful' : 'unsuccessful' }} !
                </v-alert>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import commonService from '../../../../services/common-service';
import paymentService from '../../../../services/payment-service';
import notificationsUtility from '../../../../utilities/notifications-utility';
import moment from 'moment';

export default {
    name: 'Payment',
    props: {
        activeTab: {
            type: Number,
            default: 0,
        },
        component: {
            type: [Object, Array],
            default: null,
        },
    },
    data() {
        return {
            moment,
            headers: [
                { text: 'Deadline', value: 'deadline', sortable: false },
                { text: 'Paid', value: 'paymentDate', sortable: false },
                { text: 'Concept', value: 'type', sortable: false },
                { text: 'Method', value: 'method', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: '', value: 'action', sortable: false },
            ],

            payments: [],
            selectedPayment: {},

            // Red Sys
            paymentData: {
                DS_MERCHANT_AMOUNT: '',
                DS_MERCHANT_CURRENCY: '978',
                DS_MERCHANT_MERCHANTCODE: '346252166',
                DS_MERCHANT_MERCHANTURL: `${process.env.VUE_APP_API_URL}payments/redsys/createOrder`,
                DS_MERCHANT_ORDER: '12341234',
                DS_MERCHANT_TERMINAL: process.env.MERCHANT_TERMINAL,
                DS_MERCHANT_TRANSACTIONTYPE: '0',
                DS_MERCHANT_URLKO: `${process.env.VUE_APP_BASE_URL}payment/response/error`,
                DS_MERCHANT_URLOK: `${process.env.VUE_APP_BASE_URL}payment/response/success`,
                DS_MERCHANT_TITULAR: '',
            },
            dsMerchantParameters: null,
            dsSignature: null,
            dsSignatureVersion: null,
            paymentStatus: null,
            pendingPayments: 'Has payments',
            paymentId: null,
            actionUrl: process.env.REDSYS_URL,
        };
    },

    methods: {
        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.pendingPayments,
                is_disable: false,
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: false,
                is_hidden: false,
                value: this.pendingPayments,
            };

            this.$emit('appearanceInputChanged', conditions);
        },

        getStatus(item) {
            return item.paymentDate
                ? item.paymentDone === 'Cancelled'
                    ? 'Cancelled'
                    : 'Done'
                : 'Pending';
        },

        getColor(item) {
            let status = this.getStatus(item);
            if (status == 'Pending') return 'orange';
            if (status == 'Cancelled') return 'red';
            else return 'green';
        },

        getPayments() {
            commonService
                .get(`/application/payments/${this.entity}/${this.applicationId}`)
                .then((response) => {
                    if (response) {
                        this.payments = response.data;

                        this.checkForPendingPayments();

                        let paymentData = localStorage.getItem('paymentData')
                            ? JSON.parse(localStorage.getItem('paymentData'))
                            : null;

                        this.paymentStatus = paymentData;
                        localStorage.removeItem('paymentData');
                    }
                })
                .catch(() => {
                    this.payments = [];
                });
        },

        showPaymentOptions(selectedOption) {
            this.selectedPayment = selectedOption;
            this.paymentId = selectedOption.id;
        },

        payFlywire() {
            paymentService
                .getFlyWirePaymentOrder({
                    ...this.selectedPayment,
                    entity: this.entity,
                    applicationIdField: this.component.component_crm_field,
                })
                .then((response) => {
                    if (response && response.data && response.data.data) {
                        window.open(response.data.data.payment_url, '_blank');
                    }
                })
                .catch((error) => {
                    notificationsUtility.showNotifications(error.response.data);
                });
        },

        async checkPayment() {
            let encodeData = await this.encodePayment();
            if (encodeData) {
                let paymentData = {
                    url: `${process.env.VUE_APP_BASE_URL}${this.$route.fullPath.substring(1)}`,
                    activeTab: this.activeTab,
                };

                localStorage.setItem('paymentData', JSON.stringify(paymentData));
                this.$refs.redsysForm.submit();
            }
        },

        async encodePayment() {
            try {
                this.priceForRedsys();
                this.paymentData.DS_MERCHANT_ORDER = Math.floor(
                    Math.random() * 100000001
                ).toString();

                this.paymentData.DS_MERCHANT_MERCHANTDATA =
                    this.paymentId.toString() + ',' + this.applicationId;

                this.paymentData.DS_MERCHANT_TITULAR = this.contactName;
                this.paymentData.DS_MERCHANT_URLKO = `${process.env.VUE_APP_BASE_URL}${this.site}/payment/response/error`;
                this.paymentData.DS_MERCHANT_URLOK = `${process.env.VUE_APP_BASE_URL}${this.site}/payment/response/success`;

                // Lets make a string encoded in Base64 with the object width the data
                let merchant = this.$CryptoJS.enc.Utf8.parse(JSON.stringify(this.paymentData));

                this.dsMerchantParameters = merchant.toString(this.$CryptoJS.enc.Base64);

                // Decode from Base 64 the secret key of the commerce from Redsys
                let key = this.$CryptoJS.enc.Base64.parse(process.env.SHA_256_KEY);

                // Encrypt the key diversified with the order number in TripleDes
                let iv = this.$CryptoJS.enc.Hex.parse('0000000000000000');
                let cipher = this.$CryptoJS.TripleDES.encrypt(
                    this.paymentData.DS_MERCHANT_ORDER,
                    key,
                    {
                        iv: iv,
                        mode: this.$CryptoJS.mode.CBC,
                        padding: this.$CryptoJS.pad.ZeroPadding, // Redsys dont like padding
                    }
                );

                // Signature HASH width de merchant parameters in Base 64 and the TripleDes
                let signature = this.$CryptoJS.HmacSHA256(
                    this.dsMerchantParameters,
                    cipher.ciphertext
                );

                this.dsSignature = signature.toString(this.$CryptoJS.enc.Base64);
                this.dsSignatureVersion = 'HMAC_SHA256_V1';

                return true;
            } catch (error) {
                return false;
            }
        },

        priceForRedsys() {
            let priceN = parseFloat(this.getPaymentAmount(this.selectedPayment));

            if (Number.isInteger(priceN)) {
                this.paymentData.DS_MERCHANT_AMOUNT = priceN.toString() + '00';
            } else {
                let priceFixed = priceN.toFixed(2).toString();
                let priceString = priceFixed.replace('.', '');
                this.paymentData.DS_MERCHANT_AMOUNT = priceString;
            }
        },

        getPaymentAmountConcept(payment) {
            return payment.earlyBird
                ? payment.paymentDate
                    ? moment(payment.paymentDate).isSameOrBefore(
                          moment(payment.earlyBird).endOf('day')
                      )
                        ? payment.amountEarly
                        : payment.amount
                    : moment().isSameOrBefore(moment(payment.earlyBird).endOf('day'))
                    ? payment.amountEarly
                    : payment.amount
                : payment.amount;
        },

        getPaymentAmount(payment) {
            return payment.earlyBird &&
                moment().isSameOrBefore(moment(payment.earlyBird).endOf('day'))
                ? payment.amountEarly
                : payment.amount;
        },

        checkForPendingPayments() {
            this.payments.every((payment) => {
                if (!payment.paymentDate) {
                    this.pendingPayments = null;
                    return false;
                }
            });

            this.inputChanged();
        },
    },
    computed: {
        entity() {
            return this.$store.state.common.curentApplication
                ? this.$store.state.common.curentApplication.entity
                : null;
        },
        applicationId() {
            return this.$store.state.common.curentApplication
                ? this.$store.state.common.curentApplication.applicationID
                : null;
        },
        contactName() {
            let logAsUserStatus = localStorage.getItem('log_as_user_status');
            let logAsUser = JSON.parse(localStorage.getItem('log_as_user'));

            return logAsUserStatus && logAsUserStatus === 'true'
                ? logAsUser.name
                : this.$store.state.auth.user.name;
        },
        site() {
            return this.$store.state.common.site;
        },
    },

    mounted() {
        this.getPayments();
    },
};
</script>
