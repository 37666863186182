import axios from 'axios';
import { baseURL } from '../config/setting';
import router from '../router';
import store from '../store';
import notificationsUtility from '../utilities/notifications-utility';

export const HTTP = axios.create({
    baseURL: baseURL,
});

HTTP.interceptors.request.use(
    async (config) => {
        config.headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/json',
            IsAdmin: store.getters['auth/isAdmin'],
            Site: store.state.common.site,
        };
        store.commit('common/ADD_REQUEST');
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

HTTP.interceptors.response.use(
    (response) => {
        store.commit('common/REMOVE_REQUEST');
        return response;
    },
    (error) => {
        store.commit('common/REMOVE_REQUEST');
        if (error.response.data.message === 'Unauthenticated.') {
            if (store.state.auth.status.loggedIn) {
                if (store.state.common.sessionTimeOutMessage) {
                    notificationsUtility.pushNotifications([
                        { message: store.state.common.sessionTimeOutMessage.message, type: 'info' },
                    ]);
                } else {
                    notificationsUtility.pushNotifications([
                        { message: 'Log in required', type: 'info' },
                    ]);
                }

                store.dispatch('auth/logout');
            }
            let currentSite = router.currentRoute.params.site
                ? router.currentRoute.params.site
                : 'apply';
            router.push({ path: `/${currentSite}/login`, query: router.currentRoute.query });
        } else if (error.response.status === 503) {
            store.commit('common/ACTIVATE_MAINTENANCE_MODE');
        } else {
            return Promise.reject(error);
        }
    }
);
