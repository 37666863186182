<template>
    <v-dialog :value="dialog" :width="dialogWidth" @click:outside="resetForm">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 primary--text font-weight-black mb-0">Create System Message</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="resetForm">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <SystemMessageForm :handleSave="handleSave" :systemMessage="formData" />
        </v-card>
    </v-dialog>
</template>

<script>
import SystemMessageForm from './SystemMessageForm.component.vue';
import NotificationService from '../../services/notification-service';
import notificationsUtility from '../../utilities/notifications-utility';

export default {
    name: 'CreateSystemMessagePopUp',
    components: {
        SystemMessageForm,
    },
    props: ['dialog', 'handleClose'],
    data() {
        return {
            dialogWidth: 1000,
            formData: {
                module: null,
                name: null,
                sub_module: null,
                type: null,
                message: null,
            },
        };
    },
    methods: {
        handleSave() {
            NotificationService.addSystemMessage({
                ...this.formData,
                module: this.formData.module.value,
                sub_module: this.formData.sub_module.value,
            })
                .then((response) => {
                    this.$store.commit('notification/addSystemMessage', response.data.data);
                    notificationsUtility.showNotifications(response.data.meta);
                    this.resetForm();
                })
                .catch((error) => {
                    notificationsUtility.showNotifications(error.response.data);
                });
        },
        resetForm() {
            Object.assign(this.$data, this.$options.data());
            this.handleClose();
        },
    },
};
</script>
