<template>
    <v-row>
        <v-col cols="12" xs="12" sm="12" md="12">
            <v-switch
                label="Show Label"
                v-model="is_show_label"
                hide-details
                class="mr-4 mt-2"
                dense
            ></v-switch>
        </v-col>
        <v-col cols="12">
            <div class="d-sm-flex d-xs-block justify-end align-center text-center secondary-font">
                <p class="mb-0" v-if="component_data_set.length > 0">Default</p>
            </div>
            <div
                class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center"
                v-for="(option, index) in component_data_set"
                :key="index"
            >
                <div class="d-flex align-center secondary-font width80">
                    <div class="mr-3 mt-2">
                        <v-btn
                            x-small
                            text
                            icon
                            color="darkGray"
                            :disabled="index === 0"
                            @click="changeOptionPosition(index, 'up')"
                        >
                            <v-icon x-small>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                            x-small
                            text
                            icon
                            color="darkGray"
                            @click="changeOptionPosition(index, 'down')"
                        >
                            <v-icon x-small>mdi-arrow-down</v-icon>
                        </v-btn>
                    </div>
                    <span class="mt-2">{{ index + 1 }}.</span>
                    <v-text-field
                        hide-details
                        dense
                        :value="option.name"
                        v-model="component_data_set[index].name"
                        v-on:keyup="changeOptionValues()"
                        class="ml-2"
                    ></v-text-field>
                </div>

                <div class="d-flex align-center justify-center secondary-font">
                    <v-switch
                        :input-value="option.default"
                        v-on:click.native="setDefault(index)"
                        hide-details
                    ></v-switch>
                    <v-btn
                        icon
                        class="mt-4"
                        :disabled="component_data_set.length === 1"
                        @click="removeOption(index)"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
            <v-btn small class="mt-2 mb-3" color="secondary" elevation="0" @click="addOption">
                Add Option
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'DropDown',
    props: {
        componentDataSet: Array,
        isShowLabel: Boolean,
    },
    data() {
        return {
            component_data_set: [],
            is_show_label: true,
            defaultSwitch: true,
            default_component_data_set: [
                {
                    name: 'Untitled option',
                    default: false,
                },
            ],
        };
    },
    methods: {
        changeOptionPosition(key, action) {
            let element = this.component_data_set[key];
            this.component_data_set.splice(key, 1);
            if (action === 'up') {
                let newPosition = key - 1;
                if (key > 0) {
                    this.component_data_set.splice(newPosition, 0, element);
                }
            }
            if (action === 'down') {
                let newPosition = key + 1;
                this.component_data_set.splice(newPosition, 0, element);
            }
            this.changeOptionValues();
        },
        changeOptionValues() {
            let componentValues = {
                component_data_set: this.component_data_set,
                is_show_label: this.is_show_label,
            };

            this.$emit('update-values', componentValues);
        },
        setDefault(currentIndex) {
            this.component_data_set[currentIndex].default =
                !this.component_data_set[currentIndex].default;
            this.component_data_set.forEach((option, index) => {
                if (index !== currentIndex) {
                    option.default = false;
                }
            });
            this.changeOptionValues();
        },
        addOption() {
            let currentHighestValue = 0;

            if (this.component_data_set.length > 0) {
                currentHighestValue = Math.max.apply(
                    Math,
                    this.component_data_set.map((option) => {
                        return option.id;
                    })
                );
            }

            let option = {
                name: 'Untitled option',
                default: false,
            };

            this.component_data_set.push(option);
            this.changeOptionValues();
        },
        removeOption(index) {
            this.component_data_set.splice(index, 1);
            this.changeOptionValues();
        },
        setInitials() {
            this.component_data_set =
                this.componentDataSet.length > 0 && this.componentDataSet
                    ? this.componentDataSet
                    : this.default_component_data_set;
            this.is_show_label = this.isShowLabel;
            this.changeOptionValues();
        },
    },
    watch: {
        is_show_label() {
            this.changeOptionValues();
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
