<template>
    <div class="custom-footer">
        <v-col
            :class="`text-center ${color}--text secondary-font`"
            :cols="['lg', 'xl'].includes($vuetify.breakpoint.name) ? 6 : 12"
        >
            {{ new Date().getFullYear() }} © — {{ applicationHeading }}. All rights reserved.
        </v-col>
    </div>
</template>

<script>
export default {
    name: 'LoginFooter',
    props: ['color'],
    computed: {
        applicationHeading() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? applicationHeading && applicationHeading.value !== ''
                    ? applicationHeading.value
                    : 'Application System'
                : undefined;
        },
    },
};
</script>
