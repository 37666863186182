export default {
    logoLight: (state) => {
        return state.systemSettings.find(
            (setting) => setting.key === 'settings_logo_light' && setting.site.value === state.site
        );
    },
    logoDark: (state) => {
        return state.systemSettings.find(
            (setting) => setting.key === 'settings_logo_dark' && setting.site.value === state.site
        );
    },
    applicationHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_application_heading' && setting.site.value === state.site
        );
    },
    maintenanceMode: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_maintenance_mode' && setting.site.value === state.site
        );
    },
    registeredUserHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_registered_user_heading' &&
                setting.site.value === state.site
        );
    },
    newUserHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_new_user_heading' && setting.site.value === state.site
        );
    },
    newUserDescription: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_new_user_description' && setting.site.value === state.site
        );
    },
    welcomeHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_welcome_heading' && setting.site.value === state.site
        );
    },
    welcomeSubHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_welcome_sub_heading' && setting.site.value === state.site
        );
    },
    applicationsSectionHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_applications_section_heading' &&
                setting.site.value === state.site
        );
    },
    applicationsSectionSubHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_applications_section_sub_heading' &&
                setting.site.value === state.site
        );
    },
    programsSectionHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_programs_section_heading' &&
                setting.site.value === state.site
        );
    },
    programsSectionSubHeading: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_programs_section_sub_heading' &&
                setting.site.value === state.site
        );
    },
    performMaintenanceMode: (state, getters, rootState) => {
        return !rootState.auth.isAdmin && state.maintenanceMode;
    },
    primaryColor: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_primary_color' && setting.site.value === state.site
        );
    },
    secondaryColor: (state) => {
        return state.systemSettings.find(
            (setting) =>
                setting.key === 'settings_secondary_color' && setting.site.value === state.site
        );
    },
    siteSubModules: (state) => {
        return state.sites.map((site) => ({
            text: site.name,
            value: site.uuid,
        }));
    },
};
