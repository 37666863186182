<template>
    <div class="custom-footer">
        <v-col class="text-center darkGray--text secondary-font" cols="12">
            {{ new Date().getFullYear() }} © — {{ applicationHeading }}. All rights reserved.
        </v-col>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return this.currentUser.role[0].name === 'ADMIN' ? true : false;
            }

            return false;
        },
        applicationHeading() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? applicationHeading && applicationHeading.value !== ''
                    ? applicationHeading.value
                    : 'Application System'
                : undefined;
        },
    },
};
</script>
