import JourneyService from '../../../services/journey-service';
import DocumentService from '../../../services/document-type-service';

export default {
    saveJourneyData({ commit }, payload) {
        JourneyService.saveJourneyData(payload).then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response);
            }
        });
    },
    getEntityTypes({ commit }) {
        JourneyService.getEntityTypes().then((response) => {
            if (response.data.data) {
                commit('updateEntityTypes', response.data.data);
            }
        });
    },
    getDocumentTypes({ commit }) {
        DocumentService.getDocumentTypes().then((response) => {
            if (response.data.data) {
                commit('updateDocumentTypes', response.data.data);
            }
        });
    },
    loadJourneys({ commit }) {
        JourneyService.getJourneys().then((response) => {
            commit('setJourneysList', response.data.data);
        });
    },
    setActiveJourney({ commit }, journey) {
        commit('SET_ACTIVE_JOURNEY', journey);
    },
};
