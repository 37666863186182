<template>
    <v-row>
        <v-col cols="12" xs="12" sm="12" md="12">
            <v-row>
                <div
                    class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center"
                    v-for="(option, index) in componentValues.conditions"
                    :key="index"
                >
                    <div class="d-flex align-center secondary-font ml-2">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="Attribute"
                            :value="option.attribute"
                            v-model="componentValues.conditions[index].attribute"
                            v-on:keyup="changeOptionValues()"
                            class="ml-2"
                        ></v-text-field>
                        <v-text-field
                            hide-details
                            dense
                            placeholder="Type"
                            :value="option.type"
                            v-model="componentValues.conditions[index].type"
                            v-on:keyup="changeOptionValues()"
                            class="ml-2"
                        ></v-text-field>
                        <v-text-field
                            hide-details
                            dense
                            placeholder="Value"
                            :value="option.value"
                            v-model="componentValues.conditions[index].value"
                            v-on:keyup="changeOptionValues()"
                            class="ml-2"
                        ></v-text-field>
                        <div class="d-flex align-center justify-center secondary-font">
                            <v-btn icon class="mt-4" @click="removeOption(index)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-row>
            <v-btn small class="mt-6 mb-3" color="secondary" elevation="0" @click="addOption">
                Add Conditions
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import common from '../../../constant/common-data';

export default {
    name: 'Courses',
    props: {
        courseCondition: Array,
        isShowLabel: Boolean,
    },
    data() {
        return {
            entityTypes: common.entityTypes,
            crmDropdownList: common.crmDropdownList,
            componentValues: {
                conditions: [
                    {
                        type: '',
                        attribute: '',
                        value: '',
                    },
                ],
            },
        };
    },
    methods: {
        addOption() {
            let currentHighestValue = 0;

            if (this.componentValues.conditions.length > 0) {
                currentHighestValue = Math.max.apply(
                    Math,
                    this.componentValues.conditions.map((option) => {
                        return option.id;
                    })
                );
            }

            let option = {
                type: '',
                attribute: '',
                value: '',
            };

            this.componentValues.conditions.push(option);
            this.changeOptionValues();
        },

        removeOption(index) {
            this.componentValues.conditions.splice(index, 1);
            this.changeOptionValues();
        },

        changeOptionValues() {
            this.$emit('update-values', this.componentValues);
        },

        setInitials() {
            this.componentValues.conditions =
                this.courseCondition && this.courseCondition.length ? this.courseCondition : [];
        },
    },
    watch: {},
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
