<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-checkbox
            :ref="component.component_name"
            class="mt-0 mb-0"
            v-for="(data, index) in component.component_data_set"
            :key="index"
            solo
            flat
            outlined
            :label="data.name"
            :value="data.name"
            @change="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            :disabled="enability"
            hide-details
            v-model="checkboxes"
        >
        </v-checkbox>
    </div>
</template>

<script>
export default {
    name: 'CheckBoxComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            checkboxes: [],
        };
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        setDefaultValue() {
            if (this.component.component_default_value) {
                this.checkboxes = this.component.component_default_value.split(',');
            }
        },
        inputChanged() {
            let selected = '';

            this.checkboxes.forEach((element) => {
                selected = selected + element + ',';
            });

            let inputValue = {
                key: this.component.component_name,
                value: selected.slice(0, -1) == '' ? null : selected.slice(0, -1),
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },
        appearanceInputChanged() {
            let selected = '';

            this.checkboxes.forEach((element) => {
                selected = selected + element + ',';
            });

            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: selected.slice(0, -1) == '' ? null : selected.slice(0, -1),
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },
    mounted() {
        if (this.component.component_default_value) {
            this.setDefaultValue();
        }
    },
};
</script>
