<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :ref="component.component_name"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    flat
                    outlined
                    dense
                    @change="inputChanged"
                    :rules="!$attrs.hidden && !enability ? validations : []"
                    :required="isRequired"
                    clearable
                    :disabled="enability"
                    :placeholder="component.component_placeholder"
                    :value="dateFormated"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                @input="menu = false"
                :allowed-dates="
                    dateRangeValidations && dateRangeValidations.field_type ? allowedDates : null
                "
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'DateComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
        dateRangeValidations: {
            default: null,
        },
    },
    data() {
        return {
            date: null,
            menu: false,
        };
    },
    watch: {
        date() {
            this.inputChanged();
        },
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    computed: {
        dateFormated() {
            return this.date ? moment(this.date).format('DD-MM-YYYY') : null;
        },
    },
    methods: {
        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.date,
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },
        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.date,
            };

            this.$emit('appearanceInputChanged', conditions);
        },
        allowedDates(v) {
            if (this.dateRangeValidations) {
                switch (this.dateRangeValidations.type) {
                    case 'minDate':
                        return moment(v).isSameOrAfter(moment(this.dateRangeValidations.value));
                    case 'maxDate':
                        return moment(v).isSameOrBefore(moment(this.dateRangeValidations.value));
                    case 'dateBetween':
                        return (
                            moment(v).isBetween(
                                moment(this.dateRangeValidations.value.sort()[0]),
                                moment(this.dateRangeValidations.value.sort()[1])
                            ) ||
                            moment(v).isSame(moment(this.dateRangeValidations.value[0])) ||
                            moment(v).isSame(moment(this.dateRangeValidations.value[1]))
                        );
                    case 'futureDate':
                        return moment(v).isAfter(moment().endOf('day'));
                    case 'pastDate':
                        return moment(v).isBefore(moment().startOf('day'));
                    default:
                        return null;
                }
            }
            return null;
        },
    },
    mounted() {
        this.date = this.component.component_default_value;
    },
};
</script>
