<template>
    <div>
        <v-list dense>
            <v-list-group
                :value="true"
                no-action
                sub-group
                prepend-icon=""
                v-for="(course, index) in courses"
                :key="index"
                @click="course.active = !course.active"
            >
                <template v-slot:activator>
                    <v-list-item-icon class="pl-0 mr-0">
                        <v-icon small>{{ course.active ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="pl-0">
                        <v-list-item-title class="font-weight-bold pl-0">
                            {{ course.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(child, i) in course.children" :key="i" class="pl-15">
                    <v-list-item-title class="pl-0">
                        <v-checkbox
                            :label="child.name"
                            hide-details
                            :ref="component.component_name"
                            class="mt-0 mb-0"
                            solo
                            flat
                            outlined
                            :value="child"
                            @change="inputChanged()"
                            :rules="!$attrs.hidden && !enability ? validations : []"
                            :required="isRequired"
                            :disabled="enability"
                            v-model="selectedCourses"
                        ></v-checkbox>
                    </v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </div>
</template>

<script>
import commonService from '../../../services/common-service';

export default {
    name: 'CoursesComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            courses: [],
            currentCourses: [],
            selectedCourses: [],
        };
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    computed: {
        application() {
            return this.$store.state.common.curentApplication;
        },
    },
    methods: {
        getCourses() {
            let conditions = this.component.courses_conditions;

            let payload = {
                conditions: conditions && conditions.length > 0 ? conditions : null,
            };

            commonService
                .post(`/courses`, payload)
                .then((response) => {
                    this.courses = response.data;

                    if (Array.isArray(this.component.component_default_value)) {
                        this.selectedCourses = this.component.component_default_value;
                    }
                })
                .catch(() => {
                    this.courses = [];
                });
        },
        getCurrentSelections() {
            if (this.application.applicationID) {
                commonService
                    .get(`/current-application-courses/${this.application.applicationID}`)
                    .then((response) => {
                        this.currentCourses = response.data;
                        this.setDefaultValue();
                    })
                    .catch(() => {
                        this.courses = [];
                    });
            }
        },

        inputChanged() {
            let selected = '';

            this.$refs[this.component.component_name].forEach((element) => {
                if (element.internalValue) {
                    selected = selected + element.label + ',';
                }
            });

            let inputValue = {
                key: this.component.component_name,
                value: this.selectedCourses.length > 0 ? this.selectedCourses : null,
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let selected = '';

            this.$refs[this.component.component_name].forEach((element) => {
                if (element.internalValue) {
                    selected = selected + element.label + ',';
                }
            });

            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.selectedCourses.length > 0 ? this.selectedCourses : null,
            };

            this.$emit('appearanceInputChanged', conditions);
        },

        setDefaultValue() {
            this.currentCourses.forEach((course) => {
                this.courses.filter((value) => {
                    value.children.filter((child) => {
                        if (child.id === course.courseId) {
                            this.selectedCourses.push(child);
                        }
                    });
                });
            });
        },
    },
    mounted() {
        this.getCourses();
    },
};
</script>
