<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Manage Entity Type</h1>
            <v-dialog v-model="dialog" persistent content-class="small-dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="secondary"
                        dark
                        class="text-capitalize mt-2"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Create New Entity Type
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-space-between align-center secondary-font">
                        <p class="h6 primary--text font-weight-bold mb-0">{{ dialogTitle }}</p>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" text icon color="primary" @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card-text class="secondary-font pb-0">
                            <v-row>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Entity name</p>
                                    <v-text-field
                                        v-model="entityTypeData.name"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Entity name is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Entity type</p>
                                    <v-text-field
                                        v-model="entityTypeData.entity_type"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Entity type is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary mr-2" @click="save" elevation="0"> Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
            <v-card-text class="secondary-font pa-3">
                <v-data-table
                    :headers="headers"
                    :items="entityTypeSet"
                    class="elevation-0 noborder-table body-primary"
                >
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="darkGray" @click="openEditEntityDialog(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="darkGray" @click="deleteEntityType(item.uuid)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <alert ref="confirm" />
    </v-container>
</template>

<script>
import EntityTypeService from '../../services/entity-type-service';
import notificationsUtility from '../../utilities/notifications-utility';
import common from '../../constant/common-data';
import Alert from '../../components/alert/ConfirmAlert.componet';

export default {
    name: 'EntityType',
    components: {
        Alert,
    },
    data() {
        return {
            dialogTitle: 'Create Entity Type',
            entityTypeData: {
                name: null,
                entity_type: null,
                status: 'ACTIVE',
            },
            uuid: null,
            isEdit: false,
            valid: false,
            dialog: false,
            entityTypeSet: [],
            headers: [
                { text: 'Entity Name', value: 'name', sortable: false },
                { text: 'Entity Type', value: 'entity_type', sortable: false },
                { text: 'Action', value: 'action', align: 'right', sortable: false },
            ],
            status: common.status,
        };
    },
    methods: {
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
                this.entityTypeData = Object.assign({}, this.$options.data().entityTypeData);
            });
        },
        generateCrmPeriod() {
            let name = this.period.period;
            this.period.crm_period_id = name.replace(/[^A-Z0-9]/gi, '_').toLowerCase();
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.isEdit) {
                    EntityTypeService.updateEntityType(this.entityTypeData, this.uuid)
                        .then((response) => {
                            if (response.data.data) {
                                this.entityTypeSet = response.data.data;
                                this.isEdit = false;
                                this.close();
                                notificationsUtility.pushNotifications([
                                    {
                                        message: 'Entity type updated successfully',
                                        type: 'success',
                                    },
                                ]);
                            }
                        })
                        .catch((error) => {
                            notificationsUtility.showNotifications(error.response.data);
                        });
                } else {
                    EntityTypeService.saveEntityType(this.entityTypeData)
                        .then((response) => {
                            this.entityTypeSet = response.data.data;
                            notificationsUtility.pushNotifications([
                                { message: 'Entity type created successfully', type: 'success' },
                            ]);
                            this.close();
                        })
                        .catch((error) => {
                            this.universities = [];
                            notificationsUtility.showNotifications(error.response.data);
                        });
                }
            }
        },
        deleteEntityType(uuid) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this entity type ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        EntityTypeService.deleteEntityType(uuid)
                            .then((response) => {
                                if (response.data.status === 1) {
                                    const entityIndex = this.entityTypeSet.findIndex(
                                        (x) => x.uuid === uuid
                                    );
                                    if (entityIndex !== -1)
                                        this.entityTypeSet.splice(entityIndex, 1);
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Entity type deleted successfully',
                                            type: 'success',
                                        },
                                    ]);
                                }
                            })
                            .catch((error) => {
                                if (error.response.data.status === 0) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: error.response.data.message,
                                            type: 'error',
                                        },
                                    ]);
                                } else if (error.response.status !== 503) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    }
                });
        },
        openEditEntityDialog(item) {
            this.isEdit = true;
            this.entityTypeData.name = item.name;
            this.entityTypeData.entity_type = item.entity_type;
            this.entityTypeData.status = item.status;
            this.uuid = item.uuid;
            this.dialog = true;
        },
        getEntityTypes() {
            EntityTypeService.getEntityTypes()
                .then((response) => {
                    this.entityTypeSet = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        getColor(status) {
            if (status == 'INACTIVE') return 'red';
            else return 'green';
        },
    },
    mounted() {
        this.getEntityTypes();
    },
    computed: {
        sites() {
            return this.$store.state.common.sites;
        },
    },
};
</script>

<style scoped></style>
