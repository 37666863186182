import Vue from 'vue';
import Vuex from 'vuex';
import moduleCommon from './modules/common/module-common';
import moduleAuth from './modules/auth/module-auth';
import moduleUser from './modules/user/module-user';
import moduleNotification from './modules/notification/module-notification';
import moduleJourney from './modules/journey/module-journey';
import moduleProfile from './modules/profile/module-profile';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth: moduleAuth,
        common: moduleCommon,
        user: moduleUser,
        journey: moduleJourney,
        notification: moduleNotification,
        profile: moduleProfile,
    },
});
