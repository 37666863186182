<template>
    <v-card class="rounded-12 section-card cursor-pointer hover-card" flat outlined>
        <v-card-text class="px-6">
            <p class="caption mb-0">Journey name</p>
            <v-text-field dense outlined hide-details></v-text-field>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'SectionInput',
    props: {
        section: {
            type: Object,
            default: null,
        },
    },
};
</script>
