<template>
    <div>
        <v-card
            flat
            :outlined="journeySection.is_show_title ? true : false"
            class="mx-auto rounded-lg secondary-font mb-4"
            :disabled="isDisable || isSectionDisable"
            v-if="isVisible && isSectionVisible"
        >
            <v-card-title
                class="section-card-title journey-header-text"
                v-if="journeySection.is_show_title"
            >
                {{ journeySection.section_display_name }}
            </v-card-title>
            <v-card-text class="pt-3" v-bind:class="{ 'px-0': !journeySection.is_show_title }">
                <p v-if="journeySection.section_text" v-html="journeySection.section_text"></p>

                <div v-if="journeySection.components.length > 0">
                    <v-row>
                        <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            :md="component.grid_size"
                            v-for="(component, index) in journeySection.components"
                            :key="index"
                            class="pb-0"
                            :hidden="
                                component.component_type === 'SPACER' &&
                                !advancedConditionsVisibility(component.is_hidden, component)
                            "
                        >
                            <div
                                v-if="component.component_type === 'TEXT_EDITOR'"
                                v-html="component.component_default_value"
                                :hidden="!advancedConditionsVisibility(false, component)"
                                :visibility="!advancedConditionsVisibility(false, component)"
                                :enability="advancedConditionsEnability(false, component)"
                            ></div>

                            <TextFieldComponent
                                v-if="component.component_type === 'TEXT_FIELD'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <DropDownComponent
                                v-if="component.component_type === 'DROP_DOWN'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <CheckBoxComponent
                                v-if="component.component_type === 'CHECK_BOX'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label,
                                        component.component_type
                                    )
                                "
                            />

                            <RadioComponent
                                v-if="component.component_type === 'RADIO'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <DateComponent
                                v-if="component.component_type === 'DATE'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                                :dateRangeValidations="
                                    component.advance_validation_rules.validation_rules
                                "
                            />

                            <TimeComponent
                                v-if="component.component_type === 'TIME'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <FileUploadComponent
                                v-if="component.component_type === 'FILE_UPLOAD'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <DatabaseComponent
                                v-if="component.component_type === 'DATABASE'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <CompetitorComponent
                                v-if="component.component_type === 'COMPETITOR'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <CrmDropDownComponent
                                v-if="component.component_type === 'CRM_DROP_DOWN'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <TextAreaComponent
                                v-if="component.component_type === 'TEXT_AREA'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <ButtonComponent
                                v-if="component.component_type === 'BUTTON'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label
                                    )
                                "
                            />

                            <PaymentComponent
                                v-if="component.component_type === 'PAYMENT'"
                                :activeTab="activeTab"
                                :component="component"
                                :hidden="!advancedConditionsVisibility(false, component)"
                                :visibility="!advancedConditionsVisibility(false, component)"
                                :enability="advancedConditionsEnability(false, component)"
                            />

                            <ApplicationStatusComponent
                                v-if="component.component_type === 'APPLICATION_STATUS'"
                                :component="component"
                                :hidden="!advancedConditionsVisibility(false, component)"
                                :visibility="!advancedConditionsVisibility(false, component)"
                                :enability="advancedConditionsEnability(false, component)"
                            />

                            <CoursesComponent
                                v-if="component.component_type === 'COURSES'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label,
                                        component.component_type
                                    )
                                "
                            />

                            <p
                                class="mb-0"
                                v-if="component.component_type === 'LABEL'"
                                :hidden="!advancedConditionsVisibility(false, component)"
                                :visibility="!advancedConditionsVisibility(false, component)"
                                :enability="advancedConditionsEnability(false, component)"
                            >
                                {{ component.component_label }}
                            </p>

                            <div
                                v-if="
                                    component.component_type === 'SPACER' &&
                                    advancedConditionsVisibility(false, component)
                                "
                                :hidden="!advancedConditionsVisibility(false, component)"
                                :visibility="!advancedConditionsVisibility(false, component)"
                                :enability="advancedConditionsEnability(false, component)"
                            ></div>

                            <DocumentComponent
                                v-if="component.component_type === 'DOCUMENT'"
                                v-on:inputChanged="inputChanged"
                                v-on:appearanceInputChanged="appearanceInputChanged"
                                :component="component"
                                :isRequired="isRequired(component.advance_validation_rules)"
                                :hidden="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :visibility="
                                    !advancedConditionsVisibility(component.is_hidden, component)
                                "
                                :enability="
                                    advancedConditionsEnability(component.is_disable, component)
                                "
                                :validations="
                                    getValidations(
                                        component.advance_validation_rules,
                                        component.component_label,
                                        component.component_type,
                                        component.component_default_value
                                    )
                                "
                            />
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import TextFieldComponent from './components/TextField';
import DropDownComponent from './components/DropDown';
import CheckBoxComponent from './components/CheckBox';
import RadioComponent from './components/Radio';
import DateComponent from './components/Date';
import TimeComponent from './components/Time';
import FileUploadComponent from './components/FileUpload';
import DatabaseComponent from './components/Database';
import CrmDropDownComponent from './components/CrmDropDown';
import TextAreaComponent from './components/TextArea';
import PaymentComponent from './components/payments/Payment';
import ApplicationStatusComponent from './components/ApplicationStatus';
import CoursesComponent from './components/Courses';
import DocumentComponent from './components/Document';
import CompetitorComponent from './components/Competitor';
import advancedValidation from '../../assets/scripts/advanced-validation.js';
import ButtonComponent from './components/Button';

export default {
    name: 'JourneySection',
    components: {
        TextFieldComponent,
        DropDownComponent,
        CheckBoxComponent,
        RadioComponent,
        DateComponent,
        TimeComponent,
        FileUploadComponent,
        DatabaseComponent,
        CrmDropDownComponent,
        TextAreaComponent,
        PaymentComponent,
        ApplicationStatusComponent,
        CoursesComponent,
        DocumentComponent,
        CompetitorComponent,
        ButtonComponent,
    },
    props: {
        journeySection: {
            type: [Object, Array],
            default: null,
        },
        section: {
            type: [Object, Array],
            default: null,
        },
        allSections: {
            type: [Object, Array],
            default: null,
        },
        allTabs: {
            type: [Object, Array],
            default: null,
        },
        isEditMood: {
            type: Boolean,
            default: false,
        },
        activeTab: {
            type: Number,
            default: 0,
        },
        appearanceSection: {
            type: [Object, Array],
            default: null,
        },
        isVisible: {
            type: Boolean,
            default: true,
        },
        isDisable: {
            type: Boolean,
            default: false,
        },
        isSectionDisable: {
            type: Boolean,
            default: false,
        },
        isSectionVisible: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            sectionComponents: [],
        };
    },

    computed: {
        isAdmin() {
            let logAsUserStatus = localStorage.getItem('log_as_user_status');
            let logAsUser = localStorage.getItem('log_as_user');

            let currentUser =
                logAsUserStatus && logAsUserStatus === 'true'
                    ? JSON.parse(logAsUser)
                    : this.$store.state.auth.user;

            if (currentUser && currentUser.role) {
                return currentUser.role[0].name === 'ADMIN' ? true : false;
            }

            return false;
        },
        application() {
            return this.$store.state.common.curentApplication;
        },
    },

    methods: {
        inputChanged(input) {
            this.section.components.forEach((component) => {
                if (component.name === input.key) {
                    component.value = input.value;
                }
                return;
            });

            let inputValue = {
                name: this.journeySection.section_name,
                value: this.section.components,
            };

            this.$emit('sectionComponents', inputValue);
        },

        appearanceInputChanged(input) {
            this.appearanceSection.components.forEach((component) => {
                if (component.component_name === input.component_name) {
                    component.value = input.value;
                    component.is_disable = input.is_disable;
                    component.is_hidden = input.is_hidden;
                }
                return;
            });

            let inputValue = {
                name: this.journeySection.section_name,
                value: this.appearanceSection.components,
            };

            this.$emit('appearanceSectionComponents', inputValue);
        },

        isRequired(advanceValidationRules) {
            return advanceValidationRules && advanceValidationRules.is_required ? true : false;
        },

        getValidations(validationRules, name, component_type = null, defaultValue = null) {
            let rules = [];
            if (validationRules.is_required) {
                if (
                    (component_type && component_type === 'CHECK_BOX') ||
                    component_type === 'COURSES'
                ) {
                    rules.push((v) => v.length > 0 || `${name} is required`);
                } else {
                    rules.push((v) => !!v || `${name} is required`);
                }
            }

            if (validationRules.validation_rules.field_type) {
                switch (validationRules.validation_rules.field_type) {
                    case 'text':
                        if (validationRules.validation_rules) {
                            switch (validationRules.validation_rules.type) {
                                case 'maxLength':
                                    rules.push(
                                        (v) =>
                                            (v || '').length <=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `A maximum of ${validationRules.validation_rules.value}  characters is allowed`
                                    );
                                    break;

                                case 'minLength':
                                    rules.push(
                                        (v) =>
                                            (v || '').length >=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `A minimum of ${validationRules.validation_rules.value}  characters is allowed`
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;

                    case 'number':
                        rules.push((v) => !isNaN(v) || `${name} should be number`);
                        if (validationRules.validation_rules) {
                            switch (validationRules.validation_rules.type) {
                                case 'equal':
                                    rules.push(
                                        (v) =>
                                            (!!v && v) ===
                                                parseInt(validationRules.validation_rules.value) ||
                                            `${name} should be equal to ${validationRules.validation_rules.value}`
                                    );
                                    break;

                                case 'notEqual':
                                    rules.push(
                                        (v) =>
                                            (!!v && v) !==
                                                parseInt(validationRules.validation_rules.value) ||
                                            `${name} should be not equal to ${validationRules.validation_rules.value}`
                                    );
                                    break;

                                case 'greaterThan':
                                    rules.push(
                                        (v) =>
                                            (!!v && v) >
                                                parseInt(validationRules.validation_rules.value) ||
                                            `${name} should be greater than ${validationRules.validation_rules.value}`
                                    );
                                    break;

                                case 'greaterThanEqual':
                                    rules.push(
                                        (v) =>
                                            (!!v && v) >=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `${name} should be greater than or equal to ${validationRules.validation_rules.value} `
                                    );
                                    break;

                                case 'lessThan':
                                    rules.push(
                                        (v) =>
                                            (!!v && v) <
                                                parseInt(validationRules.validation_rules.value) ||
                                            `${name} should less than to ${validationRules.validation_rules.value} `
                                    );
                                    break;

                                case 'lessThanEqual':
                                    rules.push(
                                        (v) =>
                                            (!!v && v) <=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `${name} should be less than or equal to ${validationRules.validation_rules.value} `
                                    );
                                    break;

                                default:
                                    break;
                            }
                        }
                        break;

                    case 'email':
                        rules.push((v) => /.+@.+\..+/.test(v) || `${name} must be valid`);
                        break;

                    case 'alphanumeric_no_space':
                        rules.push(
                            (v) =>
                                /^[a-zA-Z0-9]*$/.test(v) ||
                                `${name} can only contains letters or numbers [No spaces allowed]`
                        );
                        if (validationRules.validation_rules) {
                            switch (validationRules.validation_rules.type) {
                                case 'maxLength':
                                    rules.push(
                                        (v) =>
                                            (v || '').length <=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `A maximum of ${validationRules.validation_rules.value}  characters is allowed`
                                    );
                                    break;

                                case 'minLength':
                                    rules.push(
                                        (v) =>
                                            (v || '').length >=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `A minimum of ${validationRules.validation_rules.value}  characters is allowed`
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;

                    case 'alphanumeric_with_space':
                        rules.push(
                            (v) =>
                                /^[a-zA-Z0-9 ]*$/.test(v) ||
                                `${name} can only contains letters, numbers and spaces`
                        );
                        if (validationRules.validation_rules) {
                            switch (validationRules.validation_rules.type) {
                                case 'maxLength':
                                    rules.push(
                                        (v) =>
                                            (v || '').length <=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `A maximum of ${validationRules.validation_rules.value}  characters is allowed`
                                    );
                                    break;

                                case 'minLength':
                                    rules.push(
                                        (v) =>
                                            (v || '').length >=
                                                parseInt(validationRules.validation_rules.value) ||
                                            `A minimum of ${validationRules.validation_rules.value}  characters is allowed`
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;

                    default:
                        break;
                }
            }

            return rules;
        },

        advancedConditionsEnability(currentCondition, component) {
            return advancedValidation.advancedConditionsEnability(
                currentCondition,
                component,
                this.isEditMood,
                this.allTabs,
                this.allSections,
                this.isAdmin,
                this.application ? this.application.applicationSubmitted : 'No'
            );
        },

        advancedConditionsVisibility(currentCondition, component) {
            return advancedValidation.advancedConditionsVisibility(
                currentCondition,
                component,
                this.isEditMood,
                this.allTabs,
                this.allSections,
                this.isAdmin,
                this.application ? this.application.applicationSubmitted : 'No'
            );
        },
    },
};
</script>
