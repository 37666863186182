<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <v-row class="height100">
            <v-col cols="12" lg="6" class="login-card">
                <div class="ma-10 logo-spacer">
                    <a v-show="logoUrl">
                        <img :src="logoUrl" alt="logo" height="70" class="main-logo" />
                    </a>
                </div>
                <v-card flat class="rounded-0 ml-10" max-width="700">
                    <v-card-text>
                        <div class="pa-6 pa-sm-16">
                            <p class="text-h4 primary--text mb-16">{{ registeredUserHeading }}</p>
                            <v-form ref="form" v-model="valid">
                                <v-text-field
                                    v-model="user.email"
                                    :rules="emailRules"
                                    label="E-mail Address"
                                    required
                                    outlined
                                ></v-text-field>

                                <v-text-field
                                    type="password"
                                    v-model="user.password"
                                    :rules="passwordRules"
                                    label="Password"
                                    required
                                    outlined
                                ></v-text-field>

                                <div class="d-sm-flex d-xs-block align-center text-center">
                                    <v-btn
                                        color="secondary"
                                        :disabled="!valid"
                                        elevation="0"
                                        class="text-capitalize mt-2 mr-5 pa-8"
                                        x-large
                                        @click="handleLogin"
                                    >
                                        Sign In
                                    </v-btn>
                                    <a
                                        v-on:click="gotoForgotPassword"
                                        class="black--text text-h6 mt-2 secondary-font"
                                    >
                                        Forgot your password?
                                    </a>
                                </div>
                            </v-form>
                        </div>
                    </v-card-text>
                </v-card>
                <login-footer
                    color="darkGrey"
                    v-if="['lg', 'xl'].includes($vuetify.breakpoint.name)"
                />
            </v-col>
            <v-col cols="12" lg="6" class="primary login-card">
                <div class="ma-10 logo-spacer"></div>
                <v-card flat class="rounded-0 ml-10 primary" max-width="700">
                    <v-card-text>
                        <div class="pa-6 pa-sm-16">
                            <p class="text-h4 white--text mb-10">{{ newUserHeading }}</p>
                            <div v-html="newUserDescription" class="text-h6 white--text mb-3"></div>
                            <v-btn
                                color="secondary"
                                elevation="0"
                                class="text-capitalize pa-8"
                                x-large
                                :to="`/${site}/register`"
                            >
                                Create an Account
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
                <login-footer
                    color="white"
                    v-if="!['lg', 'xl'].includes($vuetify.breakpoint.name)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LoginFooter from '../../components/footer/LoginFooter.vue';
import notificationsUtility from '../../utilities/notifications-utility';

export default {
    components: { LoginFooter },
    name: 'Login',
    data() {
        return {
            valid: false,
            user: {
                email: null,
                password: null,
            },
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [(v) => !!v || 'Password is required'],
            logo_url: require('@/assets/logo/BSE_logo_horizontal_dark.png'),
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        site() {
            return this.$store.state.common.site;
        },
        applicationHeading() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? applicationHeading && applicationHeading.value !== ''
                    ? applicationHeading.value
                    : 'Application System'
                : undefined;
        },
        registeredUserHeading() {
            const data = this.$store.getters['common/registeredUserHeading'];
            return data ? data.value : null;
        },
        newUserHeading() {
            const data = this.$store.getters['common/newUserHeading'];
            return data ? data.value : null;
        },
        newUserDescription() {
            const data = this.$store.getters['common/newUserDescription'];
            return data ? data.value : null;
        },
        logoUrl() {
            const logoDark = this.$store.getters['common/logoDark'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded && logoDark && logoDark.value && logoDark.value !== ''
                ? logoDark.value
                : this.logo_url;
        },
    },
    created() {
        localStorage.removeItem('log_as_user');
        localStorage.setItem('log_as_user_status', 'false');
        if (this.loggedIn) {
            this.$router.push(`/${this.site}/`);
        }
    },
    methods: {
        gotoForgotPassword() {
            this.$router.push(`/${this.site}/forgot-password`);
        },
        handleLogin() {
            if (this.user.email && this.user.password) {
                this.$store.dispatch('auth/login', this.user).then(
                    () => {
                        window.location.replace(`/${this.site}/`);
                    },
                    (error) => {
                        notificationsUtility.pushNotifications([
                            {
                                message: error.response.data.error,
                                type: 'error',
                            },
                        ]);
                    }
                );
            }
        },
    },
};
</script>
