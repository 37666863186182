<template>
    <div class="my-4">
        <quill-editor
            :content="componentValues.component_default_value"
            :options="editorOption"
            @change="onEditorChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'TextEditor',
    props: {
        isHidden: Boolean,
        isShowLabel: Boolean,
        defaultValue: String,
    },
    data() {
        return {
            componentValues: {
                component_default_value: '',
                is_show_label: false,
                is_hidden: false,
            },
            editorOption: {},
        };
    },
    methods: {
        onEditorChange({ quill, html, text }) {
            this.componentValues.component_default_value = html;
        },
        setInitials() {
            this.componentValues.component_default_value = this.defaultValue
                ? this.defaultValue
                : null;
            this.componentValues.is_show_label = this.isShowLabel ? this.isShowLabel : false;
            this.componentValues.is_hidden = this.isHidden ? this.isHidden : false;
        },
    },
    watch: {
        'componentValues.component_default_value'() {
            this.$emit('update-values', this.componentValues);
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
