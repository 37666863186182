var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.welcomeHeading))]),_c('p',{staticClass:"primary--text subtitle-1"},[_vm._v(_vm._s(_vm.welcomeSubHeading))])]),_c('v-container',{staticClass:"mt-5 p-0"},[_c('h3',{staticClass:"primary--text text-center"},[_vm._v(_vm._s(_vm.applicationsSectionHeading))]),_c('p',{staticClass:"primary--text subtitle-2 mb-0 text-center"},[_vm._v(" "+_vm._s(_vm.applicationsSectionSubHeading)+" ")]),_c('v-row',{staticClass:"ma-0 pa-0 application-row d-flex justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',[_c('v-card',{staticClass:"mx-auto rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"secondary-font pa-3"},[_c('v-data-table',{staticClass:"elevation-0 noborder-table body-primary height100",attrs:{"no-data-text":"You do not have any applications yet","headers":_vm.headers,"items":_vm.applications,"hide-default-footer":"","page":_vm.page,"items-per-page":5},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:`item.applicationNumber`,fn:function({ item }){return [_c('p',{staticClass:"mb-0",class:{
                                            'cursor-pointer link-text': item.enable,
                                            'disabled-text': !item.enable,
                                        },on:{"click":function($event){return _vm.editApplication(item, item.enable)}}},[_vm._v(" "+_vm._s(item.applicationNumber)+" ")])]}},{key:`item.applicationType`,fn:function({ item }){return [_c('p',{staticClass:"mb-0",class:{
                                            'cursor-pointer link-text': item.enable,
                                            'disabled-text': !item.enable,
                                        },on:{"click":function($event){return _vm.editApplication(item, item.enable)}}},[_vm._v(" "+_vm._s(item.applicationType)+" ")])]}},{key:`item.period`,fn:function({ item }){return [_c('p',{staticClass:"mb-0",class:{ 'disabled-text': !item.enable }},[_vm._v(" "+_vm._s(item.period)+" ")])]}},{key:`item.currentOptionProgramName`,fn:function({ item }){return [_c('p',{staticClass:"mb-0",class:{ 'disabled-text': !item.enable }},[_vm._v(" "+_vm._s(item.currentOptionProgramName)+" ")])]}},{key:`item.applicationStatus`,fn:function({ item }){return [_c('v-chip',{staticClass:"text-capitalize lighten-1",attrs:{"small":"","color":item.enable
                                                ? _vm.statusColor(item.applicationStatus)
                                                : '',"dark":""}},[_vm._v(" "+_vm._s(item.applicationStatus)+" ")])]}}],null,true)})],1)],1),(_vm.pageCount)?_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('v-pagination',{staticClass:"secondary-font",attrs:{"length":_vm.pageCount,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)],1)],1),_c('v-container',[_c('h3',{staticClass:"primary--text text-center mt-0"},[_vm._v(_vm._s(_vm.programsSectionHeading))]),_c('p',{staticClass:"primary--text subtitle-2 text-center"},[_vm._v(_vm._s(_vm.programsSectionSubHeading))]),_c('v-row',{staticClass:"mt-5 d-flex justify-center"},_vm._l((_vm.onlyActiveJourneys),function(program,index){return _c('v-col',{key:index,attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4"}},[_c('Program',{attrs:{"applications":_vm.applications,"program":program}})],1)}),1),(_vm.journeyCount > 9)?_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showLess = !_vm.showLess}}},[_vm._v(" "+_vm._s(_vm.showLess ? 'More' : 'Less')+" Programs "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.showLess ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }