import commonService from '../../../services/common-service';
import router from '../../../router';

export default {
    setApplicationDetails({ commit }, payload) {
        commit('CURRENT_APPLICATION', payload);
    },
    loadSettings({ commit }) {
        commonService.loadSettings().then((response) => {
            commit('SET_SYSTEM_SETTINGS', response.data.data.general);
            commit('SET_SITES', response.data.data.sites);
            commit(
                'SET_SESSION_TIME_OUT_MESSAGE',
                response.data.data.messages.length ? response.data.data.messages[0] : null
            );

            let siteNames = response.data.data.sites.map((site) => {
                return site.value;
            });

            if (!siteNames.includes(router.currentRoute.params.site)) {
                window.location.href = `/apply${window.location.search}`;
            }
        });
    },
    setSite({ commit }, payload) {
        commit('CURRENT_SITE', payload);
    },

    setSites({ commit }, payload) {
        commit('SET_SITES', payload);
    },
};
