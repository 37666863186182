<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-autocomplete
            class="no-arrow"
            v-if="component.crm_drop_down.is_search_option"
            :ref="component.component_name"
            :items="componentData"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            :item-text="component.crm_drop_down.display_field"
            :item-value="component.crm_drop_down.value_field"
            :placeholder="component.component_placeholder"
            solo
            flat
            outlined
            dense
            @change="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            clearable
            :disabled="enability"
            :value="crmDropDown"
            :multiple="component.crm_drop_down.is_multi_option"
            @click:clear="clearValue"
        ></v-autocomplete>

        <v-select
            v-else
            :ref="component.component_name"
            :items="componentData"
            :item-text="component.crm_drop_down.display_field"
            :item-value="component.crm_drop_down.value_field"
            solo
            flat
            outlined
            dense
            @change="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            clearable
            :disabled="enability"
            :value="crmDropDown"
            :placeholder="component.component_placeholder"
            :multiple="component.crm_drop_down.is_multi_option"
        ></v-select>
    </div>
</template>

<script>
import commonService from '../../../services/common-service';
import commonData from '../../../constant/common-data';

export default {
    name: 'CrmDropDownComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            crmDropDown: null,
            componentData: [],
            isLoading: false,
            search: null,
            stopWatch: false,
        };
    },
    watch: {
        search() {
            if (!this.stopWatch && this.search) {
                this.getComponentData(this.search);
            }
        },
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    computed: {
        site() {
            return this.$store.state.common.site;
        },
        isEditMood() {
            return this.$route.path.includes('edit') ? true : false;
        },
        application() {
            return this.$store.state.common.curentApplication;
        },
        activeJourney() {
            return this.$store.state.journey.activeJourney;
        },
    },
    methods: {
        clearValue() {
            this.getComponentData();
        },
        getComponentData(searchValue = null) {
            let query = this.component.crm_drop_down.is_search_option ? searchValue : null;

            let conditions = this.component.crm_drop_down.conditions;

            let payload = {
                query: query,
                conditions: conditions && conditions.length > 0 ? conditions : null,
                canApplyJourneyMultipleTimes: commonData.canApplyJourneyMultipleTimes,
                isEditMood: this.isEditMood,
                currentApp: this.application ? this.application.applicationID : null,
                journeyId: this.activeJourney,
            };

            commonService
                .post(this.component.crm_drop_down.entity, payload)
                .then((response) => {
                    this.componentData = response.data.list;

                    this.setDefaultValue();
                })
                .catch(() => {
                    this.componentData = [];
                });
        },
        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('appearanceInputChanged', conditions);
        },

        setDefaultValue() {
            // for auto complete

            let defaultValue = null;

            if (
                this.component.crm_drop_down.is_search_option &&
                this.componentData.length > 0 &&
                this.stopWatch
            ) {
                defaultValue = this.componentData[0];
            }

            // for select option
            if (!this.component.crm_drop_down.is_search_option) {
                let option = this.componentData.filter((data) => {
                    if (
                        data.id == this.component.component_default_value ||
                        data.name == this.component.component_default_value
                    ) {
                        return data;
                    }
                });

                defaultValue = option ? option[0] : null;
            }

            if (defaultValue) {
                this.crmDropDown = defaultValue;

                setTimeout(
                    function () {
                        this.stopWatch = false;
                    }.bind(this),
                    1000
                );
            }
        },
    },
    mounted() {
        if (
            this.component.component_default_value &&
            this.component.crm_drop_down.is_search_option
        ) {
            this.stopWatch = true;
        }

        if (this.component && this.component.crm_drop_down.entity) {
            this.getComponentData(this.component.component_default_value);
        }
    },
};
</script>
