export default {
    journey: [],
    active_tab: null,
    active_section: null,
    active_component: null,
    openEditTabDialog: false,
    openEditSectionDialog: false,
    openEditJourneyDialog: false,
    defaultEntity: null,
    entityTypes: [],
    journeysList: [],
    documentTypes: [],
    uuid: null,
    sites: [
        { uuid: 'erw23424', site: 'apply' },
        { uuid: 'erw234324', site: 'idea' },
        { uuid: 'erw23454424', site: 'participation' },
    ],
    activeJourney: null,
    triggerAlert: false,
};
