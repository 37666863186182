import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00859b',
                secondary: '#df7a0e',
                light: '#eceff1',
                lightGray: '#efefef',
                white: '#fff',
                whiteSmoke: '#f5f5f5',
                darkGray: '#cacaca',
                red: '#de3737',

                themeOrange: '#df7a0e',
                themeGray: '#30515f',
                themeBlue: '#005d9f',
            },
        },
    },
});
