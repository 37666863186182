<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="6">
                <v-card class="mx-auto py-15 rounded-lg" outlined color="primary">
                    <v-card-text class="text-center">
                        <p class="text-h5 d-sm-none d-xs-block text-uppercase lightGray--text">
                            Unauthorized !
                        </p>
                        <p class="text-h3 d-none d-sm-block text-uppercase lightGray--text">
                            Unauthorized !
                        </p>
                        <p class="title">
                            This area is <span class="font-weight-bold">forbidden</span>. Turn back
                            now!
                        </p>
                        <v-btn
                            color="secondary"
                            elevation="0"
                            class="text-capitalize mt-2"
                            link
                            :to="`/${site}/`"
                        >
                            Home
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Unauthorized',
};
</script>
