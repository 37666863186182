import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../view/auth/Login';
import Register from '../view/auth/Register';
import ForgotPassword from '../view/auth/ForgotPassword';
import ChangePassword from '../components/user/ChangePassword';
import ChangeEmail from '../components/user/ChangeEmail';
import CreateAccountResponse from '../view/response/CreateAccountResponse';
import AccountActivatedResponse from '../view/response/AccountActivatedResponse';
import PasswordResetResponse from '../view/response/PasswordResetResponse';
import Journey from '../view/journeys/Journey';
import ManageNotifications from '../view/notification/ManageNotifications';
import ManageUsers from '../view/user/ManageUsers';
import JourneyDetails from '../view/journeys/JourneyDetails';
import EditJourneyDetails from '../view/journeys/EditJourney';
import Unauthorized from '../view/Unauthorized';
import Application from '../view/programs/Application';
import CreateApplication from '../view/programs/CreateApplication';
import Profile from '../view/profile/Profile';
import CreateProfile from '../view/profile/CreateProfile';
import University from '../view/settings/University';
import Period from '../view/settings/Period';
import JourneyType from '../view/settings/JourneyTypes';
import EntityType from '../view/settings/EntityType';
import GeneralSettings from '../view/settings/GeneralSettings';
import DocumentTypes from '../view/settings/DocumentTypes';
import Site from '../view/settings/Site';
import PaymentSuccess from '../components/application/components/payments/response/PaymentSuccess';
import PaymentError from '../components/application/components/payments/response/PaymentError';

import store from '../store/index.js';

Vue.use(VueRouter);

const routes = [
    // Applications
    {
        path: '/:site/',
        component: Application,
        meta: {
            admin: true,
            student: true,
        },
    },

    {
        path: '/:site/application/create/:id',
        component: CreateApplication,
        meta: {
            admin: true,
            student: true,
        },
    },

    {
        path: '/:site/application/edit/:id',
        component: CreateApplication,
        meta: {
            admin: true,
            student: true,
        },
    },

    {
        path: '/:site/user/change-password',
        component: ChangePassword,
        meta: {
            admin: true,
            student: true,
            title: 'Change Password',
        },
    },

    {
        path: '/:site/user/change-email',
        component: ChangeEmail,
        meta: {
            admin: true,
            student: true,
            title: 'Change email',
        },
    },

    {
        path: '/:site/journeys',
        component: Journey,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Application Journey',
        },
    },

    {
        path: '/:site/users/',
        component: ManageUsers,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Users',
        },
    },

    {
        path: '/:site/journey/edit/:id',
        component: EditJourneyDetails,
        meta: {
            admin: true,
            student: false,
        },
    },

    {
        path: '/:site/journey/test',
        component: JourneyDetails,
        meta: {
            admin: true,
            student: false,
        },
    },

    // Authentication
    {
        path: '/:site/login',
        component: Login,
        name: 'login',
        meta: {
            allowAnonymous: true,
        },
    },

    {
        path: '/:site/register',
        component: Register,
        meta: {
            allowAnonymous: true,
            title: 'Create Account',
        },
    },

    {
        path: '/:site/forgot-password',
        component: ForgotPassword,
        meta: {
            allowAnonymous: true,
            title: 'Forgot Password',
        },
    },

    // Responses
    {
        path: '/:site/account/create-response',
        component: CreateAccountResponse,
        meta: {
            allowAnonymous: true,
            title: 'Account Created',
        },
    },

    {
        path: '/:site/account/activated-response/:status',
        component: AccountActivatedResponse,
        meta: {
            allowAnonymous: true,
        },
    },

    // user profile
    {
        path: '/:site/user/profile',
        component: Profile,
        meta: {
            admin: true,
            student: false,
            title: 'Update Personal Data',
        },
    },

    {
        path: '/:site/account/password-reset-response',
        component: PasswordResetResponse,
        meta: {
            allowAnonymous: true,
        },
    },

    // Settings
    {
        path: '/:site/settings/notifications/',
        component: ManageNotifications,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Customize Notifications',
        },
    },

    {
        path: '/:site/settings/universities',
        component: University,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Universities',
        },
    },

    {
        path: '/:site/settings/create-profile',
        component: CreateProfile,
        meta: {
            admin: true,
            student: false,
            title: 'Create Personal Detail Form',
        },
    },

    {
        path: '/:site/settings/periods',
        component: Period,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Periods',
        },
    },

    {
        path: '/:site/settings/journey-types',
        component: JourneyType,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Journey Types',
        },
    },

    {
        path: '/:site/settings/entity-types',
        component: EntityType,
        meta: {
            admin: true,
            student: false,
            title: 'Manage Entity Types',
        },
    },

    {
        path: '/:site/settings/general',
        component: GeneralSettings,
        meta: {
            admin: true,
            student: false,
            title: 'Configure System General Settings',
        },
    },

    {
        path: '/:site/settings/document-types',
        component: DocumentTypes,
        meta: {
            admin: true,
            student: false,
        },
    },

    {
        path: '/:site/settings/sites',
        component: Site,
        meta: {
            admin: true,
            student: false,
        },
    },

    // Payment redirection urls
    {
        path: '/:site/payment/response/success',
        component: PaymentSuccess,
        meta: {
            admin: true,
            student: true,
        },
    },

    {
        path: '/:site/payment/response/error',
        component: PaymentError,
        meta: {
            admin: true,
            student: true,
        },
    },

    {
        path: '/:site/unauthorized',
        component: Unauthorized,
        meta: {
            admin: true,
            student: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let sites = store.state.common.sites.map((site) => {
        return site.value;
    });

    if (to.path === '/') {
        return next({ path: `/apply`, query: to.query });
    }

    let defaultSite = 'apply';

    if (to.params.hasOwnProperty('site')) {
        if (sites.length === 0 || sites.includes(to.params.site)) {
            defaultSite = to.params.site;
        } else {
            return next({ path: `/apply`, query: to.query });
        }
    } else {
        return next({ path: `/apply`, query: to.query });
    }

    const publicPages = [
        `/${defaultSite}/forgot-password`,
        `/${defaultSite}/unauthorized`,
        `/${defaultSite}/login`,
        `/${defaultSite}/account/create-response`,
        `/${defaultSite}/account/activated-response/1`,
        `/${defaultSite}/account/activated-response/0`,
        `/${defaultSite}/account/password-reset-response`,
        `/${defaultSite}/register`,
    ];

    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token') != undefined ? true : false;
    const user =
        localStorage.getItem('user') != undefined
            ? JSON.parse(localStorage.getItem('user'))
            : false;
    const userRole = user && user.role ? user.role[0].name : null;
    document.title = to.meta.title ? `${to.meta.title} - Apply to BSE` : 'Apply to BSE';

    if (loggedIn && to.fullPath === `/${defaultSite}/login`) {
        return next(`/${defaultSite}/`);
    }

    if (authRequired && !loggedIn) {
        return next({ path: `/${defaultSite}/login`, query: to.query });
    } else if (!authRequired && loggedIn) {
        if (
            (userRole == 'ADMIN' && !to.meta.admin) ||
            (userRole == 'STUDENT' && !to.meta.student)
        ) {
            return next('/unauthorized');
        }
    }

    return next();
});

export default router;
