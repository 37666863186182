<template>
    <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" class="d-flex">
            <v-switch
                label="Show Label"
                v-model="componentValues.is_show_label"
                hide-details
                class="mr-4 mt-2"
                dense
            ></v-switch>
            <v-switch
                label="Search Option"
                v-model="componentValues.crm_drop_down.is_search_option"
                hide-details
                class="mr-4 mt-2"
                dense
            ></v-switch>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
            <v-select
                :items="crmDropdownList"
                label="Entity Type"
                v-model="componentValues.crm_drop_down.entity"
                flat
                outlined
                :rules="[(v) => !!v || 'Entity Type is required']"
                dense
            ></v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
            <v-text-field
                label="Value Field"
                v-model="componentValues.crm_drop_down.value_field"
                flat
                outlined
                :rules="[(v) => !!v || 'Value field is required']"
                dense
            ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
            <v-text-field
                label="Display Field"
                v-model="componentValues.crm_drop_down.display_field"
                flat
                outlined
                :rules="[(v) => !!v || 'Display field is required']"
                dense
            ></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-row>
                <div
                    class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center"
                    v-for="(option, index) in componentValues.crm_drop_down.conditions"
                    :key="index"
                >
                    <div class="d-flex align-center secondary-font ml-2">
                        <v-text-field
                            hide-details
                            dense
                            placeholder="Attribute"
                            :value="option.attribute"
                            v-model="componentValues.crm_drop_down.conditions[index].attribute"
                            v-on:keyup="changeOptionValues()"
                            class="ml-2"
                        ></v-text-field>
                        <v-text-field
                            hide-details
                            dense
                            placeholder="Type"
                            :value="option.type"
                            v-model="componentValues.crm_drop_down.conditions[index].type"
                            v-on:keyup="changeOptionValues()"
                            class="ml-2"
                        ></v-text-field>
                        <v-text-field
                            hide-details
                            dense
                            placeholder="Value"
                            :value="option.value"
                            v-model="componentValues.crm_drop_down.conditions[index].value"
                            v-on:keyup="changeOptionValues()"
                            class="ml-2"
                        ></v-text-field>
                        <div class="d-flex align-center justify-center secondary-font">
                            <v-btn icon class="mt-4" @click="removeOption(index)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-row>
            <v-btn small class="mt-3 mb-3" color="secondary" elevation="0" @click="addOption">
                Add Conditions
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import common from '../../../constant/common-data';

export default {
    name: 'CrmDropdown',
    props: {
        crmDropdown: Object,
        isShowLabel: Boolean,
    },
    data() {
        return {
            entityTypes: common.entityTypes,
            crmDropdownList: common.crmDropdownList,
            componentValues: {
                is_show_label: true,
                crm_drop_down: {
                    is_search_option: false,
                    is_multi_option: false,
                    entity: null,
                    value_field: null,
                    display_field: null,
                    conditions: [
                        {
                            type: '',
                            attribute: '',
                            value: '',
                        },
                    ],
                },
            },
        };
    },
    methods: {
        addOption() {
            let currentHighestValue = 0;

            if (this.componentValues.crm_drop_down.conditions.length > 0) {
                currentHighestValue = Math.max.apply(
                    Math,
                    this.componentValues.crm_drop_down.conditions.map((option) => {
                        return option.id;
                    })
                );
            }

            let option = {
                type: '',
                attribute: '',
                value: '',
            };

            this.componentValues.crm_drop_down.conditions.push(option);
            this.changeOptionValues();
        },

        removeOption(index) {
            this.componentValues.crm_drop_down.conditions.splice(index, 1);
            this.changeOptionValues();
        },

        changeOptionValues() {
            this.$emit('update-values', this.componentValues);
        },

        setInitials() {
            this.componentValues.is_show_label = this.isShowLabel;
            this.componentValues.crm_drop_down.is_search_option = this.crmDropdown
                ? this.crmDropdown.is_search_option
                : false;
            this.componentValues.crm_drop_down.entity = this.crmDropdown
                ? this.crmDropdown.entity
                : null;
            this.componentValues.crm_drop_down.value_field = this.crmDropdown
                ? this.crmDropdown.value_field
                : null;
            this.componentValues.crm_drop_down.display_field = this.crmDropdown
                ? this.crmDropdown.display_field
                : null;
            this.componentValues.crm_drop_down.is_multi_option = this.crmDropdown
                ? this.crmDropdown.is_multi_option
                : null;
            this.componentValues.crm_drop_down.conditions =
                this.crmDropdown && this.crmDropdown.conditions ? this.crmDropdown.conditions : [];
        },
    },
    watch: {
        'componentValues.is_show_label'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.crm_drop_down.entity'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.crm_drop_down.value_field'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.crm_drop_down.display_field'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.crm_drop_down.is_search_option'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.crm_drop_down.is_multi_option'() {
            this.$emit('update-values', this.componentValues);
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
