<template>
    <div>
        <LogAs v-if="loggedAs" />

        <v-app-bar app flat height="75" color="primary" dark elevation="0">
            <a @click="triggerGotoHome" v-show="logoUrl">
                <div class="d-none d-sm-flex justify-center">
                    <img :src="logoUrl" alt="logo" height="55" class="main-logo" />
                </div>
                <div class="d-sm-none d-flex justify-center">
                    <img :src="logoUrl" alt="logo" height="40" class="main-logo" />
                </div>
            </a>

            <div class="ml-10 d-none d-md-block">
                <v-btn class="ml-1 text-capitalize" text :to="`/${site}/users`">
                    <v-icon left> mdi-account-multiple-outline </v-icon>
                    Users
                </v-btn>

                <v-btn class="ma-1 text-capitalize" text :to="`/${site}/journeys`">
                    <v-icon left> mdi-badge-account-outline </v-icon>
                    Journeys
                </v-btn>

                <v-menu offset-y content-class="app-menu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-1 text-capitalize" text v-on="on" v-bind="attrs" outlined>
                            <v-icon left> mdi-web </v-icon>
                            {{ selectedSite.name }}
                        </v-btn>
                    </template>
                    <v-list dense class="secondary-font" elevation="0">
                        <v-list-item
                            v-for="(site, index) in sitesArray"
                            :key="index"
                            @click="changeSite(site.value)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ site.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-spacer></v-spacer>
            <div class="d-md-none">
                <v-menu offset-y content-class="app-menu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list
                        dense
                        class="secondary-font list-compressed"
                        max-width="250"
                        elevation="0"
                    >
                        <v-list-item :to="`/${site}/users`">
                            <v-list-item-content>
                                <v-list-item-title>Users</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="`/${site}/journeys`">
                            <v-list-item-content>
                                <v-list-item-title>Journeys</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-menu offset-y content-class="app-menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3 mr-2" icon v-on="on" v-bind="attrs">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list dense class="secondary-font list-compressed" max-width="250" elevation="0">
                    <v-list-item :to="`/${site}/settings/notifications`">
                        <v-list-item-content>
                            <v-list-item-title>Notifications</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/universities`">
                        <v-list-item-content>
                            <v-list-item-title>Universities</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/create-profile`">
                        <v-list-item-content>
                            <v-list-item-title>Personal Details</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/periods`">
                        <v-list-item-content>
                            <v-list-item-title>Periods</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/journey-types`">
                        <v-list-item-content>
                            <v-list-item-title>Journey Types</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/entity-types`">
                        <v-list-item-content>
                            <v-list-item-title>Entity Types</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/document-types`">
                        <v-list-item-content>
                            <v-list-item-title>Document Types</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/sites`">
                        <v-list-item-content>
                            <v-list-item-title>Sites</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="`/${site}/settings/general`">
                        <v-list-item-content>
                            <v-list-item-title>General Settings</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y content-class="app-menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3 mr-2" icon v-on="on" v-bind="attrs">
                        <v-avatar size="44">
                            <v-icon large> mdi-account-outline </v-icon>
                        </v-avatar>
                    </v-btn>
                </template>
                <v-list dense class="secondary-font list-compressed" max-width="250" elevation="0">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text font-weight-bold">
                                {{ currentUser.first_name }} {{ currentUser.last_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="tiny-text">
                                {{ currentUser.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="`/${site}/user/profile`">
                        <v-list-item-content>
                            <v-list-item-title>Update Personal Data</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item :to="`/${site}/user/change-email`">
                        <v-list-item-content>
                            <v-list-item-title>Change email address</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item :to="`/${site}/user/change-password`">
                        <v-list-item-content>
                            <v-list-item-title>Change Password</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click.prevent="triggerLogout">
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import LogAs from '../../components/alert/LogAsUserAlert.component';
export default {
    name: 'AppBarAdmin',
    components: {
        LogAs,
    },
    props: {
        logoUrl: {
            type: String,
            default: null,
        },
        currentUser: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loggedAs: false,
            changeSelectedSite: null,
            selectedSite: {
                name: 'Apply',
                value: 'apply',
            },
            sitesArray: [],
        };
    },
    watch: {
        sites() {
            this.sitesArray = this.sites;
        },
    },
    computed: {
        sites() {
            let sites = this.$store.state.common.sites;

            const filteredSite = sites.filter((site) => {
                return site.value === this.site;
            });

            if (filteredSite && filteredSite.length > 0) {
                this.selectedSite = filteredSite[0];
            }

            return sites;
        },
        site() {
            return this.$store.state.common.site;
        },
    },
    methods: {
        triggerLogout() {
            this.$emit('logOut');
        },

        triggerGotoHome() {
            this.$emit('gotoHome');
        },
        changeSite(selectedSite) {
            let url = window.location.href;
            let origin = window.location.origin;
            let newUrl = window.location.href;
            if (url.includes('journey/edit/')) {
                newUrl = origin + '/' + selectedSite + '/journeys';
            } else if (url.includes('application/create/') || url.includes('application/edit/')) {
                newUrl = origin + '/' + selectedSite;
            } else {
                newUrl = url.replace(origin + '/' + this.site, origin + '/' + selectedSite);
            }

            window.location.href = newUrl;
        },
    },
    mounted() {
        this.changeSelectedSite = this.site;
    },
    created() {
        this.loggedAs =
            localStorage.getItem('log_as_user_status') &&
            localStorage.getItem('log_as_user_status') === 'true'
                ? true
                : false;
    },
};
</script>
