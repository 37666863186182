import state from './module-journey-state';
import mutations from './module-journey-mutations';
import actions from './module-journey-actions';
import getters from './module-journey-getters';

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
