export default {
    setEmailFormats(state, emailFormatsList) {
        state.emailFormatsList = emailFormatsList;
    },
    addEmailFormat(state, emailFormat) {
        state.emailFormatsList.push(emailFormat);
    },
    editEmailFormat(state, emailFormat) {
        const item = state.emailFormatsList.find((item) => item.uuid === emailFormat.uuid);
        Object.assign(item, emailFormat);
    },
    deleteEmailFormat(state, emailFormat) {
        state.emailFormatsList = state.emailFormatsList.filter(
            (item) => item.uuid !== emailFormat.uuid
        );
    },
    setSystemMessages(state, systemMessagesList) {
        state.systemMessagesList = systemMessagesList;
    },
    addSystemMessage(state, systemMessage) {
        state.systemMessagesList.push(systemMessage);
    },
    editSystemMessage(state, systemMessage) {
        const item = state.systemMessagesList.find((item) => item.uuid === systemMessage.uuid);
        Object.assign(item, systemMessage);
    },
    deleteSystemMessage(state, systemMessage) {
        state.systemMessagesList = state.systemMessagesList.filter(
            (item) => item.uuid !== systemMessage.uuid
        );
    },
};
