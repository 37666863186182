<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="5" md="5">
                <p class="caption mb-0">Entity Type</p>
                <v-select :items="items" solo flat outlined hide-details dense></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="7" md="7">
                <p class="caption mb-0">CRM Field</p>
                <v-text-field solo flat outlined hide-details dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="8" md="8" class="d-flex">
                <v-switch label="Required" hide-details class="mr-4 mt-2" dense></v-switch>
                <v-switch label="Admin Only" hide-details class="mr-4 mt-2" dense></v-switch>
                <v-switch label="Show Label" hide-details class="mr-4 mt-2" dense></v-switch>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4">
                <v-checkbox
                    v-model="showAdvancedConditions"
                    label="Advanced Condition"
                    outlined
                    hide-details
                    dense
                    class="mt-2"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row v-if="showAdvancedConditions">
            <v-col cols="12">
                <v-tabs v-model="tab">
                    <v-tab> Validation </v-tab>
                    <v-tab> Enability </v-tab>
                    <v-tab> Visibility </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-row class="mt-1">
                            <v-col cols="12" xs="12" sm="4">
                                <p class="caption mb-0">Number</p>
                                <v-select
                                    :items="items"
                                    solo
                                    flat
                                    outlined
                                    hide-details
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3">
                                <p class="caption mb-0">Greater than</p>
                                <v-select
                                    :items="items"
                                    solo
                                    flat
                                    outlined
                                    hide-details
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" xs="10" sm="4">
                                <p class="caption mb-0">Number</p>
                                <v-text-field solo flat outlined hide-details dense></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="2" sm="1" class="pl-0">
                                <v-btn text icon color="darkGray" class="mt-5">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="mt-1">
                            <v-col cols="12" xs="12" sm="4">
                                <p class="caption mb-0">CRM Field</p>
                                <v-text-field solo flat outlined hide-details dense></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4">
                                <p class="caption mb-0">Value</p>
                                <v-text-field solo flat outlined hide-details dense></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" class="pl-0 d-flex">
                                <v-switch label="Disable" class="mr-1 mt-6" hide-details></v-switch>
                                <v-btn text icon color="darkGray" class="mt-5">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row class="mt-1">
                            <v-col cols="12" xs="12" sm="4">
                                <p class="caption mb-0">CRM Field</p>
                                <v-text-field solo flat outlined hide-details dense></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4">
                                <p class="caption mb-0">Value</p>
                                <v-text-field solo flat outlined hide-details dense></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" class="pl-0 d-flex">
                                <v-switch label="Disable" class="mr-1 mt-6" hide-details></v-switch>
                                <v-btn text icon color="darkGray" class="mt-5">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tab: null,
            items: ['foo', 'bar'],
            showAdvancedConditions: false,
        };
    },
};
</script>
