<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="8" lg="6" xl="6">
                <v-card flat class="rounded-0" v-show="visible">
                    <v-card-text class="primary--text">
                        <p class="text-h4 mb-5 text-center">Update Personal Data</p>
                        <edit-profile
                            :profileForm="profileForm"
                            v-on:formValidity="formValidity"
                            v-on:changeFormValues="changeFormValues"
                        />
                        <div class="d-flex justify-end">
                            <v-btn
                                color="secondary"
                                elevation="0"
                                class="text-capitalize mt-6"
                                large
                                block
                                @click="triggerSave"
                                :disabled="!isFormValid"
                            >
                                Update Personal Data
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import commonService from '../../services/common-service';
import profileService from '../../services/profile-service';
import notificationsUtility from '../../utilities/notifications-utility';
import EditProfile from '../../components/profile/EditProfile.component';
export default {
    name: 'Profile',
    components: {
        EditProfile,
    },
    data() {
        return {
            profileForm: [],
            formValues: {},
            isFormValid: false,
            visible: false,
        };
    },

    watch: {},

    methods: {
        formValidity(value) {
            this.isFormValid = value;
        },
        changeFormValues(input) {
            this.profileForm.map((component) => {
                if (component.component_name === input.key)
                    this.formValues[component.component_crm_field] = input.value;
            });
        },

        triggerSave() {
            profileService
                .updateProfileData(this.formValues)
                .then((response) => {
                    if (response.data.status_code === 200) {
                        notificationsUtility.showNotifications(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        getUserProfileData() {
            profileService
                .getUserProfileData()
                .then((response) => {
                    if (response.data.status_code === 200) {
                        this.profileForm = JSON.parse(response.data.data);
                        this.visible = true;
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
    },
    mounted() {
        this.getUserProfileData();
    },
};
</script>
