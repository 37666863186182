<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    flat
                    outlined
                    dense
                    :rules="!$attrs.hidden && !enability ? validations : []"
                    :required="isRequired"
                    clearable
                    :disabled="enability"
                    :placeholder="component.component_placeholder"
                ></v-text-field>
            </template>
            <v-time-picker
                v-if="menu"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
            ></v-time-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'TimeComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            time: null,
            menu: false,
        };
    },
    watch: {
        time() {
            this.inputChanged();
        },
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.time,
                is_disable: this.enability,
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.time,
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },
    mounted() {
        this.time = this.component.component_default_value;
    },
};
</script>
