<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="7" lg="6" xl="5">
                <v-card flat class="rounded-0">
                    <v-card-text class="primary--text text-center">
                        <p class="text-h4 mb-5">Forgot Your Password?</p>
                        <p class="mb-6 secondary-font">
                            Enter the email address associated with your account and we'll send you
                            a link to reset your password.
                        </p>
                        <v-form ref="form">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Email Address"
                                            solo
                                            flat
                                            outlined
                                            dense
                                            validate-on-blur
                                            name="email"
                                            v-model="formData.email"
                                            :rules="[validations.required, validations.email]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-btn
                                    color="secondary"
                                    elevation="0"
                                    class="text-capitalize mt-3"
                                    large
                                    block
                                    @click="resetPassword"
                                >
                                    Request New Password
                                </v-btn>

                                <p class="primary--text mt-6 text-center secondary-font">
                                    Don't have an account?
                                    <a
                                        v-on:click="gotoSignUp"
                                        class="secondary--text text-decoration-none"
                                    >
                                        Sign Up
                                    </a>
                                </p>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import validations from '../../utilities/validations-utility';
import notificationsUtility from '../../utilities/notifications-utility';
import authService from '../../services/auth-service';

export default {
    name: 'ForgotPassword',
    data() {
        return {
            formData: {
                email: null,
            },
            validations,
        };
    },

    computed: {
        site() {
            return this.$store.state.common.site;
        },
    },

    methods: {
        gotoSignUp() {
            this.$router.push(`/${this.site}/register`);
        },
        resetPassword() {
            if (this.$refs.form.validate()) {
                authService
                    .resetPassword(this.formData)
                    .then((response) => {
                        notificationsUtility.showNotifications(response.data);
                        this.$store.commit('auth/setPasswordResetEmail', this.formData.email);
                        this.$router.push(`/${this.site}/account/password-reset-response`);
                    })
                    .catch((error) => {
                        notificationsUtility.showNotifications(error.response.data);
                    });
            }
        },
    },
};
</script>
