<template>
    <v-dialog :value="dialog" @click:outside="handleClose" content-class="small-dialog">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 primary--text font-weight-bold mb-0">{{ dialogTitle }}</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="handleClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="secondary-font pb-0">
                    <v-row>
                        <v-col cols="12">
                            <p class="caption mb-0">Journey name</p>
                            <v-text-field
                                v-model="formData.journey_name"
                                dense
                                outlined
                                :rules="[(v) => !!v || 'Journey name is required']"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Period</p>
                            <v-select
                                :items="academicPeriods"
                                v-model="formData.academic_year_id"
                                :rules="[(v) => !!v || 'Period is required']"
                                dense
                                outlined
                                @change="getProgramNames"
                                item-text="period"
                                item-value="uuid"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Journey Type</p>
                            <v-select
                                :items="journeyTypes"
                                v-model="formData.journey_type"
                                :rules="[(v) => !!v || 'Program Type is required']"
                                dense
                                @change="getProgramNames"
                                outlined
                                item-text="journey_type"
                                item-value="uuid"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" v-if="journeyPrograms.length > 0">
                            <p class="caption mb-0">Program Name</p>
                            <v-select
                                :items="journeyPrograms"
                                v-model="formData.program_id"
                                dense
                                outlined
                                item-text="name"
                                item-value="id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Entity Type</p>
                            <v-select
                                :items="entityTypes"
                                v-model="formData.entity_type_id"
                                :rules="[(v) => !!v || 'Entity Type is required']"
                                dense
                                outlined
                                item-text="name"
                                item-value="uuid"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Theme</p>
                            <v-select
                                :items="themeTypes"
                                v-model="formData.theme"
                                :rules="[(v) => !!v || 'Entity Type is required']"
                                dense
                                outlined
                                item-text="text"
                                item-value="color"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-switch
                        label="Admin Only"
                        v-if="isUserAdmin"
                        v-model="isAdmin"
                        flat
                        hide-details
                    ></v-switch>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary mr-2" @click="save" elevation="0"> Save</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import commonData from '../../constant/common-data';
import JourneyService from '../../services/journey-service';
import notificationsUtility from '../../utilities/notifications-utility';
export default {
    name: 'CloneJourneyPopup',
    props: [
        'selectedJourney',
        'dialog',
        'handleClose',
        'handleSave',
        'journeyTypes',
        'academicPeriods',
        'entityTypes',
    ],
    data: () => ({
        dialogTitle: 'Journey Clone',
        valid: true,
        isAdmin: true,
        journeyPrograms: [],
        formData: {
            uuid: null,
            journey_name: '',
            academic_year_id: null,
            program_id: null,
            journey_type: null,
            entity_type_id: null,
            status: 'INACTIVE',
            theme: null,
            is_allow_for_only_applied: false,
        },
        themeTypes: commonData.themes,
    }),
    watch: {
        isAdmin() {
            this.formData.status = this.isAdmin === true ? 'INACTIVE' : 'ACTIVE';
        },
        selectedJourney() {
            if (this.selectedJourney) {
                this.formData = {
                    ...(({ theme, entity_type_id, academic_year_id, uuid }) => ({
                        theme,
                        entity_type_id,
                        academic_year_id,
                        uuid,
                    }))(this.selectedJourney),
                    journey_name: `Copy of ${this.selectedJourney.journey_name}`,
                    status: 'INACTIVE',
                    journey_type: this.selectedJourney.journey_type_id,
                };
                this.getProgramNames();
                this.formData.program_id = this.selectedJourney.program_id;
            } else {
                Object.assign(this.$data, this.$options.data());
            }
        },
    },
    methods: {
        save() {
            this.handleSave({ ...this.formData });
        },
        getProgramNames() {
            if (this.formData.academic_year_id !== null && this.formData.journey_type !== null) {
                this.formData.program_id = null;
                const params = {
                    academicYear: this.formData.academic_year_id,
                    journeyTypeId: this.formData.journey_type,
                };
                JourneyService.getPrograms(params)
                    .then((response) => {
                        this.journeyPrograms = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isUserAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return ['ADMIN'].includes(this.currentUser.role[0].name);
            }

            return false;
        },
    },
};
</script>

<style></style>
