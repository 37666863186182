var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisibleTabClick)?_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}]},[_c('div',{staticClass:"p-5"},[_c('p',{staticClass:"header-text mb-5 text-capitalize journey-header-text"},[_vm._v(" "+_vm._s(_vm.journeyItem.tab_display_name)+" ")]),_c('v-form',{ref:_vm.journeyItem.tab_name,model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_vm._l((_vm.onlyActiveSections),function(section,index){return _c('journey-section',{key:index,attrs:{"journeySection":section,"section":_vm.sections[index],"appearanceSection":_vm.appearanceSections[index],"allSections":_vm.sections,"allTabs":_vm.allTabs,"isEditMood":_vm.isEditMood,"activeTab":_vm.activeTab,"isVisible":_vm.isVisible,"isDisable":_vm.isDisable,"isSectionDisable":_vm.appearanceSections.hasOwnProperty('is_disable')
                        ? !_vm.appearanceSections[index].is_disable
                        : false,"isSectionVisible":_vm.appearanceSections.hasOwnProperty('is_hidden')
                        ? !_vm.appearanceSections[index].is_hidden
                        : true,"applicationSubmit":_vm.applicationSubmit},on:{"sectionComponents":_vm.sectionComponents,"appearanceSectionComponents":_vm.appearanceSectionComponents}})}),_c('div',{staticClass:"d-flex justify-end"},[(
                        !_vm.application ||
                        (_vm.application && _vm.application.applicationSubmitted === 'No')
                    )?_c('v-btn',{staticClass:"text-capitalize mt-2 text-end",attrs:{"color":_vm.journeyItem.is_submit ? 'secondary' : 'primary',"elevation":"0","large":"","dark":"","disabled":_vm.isDisable ||
                        (_vm.journeyItem.is_submit
                            ? !_vm.isFormValid || !_vm.eligibleForSubmit
                            : !_vm.isFormValid)},on:{"click":function($event){_vm.journeyItem.read_only ? _vm.triggerNextTab() : _vm.triggerSave()}}},[_vm._v(" "+_vm._s(_vm.journeyItem.button_text)+" ")]):_vm._e(),(_vm.application && _vm.application.applicationSubmitted === 'Yes')?_c('v-btn',{staticClass:"text-capitalize mt-2 text-end",attrs:{"color":"primary","elevation":"0","large":"","dark":"","disabled":!_vm.isFormValid},on:{"click":function($event){_vm.journeyItem.read_only ? _vm.triggerNextTab() : _vm.triggerSave()}}},[_vm._v(" Save & Next ")]):_vm._e()],1)],2)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }