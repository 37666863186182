<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-select
            :ref="component.component_name"
            :items="component.component_data_set ? component.component_data_set : []"
            item-text="name"
            item-value="name"
            solo
            flat
            outlined
            dense
            :value="setDefaultValue(component.component_data_set)"
            @change="inputChanged"
            :rules="componentValidation"
            :required="isRequired"
            clearable
            :disabled="enability"
            :placeholder="component.component_placeholder"
        ></v-select>
    </div>
</template>

<script>
export default {
    name: 'DropDownComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        componentValidation() {
            if (!this.$attrs.hidden && !this.enability) {
                return this.validations;
            }

            return [];
        },
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        setDefaultValue(dataSet) {
            if (dataSet && dataSet.length > 0) {
                let filteredValue = dataSet.filter((value) => {
                    return (
                        value.default === true ||
                        value.name === this.component.component_default_value
                    );
                });

                if (filteredValue && filteredValue.length > 0) {
                    return filteredValue[0].name;
                }
            }

            return null;
        },

        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },

    mounted() {
        if (this.component) {
            setTimeout(() => {
                this.inputChanged();
                this.appearanceInputChanged();
            }, 1000);
        }
    },
};
</script>
