import ProfileService from '../../../services/profile-service';
import JourneyService from '../../../services/journey-service';

export default {
    saveProfileForm({ commit }, payload) {
        ProfileService.saveProfileForm(payload).then((response) => {
            if (response.status === 200) {
                return Promise.resolve(response);
            }
        });
    },
    getEntityTypes({ commit }) {
        JourneyService.getEntityTypes().then((response) => {
            if (response.data.data) {
                commit('journey/updateEntityTypes', response.data.data);
            }
        });
    },
};
