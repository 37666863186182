<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="optionsGroup">
                    <v-radio
                        v-for="(option, index) in options"
                        :key="index"
                        :label="option"
                        :value="option"
                    ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'ExistingDatabase',
    data() {
        return {
            optionsGroup: 1,
            options: ['Country', 'University', 'City'],
        };
    },
};
</script>
