<template>
    <v-container>
        <h1 class="primary--text">Create Personal Detail Form</h1>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                <v-card class="mx-auto rounded-12 mt-5" flat :height="height">
                    <v-card-text>
                        <div
                            class="d-flex mb-5"
                            v-for="(itemComponent, index) in profileState"
                            :key="index"
                        >
                            <div class="mr-2 d-flex flex-column">
                                <v-btn
                                    x-small
                                    text
                                    icon
                                    color="darkGray"
                                    :disabled="index === 0"
                                    @click="updateComponentPosition(index, 'up')"
                                >
                                    <v-icon x-small>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn
                                    x-small
                                    text
                                    icon
                                    color="darkGray"
                                    @click="updateComponentPosition(index, 'down')"
                                >
                                    <v-icon x-small>mdi-arrow-down</v-icon>
                                </v-btn>
                            </div>
                            <TextFieldComponent
                                v-if="itemComponent.hide"
                                :itemComponent="itemComponent"
                                v-on:click.native="show(index)"
                            />
                            <profile-form
                                v-if="!itemComponent.hide"
                                :componentData="itemComponent"
                                :selectedComponent="index"
                                :saveProfile="saveProfile"
                                v-on:after-save-profile="afterSaveProfile"
                            />
                        </div>
                        <profile-form v-if="profileState.length === 0" />
                        <v-btn
                            fixed
                            bottom
                            right
                            color="primary"
                            elevation="3"
                            fab
                            @click="saveProfileForm"
                        >
                            <v-icon> mdi-content-save-outline </v-icon></v-btn
                        >
                        <v-btn
                            v-show="false"
                            outlined
                            color="primary"
                            class="mt-5"
                            block
                            large
                            @click="saveProfileForm()"
                        >
                            <v-icon left>mdi-content-save-outline</v-icon>
                            Save
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <validation-popup
                v-if="openErrorDialog"
                :openDialog="openErrorDialog"
                @close-error-dialog="closeErrorDialg"
                :errorList="componentErrorList"
            />
        </v-row>
    </v-container>
</template>

<script>
import ProfileForm from '../../components/profile/ProfileForm.component';
import store from '../../store/index';
import TextFieldComponent from '../../components/journey/items/TextFieldComponent';
import common from '../../constant/common-data';
import notificationsUtility from '../../utilities/notifications-utility';
import ProfileService from '../../services/profile-service';
import ValidationPopup from '../../components/journey/FormValidationPopup.component';
export default {
    name: 'CreateProfile',
    components: {
        ProfileForm,
        TextFieldComponent,
        ValidationPopup,
    },
    data() {
        return {
            height: '100%',
            form_data: null,
            saveProfile: false,
            openedComponent: false,
            openErrorDialog: false,
            componentErrorList: [],
        };
    },
    methods: {
        closeErrorDialg() {
            this.openErrorDialog = false;
        },
        show(currentIndex) {
            (this.openedComponent = true),
                this.$store.commit('profile/expandCurrentComponent', currentIndex);
        },
        updateComponentPosition(componentKey, action) {
            this.$store.commit('profile/updateComponentPosition', { componentKey, action });
        },
        checkRequiredFields() {
            if (this.profileState.length > 0) {
                let failedComps = [];
                this.profileState.map((component) => {
                    let checkAdvance = true;
                    if (
                        component.is_advance_condition === true &&
                        common.inputTypesProfile.find(
                            (item) => item.value === component.component_type
                        ).validation_allow === true
                    ) {
                        checkAdvance = this.checkAdvanceCondition(
                            component.advance_validation_rules
                        );
                    }

                    if (component.component_type === 'CRM_DROP_DOWN') {
                        if (
                            component.component_label === null ||
                            component.component_label === '' ||
                            component.crm_drop_down.entity === null ||
                            component.entity === '' ||
                            component.crm_drop_down.value_field === null ||
                            component.value_field === '' ||
                            component.crm_drop_down.display_field === null ||
                            component.display_field === '' ||
                            (common.inputTypes.find(
                                (item) => item.value === component.component_type
                            ).crm_required === true &&
                                (component.component_crm_field === null ||
                                    component.component_crm_field === '')) ||
                            checkAdvance === false
                        ) {
                            failedComps.push(
                                component.component_name + ` (Ref : ${component.component_ref})`
                            );
                        }
                    } else {
                        if (
                            component.component_label === null ||
                            component.component_label === '' ||
                            (common.inputTypes.find(
                                (item) => item.value === component.component_type
                            ).crm_required === true &&
                                (component.component_crm_field === null ||
                                    component.component_crm_field === '')) ||
                            checkAdvance === false
                        ) {
                            failedComps.push(
                                component.component_name + ` (Ref : ${component.component_ref})`
                            );
                        }
                    }
                });
                this.componentErrorList = failedComps;
            }
        },
        checkAdvanceCondition(conditions) {
            const rules = ['visibility_rules', 'edit_rules', 'enability_rules', 'validation_rules'];
            const data = rules.every((element) => {
                let count = 0;
                if (element !== 'validation_rules') {
                    if (
                        conditions[element]['action'] !== null &&
                        conditions[element]['action'] !== ''
                    )
                        count = count + 1;
                    if (
                        conditions[element]['parent_field'] !== null &&
                        conditions[element]['parent_field'] !== ''
                    )
                        count = count + 1;
                    if (
                        conditions[element]['value'] !== null &&
                        conditions[element]['value'] !== ''
                    )
                        count = count + 1;
                } else {
                    if (
                        (conditions[element]['value'] === null ||
                            conditions[element]['value'] === '') &&
                        conditions[element]['type'] !== null &&
                        conditions[element]['type'] !== ''
                    )
                        return false;
                    if (
                        conditions[element]['value'] !== null &&
                        conditions[element]['value'] !== '' &&
                        ((conditions[element]['type'] === null &&
                            conditions[element]['type'] === '') ||
                            (conditions[element]['field_type'] === null &&
                                conditions[element]['field_type'] === null))
                    )
                        return false;
                }
                if (count > 0 && count < 3) {
                    return false;
                }
                return true;
            });
            return data;
        },
        saveProfileForm() {
            if (this.openedComponent) {
                this.saveProfile = !this.saveProfile;
            } else {
                this.checkRequiredFields();

                if (this.componentErrorList.length > 0) {
                    this.openErrorDialog = true;
                } else {
                    this.$store.dispatch('profile/saveProfileForm', this.profileState).then(
                        () => {
                            notificationsUtility.pushNotifications([
                                { message: 'Personal Detail Form Save Success', type: 'success' },
                            ]);
                        },
                        (error) => {}
                    );
                }
            }
        },

        afterSaveProfile(value) {
            if (value === 'saved') {
                this.openedComponent = false;
                this.saveProfile = false;
                this.saveProfileForm();
            }
            if (value === 'deleted') {
                this.openedComponent = false;
                this.saveProfile = false;
            }
            if (value === 'saved-failed') {
                this.saveProfile = false;
            }
        },
        getCreateProfileForm() {
            ProfileService.getCreateProfileForm()
                .then((response) => {
                    if (response.data) {
                        this.form_data = response.data;
                        this.$store.commit('profile/addCreateFormData', response.data.form_data);
                    }
                })
                .catch(() => {});
        },
    },
    mounted() {
        this.getCreateProfileForm();
    },
    computed: {
        profileState: () => {
            return store.state.profile.profileFormData;
        },
    },
};
</script>

<style scoped></style>
