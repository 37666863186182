<template>
    <div class="d-flex section-card">
        <v-card class="rounded-12 section-card selected-component" flat outlined>
            <v-card-text class="px-6">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col cols="12" xs="12" sm="7" md="7">
                            <v-text-field
                                label="Label"
                                v-model="component.component_label"
                                :rules="[(v) => !!v || 'Label is required']"
                                flat
                                outlined
                                dense
                                @input="generateFieldName()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="5">
                            <v-select
                                :items="inputTypes"
                                v-model="component.component_type"
                                :rules="[(v) => !!v || 'Text field is required']"
                                label="Field Type"
                                @change="changeType()"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="pb-2">
                        <v-col
                            cols="12"
                            xs="12"
                            sm="7"
                            md="7"
                            v-if="
                                component.component_type !== 'LABEL' &&
                                component.component_type !== 'SPACER' &&
                                component.component_type !== 'PAYMENT' &&
                                component.component_type !== 'TEXT_EDITOR' &&
                                component.component_type !== 'APPLICATION_STATUS' &&
                                component.component_type !== 'COURSES' &&
                                component.component_type !== 'FILE_UPLOAD'
                            "
                        >
                            <v-text-field
                                label="Placeholder"
                                v-model="component.component_placeholder"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="5" md="5">
                            <v-select
                                :items="gridSizes"
                                v-model="component.grid_size"
                                label="Grid Size"
                                flat
                                outlined
                                hide-details
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>

                    <TextField
                        v-if="component.component_type === 'TEXT_FIELD'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <TextArea
                        v-if="component.component_type === 'TEXT_AREA'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <DropDown
                        v-if="component.component_type === 'DROP_DOWN'"
                        v-on:update-values="updateComponentValues"
                        :componentDataSet="component.component_data_set"
                        :isShowLabel="component.is_show_label"
                    />

                    <CheckBox
                        v-if="component.component_type === 'CHECK_BOX'"
                        v-on:update-values="updateComponentValues"
                        :componentDataSet="component.component_data_set"
                        :isShowLabel="component.is_show_label"
                        :defaultValue="component.component_default_value"
                    />

                    <Radio
                        v-if="component.component_type === 'RADIO'"
                        v-on:update-values="updateComponentValues"
                        :componentDataSet="component.component_data_set"
                        :isShowLabel="component.is_show_label"
                    />

                    <Date
                        v-if="component.component_type === 'DATE'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <Time
                        v-if="component.component_type === 'TIME'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                    />

                    <TextEditor
                        v-if="component.component_type === 'TEXT_EDITOR'"
                        v-on:update-values="updateComponentValues"
                        :defaultValue="component.component_default_value"
                        :isShowLabel="component.is_show_label"
                        :isHidden="component.is_hidden"
                        :isAdmin="component.is_admin"
                    />

                    <FileUpload
                        v-if="component.component_type === 'FILE_UPLOAD'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                    />

                    <FromDatabase
                        v-if="component.component_type === 'DATABASE'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :dbField="component.db_field"
                    />

                    <CrmDropDown
                        v-if="component.component_type === 'CRM_DROP_DOWN'"
                        v-on:update-values="updateComponentValues"
                        :isShowLabel="component.is_show_label"
                        :crmDropdown="component.crm_drop_down"
                    />

                    <v-row
                        v-if="
                            component.component_type !== 'TEXT_EDITOR' &&
                            component.component_type !== 'LABEL' &&
                            component.component_type !== 'SPACER' &&
                            component.component_type !== 'PAYMENT'
                        "
                        class="pt-2 entity-section"
                    >
                        <v-col cols="12" xs="12" sm="5" md="5">
                            <v-select
                                :items="entityTypes"
                                label="Entity Type"
                                v-model="component.component_entity"
                                :rules="[(v) => !!v || 'Entity Type is required']"
                                flat
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="7" md="7">
                            <v-text-field
                                label="CRM Field"
                                :rules="[(v) => !!v || 'Text field is required']"
                                v-model="component.component_crm_field"
                                flat
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="
                            component.component_type !== 'TEXT_EDITOR' &&
                            component.component_type !== 'LABEL' &&
                            component.component_type !== 'SPACER' &&
                            component.component_type !== 'PAYMENT'
                        "
                    >
                        <v-col cols="12" xs="12" sm="8" md="8" class="d-flex">
                            <v-switch
                                v-model="component.advance_validation_rules.is_required"
                                label="Required"
                                hide-details
                                class="mr-4 mt-2"
                                dense
                            ></v-switch>
                            <v-switch
                                label="Admin Only"
                                v-model="component.is_admin_only"
                                hide-details
                                class="mr-4 mt-2"
                                dense
                            ></v-switch>
                            <v-switch
                                label="Disable"
                                v-model="component.is_disable"
                                hide-details
                                class="mr-4 mt-2"
                                dense
                            ></v-switch>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4" md="4" v-show="false">
                            <v-checkbox
                                label="Advanced Condition"
                                v-model="component.is_advance_condition"
                                outlined
                                @change="changeAdvanceCondition"
                                hide-details
                                dense
                                class="mt-2"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if="visibleAdvanceCondition">
                        <v-col
                            cols="12"
                            v-if="
                                component.component_type !== 'TEXT_EDITOR' &&
                                component.component_type !== 'LABEL' &&
                                component.component_type !== 'SPACER' &&
                                component.component_type !== 'PAYMENT'
                            "
                        >
                            <v-tabs v-model="tab">
                                <v-tab :key="1"> Validation</v-tab>
                                <v-tab :key="2"> Enability</v-tab>
                                <v-tab :key="3"> Visibility</v-tab>
                                <v-tab :key="4"> Edit Condition</v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item :key="1">
                                    <v-row class="mt-1">
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-select
                                                :items="types"
                                                label="Field Type"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .validation_rules.field_type
                                                "
                                                flat
                                                outlined
                                                @change="changeFieldType"
                                                dense
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-select
                                                :items="fieldLogic"
                                                label="Action"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .validation_rules.type
                                                "
                                                :disabled="
                                                    component.advance_validation_rules
                                                        .validation_rules.field_type === 'email' &&
                                                    component.component_type === 'TEXT_FIELD'
                                                "
                                                flat
                                                outlined
                                                dense
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="10" sm="3">
                                            <v-text-field
                                                label="value"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .validation_rules.value
                                                "
                                                flat
                                                :disabled="
                                                    (component.advance_validation_rules
                                                        .validation_rules.field_type === 'email' &&
                                                        component.component_type ===
                                                            'TEXT_FIELD') ||
                                                    component.advance_validation_rules
                                                        .validation_rules.type === null
                                                "
                                                outlined
                                                :rules="getValidation('Value')"
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" class="pl-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                @click="resetValidation('type')"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item :key="2">
                                    <v-row class="mt-1">
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-text-field
                                                label="CRM Field"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules.parent_field
                                                "
                                                flat
                                                outlined
                                                dense
                                                :rules="
                                                    getValidationRules(
                                                        'enability_rules',
                                                        'parent_field',
                                                        'CRM field'
                                                    )
                                                "
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="10" sm="4">
                                            <v-text-field
                                                label="value"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules.value
                                                "
                                                flat
                                                outlined
                                                dense
                                                :rules="
                                                    getValidationRules(
                                                        'enability_rules',
                                                        'value',
                                                        'Value'
                                                    )
                                                "
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3">
                                            <v-select
                                                :items="enableActions"
                                                label="action"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .enability_rules.action
                                                "
                                                flat
                                                outlined
                                                dense
                                                :rules="
                                                    getValidationRules(
                                                        'enability_rules',
                                                        'action',
                                                        'Action'
                                                    )
                                                "
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" class="pl-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                @click="resetValidation('enability')"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item :key="3">
                                    <v-row class="mt-1">
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-text-field
                                                label="CRM Field"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules.parent_field
                                                "
                                                flat
                                                outlined
                                                :rules="
                                                    getValidationRules(
                                                        'visibility_rules',
                                                        'parent_field',
                                                        'CRM field'
                                                    )
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="10" sm="4">
                                            <v-text-field
                                                label="value"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules.value
                                                "
                                                flat
                                                outlined
                                                :rules="
                                                    getValidationRules(
                                                        'visibility_rules',
                                                        'value',
                                                        'Value'
                                                    )
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3">
                                            <v-select
                                                :items="visibleActions"
                                                label="action"
                                                v-model="
                                                    component.advance_validation_rules
                                                        .visibility_rules.action
                                                "
                                                flat
                                                outlined
                                                :rules="
                                                    getValidationRules(
                                                        'visibility_rules',
                                                        'action',
                                                        'Action'
                                                    )
                                                "
                                                dense
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" class="pl-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                @click="resetValidation('visibility')"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item :key="4">
                                    <v-row class="mt-1">
                                        <v-col cols="12" xs="12" sm="4">
                                            <v-text-field
                                                label="CRM Field"
                                                v-model="
                                                    component.advance_validation_rules.edit_rules
                                                        .parent_field
                                                "
                                                flat
                                                outlined
                                                :rules="
                                                    getValidationRules(
                                                        'edit_rules',
                                                        'parent_field',
                                                        'CRM field'
                                                    )
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="10" sm="4">
                                            <v-text-field
                                                label="value"
                                                v-model="
                                                    component.advance_validation_rules.edit_rules
                                                        .value
                                                "
                                                flat
                                                outlined
                                                :rules="
                                                    getValidationRules(
                                                        'edit_rules',
                                                        'value',
                                                        'Value'
                                                    )
                                                "
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3">
                                            <v-select
                                                :items="enableActions"
                                                label="action"
                                                v-model="
                                                    component.advance_validation_rules.edit_rules
                                                        .action
                                                "
                                                flat
                                                outlined
                                                :rules="
                                                    getValidationRules(
                                                        'edit_rules',
                                                        'action',
                                                        'Action'
                                                    )
                                                "
                                                dense
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" class="pl-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                @click="resetValidation('edit_rules')"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <div class="floating-icons">
            <v-card class="rounded-12" flat>
                <v-card-text class="pa-1 d-flex flex-column">
                    <v-btn text icon color="darkGray" @click="addComponent()">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <v-btn v-show="false" text icon color="darkGray" @click="updateComponent()">
                        <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="darkGray" @click="deleteComponent()">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <!--<v-btn text icon color="darkGray" @click="duplicateComponent()">-->
                    <!--<v-icon>mdi-content-copy</v-icon>-->
                    <!--</v-btn>-->
                </v-card-text>
            </v-card>
        </div>
        <delete-alert ref="confirm" />
    </div>
</template>

<script>
import store from '../../store/index';
import common from '../../constant/common-data';
import TextArea from '../journey/component-types/TextArea';
import TextField from '../journey/component-types/TextField';
import DropDown from '../journey/component-types/DropDown';
import CheckBox from '../journey/component-types/CheckBox';
import Radio from '../journey/component-types/Radio';
import Date from '../journey/component-types/Date';
import Time from '../journey/component-types/Time';
import TextEditor from '../journey/component-types/TextEditor';
import FileUpload from '../journey/component-types/FileUpload';
import FromDatabase from '../journey/component-types/FromDatabase';
import CrmDropDown from '../journey/component-types/CrmDropdown';
import SectionModel from '../../models/Component';
import DeleteAlert from '../../components/alert/ConfirmAlert.componet';
import notificationsUtility from '../../utilities/notifications-utility';

export default {
    name: 'ProfileForm.component',
    store,
    common,
    components: {
        TextArea,
        TextField,
        DropDown,
        CheckBox,
        Radio,
        Date,
        Time,
        TextEditor,
        FileUpload,
        FromDatabase,
        CrmDropDown,
        DeleteAlert,
    },
    props: {
        selectedSection: Object,
        selectedComponent: Number,
        componentData: Object,
        saveProfile: Boolean,
    },
    data() {
        return {
            tab: null,
            inputTypes: common.inputTypesProfile,
            entityTypes: common.entityTypesProfile,
            valid: true,
            gridSizes: common.gridSizes,
            types: [],
            fieldLogic: [],
            visibleActions: common.visibleActions,
            enableActions: common.enableActions,
            isEdited: false,
            grid_size: 12,
            visibleAdvanceCondition: false,
            component: {
                component_name: null,
                component_label: null,
                component_placeholder: null,
                component_entity: null,
                component_crm_field: null,
                component_type: null,
                component_default_value: null,
                is_admin_only: false,
                is_show_label: true,
                is_disable: false,
                is_hidden: false,
                is_advance_condition: false,
                db_field: null,
                grid_size: 12,
                is_frontend_insertion: false,
                hide: true,
                is_full_width: true,
                crm_drop_down: {
                    entity: null,
                    value_field: null,
                    display_field: null,
                    is_search_option: false,
                    is_multi_option: false,
                    conditions: [],
                },
                component_data_set: [],
                advance_validation_rules: {
                    is_required: false,
                    field_type: null,
                    validation_rules: {
                        field_type: null,
                        value: null,
                        type: null,
                    },
                    enability_rules: {
                        parent_field: null,
                        value: null,
                        action: null,
                    },
                    visibility_rules: {
                        parent_field: null,
                        value: null,
                        action: null,
                    },
                    edit_rules: {
                        parent_field: null,
                        value: null,
                        action: null,
                    },
                },
            },
        };
    },
    methods: {
        changeAdvanceCondition() {
            if (this.component.is_advance_condition === false) {
                this.component.advance_validation_rules.validation_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.validation_rules
                );
                this.component.advance_validation_rules.enability_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.enability_rules
                );
                this.component.advance_validation_rules.visibility_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.visibility_rules
                );
                this.component.advance_validation_rules.edit_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.edit_rules
                );
            }
        },
        getValidationRules(model, field, name) {
            switch (field) {
                case 'parent_field':
                    if (
                        (this.component.advance_validation_rules[model].value !== null &&
                            this.component.advance_validation_rules[model].value !== '') ||
                        (this.component.advance_validation_rules[model].action !== null &&
                            this.component.advance_validation_rules[model].action !== '')
                    ) {
                        return [(v) => !!v || `${name} is required`];
                    }
                    break;
                case 'value':
                    if (
                        (this.component.advance_validation_rules[model].parent_field !== null &&
                            this.component.advance_validation_rules[model].parent_field !== '') ||
                        (this.component.advance_validation_rules[model].action !== null &&
                            this.component.advance_validation_rules[model].action !== '')
                    ) {
                        return [(v) => !!v || `${name} is required`];
                    }
                    break;

                case 'action':
                    if (
                        (this.component.advance_validation_rules[model].value !== null &&
                            this.component.advance_validation_rules[model].value !== '') ||
                        (this.component.advance_validation_rules[model].action !== null &&
                            this.component.advance_validation_rules[model].action !== '')
                    ) {
                        return [(v) => !!v || `${name} is required`];
                    }
                    break;
                default:
                    break;
            }
        },
        getValidation(type) {
            if (
                this.component.advance_validation_rules.validation_rules.field_type !== null &&
                this.component.advance_validation_rules.validation_rules.field_type !== '' &&
                this.component.advance_validation_rules.validation_rules.type !== null &&
                this.component.advance_validation_rules.validation_rules.type !== ''
            ) {
                return [(v) => !!v || `${type} is required`];
            } else {
                return [];
            }
        },
        changeFieldType() {
            switch (this.component.advance_validation_rules.validation_rules.field_type) {
                case 'number':
                    this.fieldLogic = common.numberLogic;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    break;
                case 'text':
                    this.fieldLogic = common.itemsLength;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    break;
                case 'email':
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    this.fieldLogic = [];
                    break;
                default:
                    this.fieldLogic = [];
                    break;
            }
        },
        generateFieldName() {
            let name = this.component.component_label;
            this.component.component_name = name.replace(/[^A-Z0-9]/gi, '_').toLowerCase();
        },
        changeType() {
            if (this.component.component_type === 'TEXT_FIELD') {
                this.types = common.textFieldTypes;
            } else {
                this.component.advance_validation_rules.validation_rules = Object.assign(
                    {},
                    this.$options.data().component.advance_validation_rules.validation_rules
                );
                this.types = [];
            }
            this.fieldLogic = [];
            // this.component.crm_required = common.inputTypes.find((item) => //TODO IMPLEMENT
            //     item.value === this.component.component_type).crm_required;
            this.component.component_default_value = null;
            this.component.component_data_set = [];
            this.component.advance_validation_rules.validation_rules.value = null;
            this.component.advance_validation_rules.validation_rules.type = null;
        },
        addComponent() {
            if (this.$refs.form.validate()) {
                this.component.hide = !this.component.hide;
                if (this.isEdited) {
                    const sectionComponent = new SectionModel();
                    let newComponent = sectionComponent.components[0];
                    newComponent.hide = !newComponent.hide;
                    newComponent.component_entity = 'Contact';
                    const params = {
                        selectedComponent: this.selectedComponent,
                        component: this.component,
                        newComponent: newComponent,
                    };
                    this.$store.commit('profile/addNewComponent', { ...params });
                } else {
                    this.$store.commit('profile/addComponent', this.component);
                    Object.assign(this.$data, this.$options.data());
                }
            }
        },
        updateComponent() {
            if (this.isEdited && this.$refs.form.validate()) {
                const params = {
                    component: this.component,
                    selectedComponent: this.selectedComponent,
                };
                this.$store.commit('profile/updateComponent', { ...params });
            }
        },
        deleteComponent() {
            if (this.profileState.length !== 1) {
                this.$refs.confirm
                    .open('Are you sure', 'Do you want to delete this component ?', {
                        color: 'red',
                    })
                    .then((confirm) => {
                        if (confirm) {
                            this.$store.commit('profile/deleteComponent', this.selectedComponent);
                            this.$emit('after-save-profile', 'deleted');
                        }
                    });
            }
        },
        duplicateComponent() {
            const params = {
                component: this.component,
                selectedComponent: this.selectedComponent,
            };
            this.$store.commit('profile/duplicateComponent', { ...params });
            this.component.hide = !this.component.hide;
        },
        updateComponentValues(params) {
            this.component.component_default_value = params.component_default_value
                ? params.component_default_value
                : null;
            this.component.is_hidden = params.is_hidden ? params.is_hidden : false;
            this.component.is_show_label = params.is_show_label ? params.is_show_label : false;
            this.component.component_data_set = params.component_data_set
                ? params.component_data_set
                : null;

            if (this.component.component_type === 'CRM_DROP_DOWN') {
                this.component.crm_drop_down.entity = params.crm_drop_down.entity
                    ? params.crm_drop_down.entity
                    : null;
                this.component.crm_drop_down.value_field = params.crm_drop_down.value_field
                    ? params.crm_drop_down.value_field
                    : null;
                this.component.crm_drop_down.display_field = params.crm_drop_down.display_field
                    ? params.crm_drop_down.display_field
                    : null;
                this.component.crm_drop_down.is_search_option = params.crm_drop_down
                    .is_search_option
                    ? params.crm_drop_down.is_search_option
                    : null;
                this.component.crm_drop_down.conditions = params.crm_drop_down.conditions
                    ? params.crm_drop_down.conditions
                    : [];

                this.component.crm_drop_down.is_multi_option = params.crm_drop_down.is_multi_option
                    ? params.crm_drop_down.is_multi_option
                    : null;
            }

            if (this.component.component_type === 'TEXT_EDITOR') {
                this.component.is_admin_only = params.is_admin ? params.is_admin : false;
            }
            if (this.component.component_type === 'DATABASE') {
                this.component.db_field = params.db_field ? params.db_field : null;
                this.component.is_frontend_insertion = params.is_frontend_insertion
                    ? params.is_frontend_insertion
                    : null;
            }
        },
        resetValidation(value) {
            switch (value) {
                case 'type':
                    this.component.advance_validation_rules.validation_rules.field_type = null;
                    this.component.advance_validation_rules.validation_rules.value = null;
                    this.component.advance_validation_rules.validation_rules.type = null;
                    this.fieldLogic = [];
                    break;
                case 'visibility':
                    this.component.advance_validation_rules.visibility_rules.parent_field = null;
                    this.component.advance_validation_rules.visibility_rules.action = null;
                    this.component.advance_validation_rules.visibility_rules.value = null;
                    break;
                case 'enability':
                    this.component.advance_validation_rules.enability_rules.parent_field = null;
                    this.component.advance_validation_rules.enability_rules.action = null;
                    this.component.advance_validation_rules.enability_rules.value = null;
                    break;
                case 'edit_rules':
                    this.component.advance_validation_rules.edit_rules.parent_field = null;
                    this.component.advance_validation_rules.edit_rules.action = null;
                    this.component.advance_validation_rules.edit_rules.value = null;
                    break;
                default:
                    break;
            }
        },
        setInitials() {
            if (this.componentData && this.componentData.component_type) {
                this.component = this.componentData;
                this.isEdited = true;
                if (this.componentData.component_type === 'TEXT_FIELD') {
                    this.types = common.textFieldTypes;
                }
                switch (this.component.advance_validation_rules.validation_rules.field_type) {
                    case 'number':
                        this.fieldLogic = common.numberLogic;
                        break;
                    case 'text':
                        this.fieldLogic = common.itemsLength;
                        break;
                    case 'email':
                        this.component.advance_validation_rules.validation_rules.value = null;
                        this.component.advance_validation_rules.validation_rules.type = null;
                        this.fieldLogic = [];
                        break;
                    default:
                        this.fieldLogic = [];
                        break;
                }
            }
        },
        saveProfileForm() {
            if (this.saveProfile === true) {
                if (this.isEdited && this.$refs.form.validate()) {
                    const params = {
                        component: this.component,
                        selectedComponent: this.selectedComponent,
                    };
                    this.$store.commit('profile/updateComponent', { ...params });
                    this.$store.commit('profile/minimizeCurrentComponent', this.selectedComponent);
                    this.$emit('after-save-profile', 'saved');
                }
            } else {
                this.$emit('after-save-profile', 'saved-failed');
            }
        },
    },
    mounted() {
        this.setInitials();
    },

    watch: {
        saveProfile() {
            this.saveProfileForm();
        },
    },
    computed: {
        profileState: () => {
            return store.state.profile.profileFormData;
        },
    },
};
</script>

<style scoped></style>
