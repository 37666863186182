export default {
    SET_NOTIFICATIONS: (state, notifications) => {
        state.notifications = notifications;
    },
    REMOVE_NOTIFICATIONS: (state) => {
        state.notifications = [];
    },
    ADD_REQUEST: (state) => {
        state.pendingRequestsCount += 1;
    },
    REMOVE_REQUEST: (state) => {
        state.pendingRequestsCount =
            state.pendingRequestsCount > 0 ? state.pendingRequestsCount - 1 : 0;
    },
    CURRENT_APPLICATION(state, payload) {
        state.curentApplication = payload;
    },
    SET_SYSTEM_SETTINGS(state, payload) {
        state.isSystemSettingsLoaded = true;
        state.systemSettings = payload;
    },
    SET_SITES(state, payload) {
        state.sites = payload;

        state.sitesArray = state.sites.map((site) => {
            return site.value;
        });
    },
    SET_SESSION_TIME_OUT_MESSAGE(state, payload) {
        state.sessionTimeOutMessage = payload;
    },
    ACTIVATE_MAINTENANCE_MODE(state) {
        state.maintenanceMode = true;
    },
    CURRENT_SITE(state, payload) {
        state.site = payload;
    },
    CURRENT_SETTING_SITE(state, payload) {
        state.settingsSite = payload;
    },
};
