<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="primary--text text-center">
                <p class="display-1 mb-0">Account Created</p>
                <v-icon class="icon-200 mt-10 mb-8" color="success">
                    mdi-checkbox-marked-circle-outline
                </v-icon>
                <br />
                <p class="display-1">Thank You!</p>
                <p class="secondary-font">
                    Your new account has been created successfully, please check your email to
                    confirm registration and receive your password.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'CreateAccountResponse',
    data() {
        return {
            logo_url: require('@/assets/logo/BSE_logo_horizontal_dark.png'),
        };
    },
};
</script>
