<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="secondary-font pb-0">
            <v-row>
                <v-col cols="6">
                    <p class="caption mb-0">Module</p>
                    <v-combobox
                        solo
                        flat
                        outlined
                        dense
                        :items="emailModules"
                        item-text="text"
                        v-model="notification.module"
                        :rules="[validations.required]"
                    ></v-combobox>
                </v-col>
                <v-col cols="6">
                    <p class="caption mb-0">Sub Module</p>
                    <v-combobox
                        solo
                        flat
                        outlined
                        dense
                        :items="emailSubModules"
                        item-text="text"
                        v-model="notification.sub_module"
                        :rules="[validations.required]"
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Name</p>
                    <v-text-field
                        v-model="notification.name"
                        dense
                        outlined
                        :rules="[validations.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Subject</p>
                    <v-text-field
                        v-model="notification.subject"
                        dense
                        outlined
                        :rules="[validations.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Body</p>
                    <quill-editor
                        v-model="notification.email_body"
                        :options="quillOptions"
                        class="quill-text-editor"
                    />
                    <v-textarea
                        class="quill-content-container"
                        v-model="notification.email_body"
                        dense
                        outlined
                        rows="2"
                        :rules="[validations.required]"
                    >
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Signature</p>
                    <v-textarea
                        v-model="notification.signature"
                        dense
                        outlined
                        rows="2"
                        :rules="[validations.required]"
                    >
                    </v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary mr-2" @click="handleFormSubmit" elevation="0"> Save</v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
import { emailModules } from '../../utilities/constants-utility';
import validations from '../../utilities/validations-utility';
export default {
    name: 'EmailNotificationForm',
    props: ['notification', 'handleSave'],
    data() {
        return {
            validations,
            emailModules,
            valid: true,
            quillOptions: {
                placeholder: null,
            },
        };
    },
    computed: {
        emailSubModules() {
            return this.notification.module
                ? ['APPLICATION', 'PAYMENT'].includes(this.notification.module.value)
                    ? this.journeySubModules
                    : ['CHANGE_PASSWORD'].includes(this.notification.module.value)
                    ? this.siteSubModules
                    : this.notification.module.subModules
                : [];
        },
        journeySubModules() {
            return this.$store.getters['journey/journeySubModules'];
        },
        siteSubModules() {
            return this.$store.getters['common/siteSubModules'];
        },
    },
    watch: {
        emailSubModules() {
            if (this.emailSubModules && this.notification.sub_module) {
                const current = this.emailSubModules.find((submodule) => {
                    return submodule.value === this.notification.sub_module.value;
                });
                this.notification.sub_module = current ? current : this.emailSubModules[0];
            } else {
                this.notification.sub_module = this.emailSubModules ? this.emailSubModules[0] : [];
            }
        },
        notification() {
            this.$refs.form.resetValidation();
        },
    },
    methods: {
        handleFormSubmit() {
            if (this.$refs.form.validate()) {
                this.handleSave(this.notification);
            }
        },
    },
};
</script>
