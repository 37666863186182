<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Manage Sites</h1>
            <v-dialog v-model="dialog" persistent content-class="small-dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="secondary"
                        dark
                        class="text-capitalize mt-2"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Create New Site
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-space-between align-center secondary-font">
                        <p class="h6 primary--text font-weight-bold mb-0">
                            {{ isEdit ? 'Update Site' : 'Create Site' }}
                        </p>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" text icon color="primary" @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card-text class="secondary-font pb-0">
                            <v-row>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Site name</p>
                                    <v-text-field
                                        v-model="siteData.name"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Site name is required']"
                                        @input="setSiteValue()"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Site value</p>
                                    <v-text-field
                                        v-model="siteData.value"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Site value is required']"
                                        disabled
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary mr-2" @click="save" elevation="0"> Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
            <v-card-text class="secondary-font pa-3">
                <v-data-table
                    :headers="headers"
                    :items="siteSet"
                    class="elevation-0 noborder-table body-primary"
                >
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn
                            icon
                            color="darkGray"
                            @click="openEditDialog(item)"
                            :hidden="item.value === 'apply'"
                        >
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="darkGray"
                            @click="deleteEntityType(item.uuid)"
                            :hidden="item.value === 'apply' || item.value === currentSite"
                        >
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <alert ref="confirm" />
    </v-container>
</template>

<script>
import SiteService from '../../services/site-service';
import notificationsUtility from '../../utilities/notifications-utility';
import common from '../../constant/common-data';
import Alert from '../../components/alert/ConfirmAlert.componet';

export default {
    name: 'Site',
    components: {
        Alert,
    },
    data() {
        return {
            dialogTitle: 'Create Site',
            siteData: {
                name: null,
                value: null,
            },
            uuid: null,
            isEdit: false,
            valid: false,
            dialog: false,
            siteSet: [],
            headers: [
                { text: 'Site Name', value: 'name', sortable: false },
                { text: 'Site Value', value: 'value', sortable: false },
                { text: 'Action', value: 'action', align: 'right', sortable: false },
            ],
            status: common.status,
            currentSite: this.$store.state.common.site,
            oldSiteValue: null,
        };
    },
    methods: {
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
                this.siteData = Object.assign({}, this.$options.data().siteData);
            });
            this.isEdit = false;
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.isEdit) {
                    SiteService.updateSite(this.siteData, this.uuid)
                        .then((response) => {
                            if (response.data.data) {
                                this.siteSet = response.data.data;
                                this.$store.dispatch('common/setSites', response.data.data);
                                notificationsUtility.pushNotifications([
                                    {
                                        message: 'Site updated successfully',
                                        type: 'success',
                                    },
                                ]);

                                if (this.oldSiteValue === this.$store.state.common.site) {
                                    window.location.href = `/${this.siteData.value}/settings/sites`;
                                }

                                this.isEdit = false;
                                this.close();
                            }
                        })
                        .catch((error) => {
                            notificationsUtility.showNotifications(error.response.data);
                        });
                } else {
                    SiteService.saveSite(this.siteData)
                        .then((response) => {
                            this.siteSet = response.data.data;
                            this.$store.dispatch('common/setSites', response.data.data);

                            notificationsUtility.pushNotifications([
                                { message: 'Site created successfully', type: 'success' },
                            ]);
                            this.close();
                        })
                        .catch((error) => {
                            this.universities = [];
                            notificationsUtility.showNotifications(error.response.data);
                        });
                }
            }
        },
        deleteEntityType(uuid) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this site ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        SiteService.deleteSite(uuid)
                            .then((response) => {
                                if (response.data.status === 1) {
                                    const entityIndex = this.siteSet.findIndex(
                                        (x) => x.uuid === uuid
                                    );

                                    if (entityIndex !== -1) this.siteSet.splice(entityIndex, 1);

                                    this.$store.dispatch('common/setSites', this.siteSet);

                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Site deleted successfully',
                                            type: 'success',
                                        },
                                    ]);
                                }
                            })
                            .catch((error) => {
                                if (error.response.data.status === 0) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: error.response.data.message,
                                            type: 'error',
                                        },
                                    ]);
                                } else {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    }
                });
        },
        openEditDialog(item) {
            this.isEdit = true;
            this.siteData.name = item.name;
            this.siteData.value = item.value;
            this.oldSiteValue = item.value;
            this.uuid = item.uuid;
            this.dialog = true;
        },
        getSites() {
            SiteService.getSites()
                .then((response) => {
                    this.siteSet = response.data.data;
                })
                .catch(() => {
                    notificationsUtility.pushNotifications([
                        {
                            message: 'Something went wrong. Please try contact server admin',
                            type: 'error',
                        },
                    ]);
                });
        },
        setSiteValue() {
            let siteName = this.siteData.name.trim();
            this.siteData.value = siteName.replace(/ /g, '_').toLowerCase();
        },
    },
    mounted() {
        this.getSites();
    },
};
</script>
