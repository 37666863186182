<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Manage Customize Notifications</h1>
            <v-btn
                @click="openCreateEmailDialog"
                color="secondary"
                dark
                class="text-capitalize mt-2"
                elevation="0"
                v-if="tab === 1"
            >
                Create New Email Format
            </v-btn>
            <v-btn
                @click="openCreateSystemMessageDialog"
                color="secondary"
                dark
                class="text-capitalize mt-2"
                elevation="0"
                v-if="tab === 0"
            >
                Create New System Message
            </v-btn>
        </div>
        <v-card class="mx-auto mt-5 rounded-10" flat>
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab>
                        <h3 class="text-uppercase">System Messages</h3>
                    </v-tab>
                    <v-tab>
                        <h3 class="text-uppercase">Email Formats</h3>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
                            <v-card-text class="secondary-font pa-3">
                                <v-data-table
                                    :headers="headers"
                                    :items="systemMessages"
                                    class="elevation-0 noborder-table body-primary"
                                    hide-default-footer
                                >
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn
                                            icon
                                            color="darkGray"
                                            @click="openEditSystemMessageDialog(item)"
                                        >
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="darkGray"
                                            @click="deleteSystemMessage(item)"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
                            <v-card-text class="secondary-font pa-3">
                                <v-data-table
                                    :headers="headers"
                                    :items="emailFormats"
                                    class="elevation-0 noborder-table body-primary"
                                    hide-default-footer
                                >
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn
                                            icon
                                            color="darkGray"
                                            @click="openEditEmailFormatDialog(item)"
                                        >
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                        <v-btn
                                            icon
                                            color="darkGray"
                                            @click="deleteEmailFormat(item)"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
        <CreateEmailNotificationPopUp
            :dialog="createEmailDialog"
            :handleClose="closeCreateEmailDialog"
        />
        <EditEmailNotificationPopUp
            :emailTemplate="selectedEmailTemplate"
            :handleClose="closeEditEmailDialog"
        />
        <CreateSystemMessagePopUp
            :dialog="createSystemMessageDialog"
            :handleClose="closeCreateSystemMessageDialog"
        />
        <EditSystemMessagePopUp
            :systemMessage="selectedSystemMessage"
            :handleClose="closeEditSystemMessageDialog"
        />
        <alert ref="confirm" />
    </v-container>
</template>

<script>
import CreateEmailNotificationPopUp from '../../components/notifications/CreateEmailNotificationPopUp.component.vue';
import EditEmailNotificationPopUp from '../../components/notifications/EditEmailNotificationPopUp.component.vue';
import CreateSystemMessagePopUp from '../../components/notifications/CreateSystemMessagePopUp.component.vue';
import EditSystemMessagePopUp from '../../components/notifications/EditSystemMessagePopUp.component.vue';
import Alert from '../../components/alert/ConfirmAlert.componet.vue';
export default {
    name: 'ManageNotifications',
    components: {
        CreateEmailNotificationPopUp,
        EditEmailNotificationPopUp,
        CreateSystemMessagePopUp,
        EditSystemMessagePopUp,
        Alert,
    },
    data() {
        return {
            headers: [
                { text: 'Name', value: 'name', sortable: false },
                { text: '', value: 'action', sortable: false, align: 'right' },
            ],
            tab: 0,
            createEmailDialog: false,
            createSystemMessageDialog: false,
            selectedEmailTemplate: {},
            selectedSystemMessage: {},
        };
    },
    methods: {
        closeCreateEmailDialog() {
            this.createEmailDialog = false;
        },
        closeEditEmailDialog() {
            this.selectedEmailTemplate = null;
        },
        openCreateEmailDialog() {
            this.createEmailDialog = true;
        },
        openEditEmailFormatDialog(emailFormat) {
            this.selectedEmailTemplate = emailFormat;
        },
        deleteEmailFormat(emailFormat) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this email format?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        this.$store.dispatch('notification/deleteEmailFormat', emailFormat);
                    }
                });
        },
        closeCreateSystemMessageDialog() {
            this.createSystemMessageDialog = false;
        },
        closeEditSystemMessageDialog() {
            this.selectedSystemMessage = null;
        },
        openCreateSystemMessageDialog() {
            this.createSystemMessageDialog = true;
        },
        openEditSystemMessageDialog(systemMessage) {
            this.selectedSystemMessage = systemMessage;
        },
        deleteSystemMessage(systemMessage) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this system message?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        this.$store.dispatch('notification/deleteSystemMessage', systemMessage);
                    }
                });
        },
    },
    mounted() {
        this.$store.dispatch('notification/loadEmailFormats');
        this.$store.dispatch('notification/loadSystemMessages');
        this.$store.dispatch('journey/loadJourneys');
    },
    computed: {
        emailFormats() {
            return this.$store.state.notification.emailFormatsList;
        },
        systemMessages() {
            return this.$store.state.notification.systemMessagesList;
        },
    },
};
</script>
