export default {
    notifications: [],
    pendingRequestsCount: 0,
    curentApplication: null,
    systemSettings: [],
    isSystemSettingsLoaded: false,
    maintenanceMode: false,
    site: 'apply',
    siteUuid: null,
    sites: [],
    settingsSite: 'apply',
    sitesArray: [],
    sessionTimeOutMessage: null,
};
