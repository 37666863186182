export default [
    { status_name: 'New Application', color: 'deep-purple' },
    { status_name: 'Pre Submit', color: 'indigo' },
    { status_name: 'Confirmed', color: 'green' },
    { status_name: 'Cancelled', color: 'red' },
    { status_name: 'Accepted', color: 'orange' },
    { status_name: 'Pre Register', color: 'green' },
    { status_name: 'Under Revision', color: 'deep-purple' },
    { status_name: 'Partial Decision', color: 'deep-purple' },
    { status_name: 'Admitted', color: 'orange' },
    { status_name: 'Current Student', color: 'green' },
    { status_name: 'Paid Cancel', color: 'red' },
    { status_name: 'Rejected', color: 'red' },
    { status_name: 'Declined', color: 'red' },
    { status_name: 'Deferred', color: 'orange' },
    { status_name: 'No Show', color: 'red' },
];
