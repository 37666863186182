import NotificationService from '../../../services/notification-service';
import notificationsUtility from '../../../utilities/notifications-utility';

export default {
    loadEmailFormats({ commit }) {
        NotificationService.getEmailFormats().then((response) => {
            commit('setEmailFormats', response.data.data);
        });
    },
    deleteEmailFormat({ commit }, emailFormat) {
        NotificationService.deleteEmailFormat(emailFormat)
            .then((response) => {
                commit('deleteEmailFormat', emailFormat);
                notificationsUtility.showNotifications(response.data.meta);
            })
            .catch((error) => {
                notificationsUtility.showNotifications(error.response.data);
            });
    },
    loadSystemMessages({ commit }) {
        NotificationService.getSystemMessages().then((response) => {
            commit('setSystemMessages', response.data.data);
        });
    },
    deleteSystemMessage({ commit }, systemMessage) {
        NotificationService.deleteSystemMessage(systemMessage)
            .then((response) => {
                commit('deleteSystemMessage', systemMessage);
                notificationsUtility.showNotifications(response.data.meta);
            })
            .catch((error) => {
                notificationsUtility.showNotifications(error.response.data);
            });
    },
};
