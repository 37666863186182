<template>
    <div>
        <v-container>
            <h1 class="primary--text">{{ welcomeHeading }}</h1>
            <p class="primary--text subtitle-1">{{ welcomeSubHeading }}</p>
        </v-container>
        <v-container class="mt-5 p-0">
            <h3 class="primary--text text-center">{{ applicationsSectionHeading }}</h3>
            <p class="primary--text subtitle-2 mb-0 text-center">
                {{ applicationsSectionSubHeading }}
            </p>
            <v-row class="ma-0 pa-0 application-row d-flex justify-center">
                <v-col cols="12">
                    <v-container>
                        <v-card class="mx-auto rounded-lg" elevation="0">
                            <v-card-text class="secondary-font pa-3">
                                <v-data-table
                                    no-data-text="You do not have any applications yet"
                                    :headers="headers"
                                    :items="applications"
                                    class="elevation-0 noborder-table body-primary height100"
                                    hide-default-footer
                                    @page-count="pageCount = $event"
                                    :page.sync="page"
                                    :items-per-page="5"
                                >
                                    <template v-slot:[`item.applicationNumber`]="{ item }">
                                        <p
                                            class="mb-0"
                                            v-bind:class="{
                                                'cursor-pointer link-text': item.enable,
                                                'disabled-text': !item.enable,
                                            }"
                                            @click="editApplication(item, item.enable)"
                                        >
                                            {{ item.applicationNumber }}
                                        </p>
                                    </template>
                                    <template v-slot:[`item.applicationType`]="{ item }">
                                        <p
                                            class="mb-0"
                                            v-bind:class="{
                                                'cursor-pointer link-text': item.enable,
                                                'disabled-text': !item.enable,
                                            }"
                                            @click="editApplication(item, item.enable)"
                                        >
                                            {{ item.applicationType }}
                                        </p>
                                    </template>
                                    <template v-slot:[`item.period`]="{ item }">
                                        <p
                                            class="mb-0"
                                            v-bind:class="{ 'disabled-text': !item.enable }"
                                        >
                                            {{ item.period }}
                                        </p>
                                    </template>
                                    <template v-slot:[`item.currentOptionProgramName`]="{ item }">
                                        <p
                                            class="mb-0"
                                            v-bind:class="{ 'disabled-text': !item.enable }"
                                        >
                                            {{ item.currentOptionProgramName }}
                                        </p>
                                    </template>
                                    <template v-slot:[`item.applicationStatus`]="{ item }">
                                        <v-chip
                                            small
                                            :color="
                                                item.enable
                                                    ? statusColor(item.applicationStatus)
                                                    : ''
                                            "
                                            class="text-capitalize lighten-1"
                                            dark
                                        >
                                            {{ item.applicationStatus }}
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                        <div class="d-flex justify-end mt-2" v-if="pageCount">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                                class="secondary-font"
                                :total-visible="5"
                            ></v-pagination>
                        </div>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <h3 class="primary--text text-center mt-0">{{ programsSectionHeading }}</h3>
            <p class="primary--text subtitle-2 text-center">{{ programsSectionSubHeading }}</p>
            <v-row class="mt-5 d-flex justify-center">
                <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="(program, index) in onlyActiveJourneys"
                    :key="index"
                >
                    <Program :applications="applications" :program="program" />
                </v-col>
            </v-row>
            <div class="d-flex justify-center mt-4" v-if="journeyCount > 9">
                <v-btn text color="primary" @click="showLess = !showLess">
                    {{ showLess ? 'More' : 'Less' }} Programs
                    <v-icon right>
                        {{ showLess ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                    </v-icon>
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
import statuses from '../../config/statuses';
import Program from './Program';
import programService from '../../services/program-service';
import JourneyService from '../../services/journey-service';
import notificationsUtility from '../../utilities/notifications-utility';

export default {
    name: 'Application',

    components: {
        Program,
    },

    data() {
        return {
            headers: [
                { text: 'Number', value: 'applicationNumber', sortable: false },
                { text: 'Type', value: 'applicationType', sortable: false },
                { text: 'Academic Year', value: 'period', sortable: false },
                { text: 'Program', value: 'currentOptionProgramName', sortable: false },
                { text: 'Status', value: 'applicationStatus', sortable: false },
            ],
            journeys: [],
            applications: [],
            loggedAs: false,
            statusTypes: statuses,
            showLess: true,
            journeyCount: 0,
            page: 1,
            pageCount: 0,
        };
    },

    computed: {
        isAdmin() {
            let currentUser =
                localStorage.getItem('log_as_user_status') &&
                localStorage.getItem('log_as_user_status') === 'true'
                    ? JSON.parse(localStorage.getItem('log_as_user'))
                    : this.$store.state.auth.user;

            if (currentUser && currentUser.role) {
                return currentUser.role[0].name === 'ADMIN' ? true : false;
            }

            return false;
        },

        onlyActiveJourneys() {
            let activeJourneys = [];
            if (this.journeys && this.journeys.length > 0) {
                if (!this.isAdmin) {
                    activeJourneys = this.journeys.filter((journey) => journey.status === 'ACTIVE');
                } else {
                    activeJourneys = this.journeys;
                }
            }

            this.journeyCount = activeJourneys.length;

            return this.showLess ? activeJourneys.slice(0, 9) : activeJourneys;
        },
        applicationHeading() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? applicationHeading && applicationHeading.value !== ''
                    ? applicationHeading.value
                    : 'Application System'
                : undefined;
        },
        site() {
            return this.$store.state.common.site;
        },

        welcomeHeading() {
            const data = this.$store.getters['common/welcomeHeading'];
            return data ? data.value : null;
        },
        welcomeSubHeading() {
            const data = this.$store.getters['common/welcomeSubHeading'];
            return data ? data.value : null;
        },
        applicationsSectionHeading() {
            const data = this.$store.getters['common/applicationsSectionHeading'];
            return data ? data.value : null;
        },
        applicationsSectionSubHeading() {
            const data = this.$store.getters['common/applicationsSectionSubHeading'];
            return data ? data.value : null;
        },
        programsSectionHeading() {
            const data = this.$store.getters['common/programsSectionHeading'];
            return data ? data.value : null;
        },
        programsSectionSubHeading() {
            const data = this.$store.getters['common/programsSectionSubHeading'];
            return data ? data.value : null;
        },
    },

    methods: {
        statusColor(status) {
            if (status) {
                let filteredStatus = this.statusTypes.filter((value) => {
                    return value.status_name === status;
                });

                if (filteredStatus.length > 0) {
                    return filteredStatus[0].color;
                }
            }

            return '';
        },

        async getUserApplication() {
            // This line to be removed.
            // this.$store.dispatch('auth/changeUser', '60b5d5cc8e2832f81');

            let logAsUserStatus = localStorage.getItem('log_as_user_status');
            let logAsUser = JSON.parse(localStorage.getItem('log_as_user'));

            let payload = {
                contact_id:
                    logAsUserStatus && logAsUserStatus === 'true'
                        ? logAsUser.contact_id
                        : this.$store.state.auth.user.contact_id,
            };

            await programService
                .getUserApplications(payload)
                .then((response) => {
                    this.applications = response.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        getJourneys() {
            JourneyService.getJourneys()
                .then((response) => {
                    this.journeys = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        editApplication(item, enable) {
            if (enable && item.journey) {
                let applicationIdWithJourneyId = item.id + item.journey;

                this.$router.push(`/${this.site}/application/edit/${applicationIdWithJourneyId}`);
            }
        },
    },
    mounted() {
        this.getJourneys();
        this.getUserApplication();
    },
};
</script>
