import { HTTP } from './http-common';

export default {
    getDocumentTypes() {
        return HTTP.get('document-types');
    },
    saveDocumentType(data) {
        return HTTP.post('document-types', data);
    },
    updateDocumentType(data, uuid) {
        return HTTP.put(`document-types/${uuid}`, data);
    },
    deleteDocumentType(uuid) {
        return HTTP.delete(`document-types/${uuid}`);
    },
};
