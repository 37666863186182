<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Manage Journey Types</h1>
            <v-dialog v-model="dialog" persistent content-class="small-dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="secondary"
                        dark
                        class="text-capitalize mt-2"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Create New Journey Type
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-space-between align-center secondary-font">
                        <p class="h6 primary--text font-weight-bold mb-0">{{ dialogTitle }}</p>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" text icon color="primary" @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card-text class="secondary-font pb-0">
                            <v-row>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Journey type name</p>
                                    <v-text-field
                                        v-model="journeyTypeData.journey_type"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Period is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Entity name</p>
                                    <v-text-field
                                        v-model="journeyTypeData.entity_name"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Entity name is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Program name</p>
                                    <v-text-field
                                        v-model="journeyTypeData.program_name"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Program name is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Filter field</p>
                                    <v-text-field
                                        v-model="journeyTypeData.filter_field"
                                        dense
                                        outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Filter value</p>
                                    <v-text-field
                                        v-model="journeyTypeData.filter_value"
                                        dense
                                        outlined
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">CRM unique field</p>
                                    <v-text-field
                                        v-model="journeyTypeData.crm_unique_field"
                                        dense
                                        outlined
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary mr-2" @click="save" elevation="0"> Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
            <v-card-text class="secondary-font pa-3">
                <v-data-table
                    :headers="headers"
                    :items="JourneyTypesSet"
                    class="elevation-0 noborder-table body-primary"
                >
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="darkGray" @click="openEditJourneyDialog(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="darkGray" @click="deleteJourneyType(item.uuid)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <alert ref="confirm" />
    </v-container>
</template>

<script>
import journeyTypeService from '../../services/journey-type-service';
import notificationsUtility from '../../utilities/notifications-utility';
import common from '../../constant/common-data';
import Alert from '../../components/alert/ConfirmAlert.componet';

export default {
    name: 'JourneyTypes',
    components: {
        Alert,
    },
    data() {
        return {
            dialogTitle: 'Create Journey Type',
            journeyTypeData: {
                journey_type: null,
                entity_name: null,
                program_name: null,
                filter_field: null,
                filter_value: null,
                crm_unique_field: null,
                status: 'ACTIVE',
            },
            uuid: null,
            isEdit: false,
            valid: false,
            dialog: false,
            JourneyTypesSet: [],
            headers: [
                { text: 'Type Name', value: 'journey_type', sortable: false },
                { text: 'Entity Name', value: 'entity_name', sortable: false },
                { text: 'Program Name', value: 'program_name', sortable: false },
                { text: 'Filter Field', value: 'filter_field', sortable: false },
                { text: 'Filter Value', value: 'filter_value', sortable: false },
                { text: 'CRM unique field', value: 'crm_unique_field', sortable: false },
                { text: 'Action', value: 'action', align: 'right', sortable: false },
            ],
            status: common.status,
        };
    },
    methods: {
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
                this.journeyTypeData = Object.assign({}, this.$options.data().journeyTypeData);
            });
        },
        generateCrmPeriod() {
            let name = this.period.period;
            this.period.crm_period_id = name.replace(/[^A-Z0-9]/gi, '_').toLowerCase();
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.isEdit) {
                    journeyTypeService
                        .updateJourneyType(this.journeyTypeData, this.uuid)
                        .then((response) => {
                            if (response.data.data) {
                                this.JourneyTypesSet = response.data.data;
                                this.isEdit = false;
                                this.close();
                                notificationsUtility.pushNotifications([
                                    {
                                        message: 'Journey Type updated Successfully',
                                        type: 'success',
                                    },
                                ]);
                            }
                        })
                        .catch((error) => {
                            notificationsUtility.showNotifications(error.response.data);
                        });
                } else {
                    journeyTypeService
                        .saveJourneyType(this.journeyTypeData)
                        .then((response) => {
                            this.JourneyTypesSet = response.data.data;
                            notificationsUtility.pushNotifications([
                                { message: 'Journey Type created Successfully', type: 'success' },
                            ]);
                            this.close();
                        })
                        .catch((error) => {
                            notificationsUtility.showNotifications(error.response.data);
                        });
                }
            }
        },
        deleteJourneyType(uuid) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this journey type ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        journeyTypeService
                            .deleteJourneyType(uuid)
                            .then((response) => {
                                if (response.data.status === 1) {
                                    const journeyTypeIndex = this.JourneyTypesSet.findIndex(
                                        (x) => x.uuid === uuid
                                    );
                                    if (journeyTypeIndex !== -1)
                                        this.JourneyTypesSet.splice(journeyTypeIndex, 1);
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Journey Type Deleted Successfully ',
                                            type: 'success',
                                        },
                                    ]);
                                }
                            })
                            .catch((error) => {
                                if (error.response.data.status === 0) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: error.response.data.message,
                                            type: 'error',
                                        },
                                    ]);
                                } else if (error.response.status !== 503) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    }
                });
        },
        openEditJourneyDialog(item) {
            this.isEdit = true;
            this.journeyTypeData.journey_type = item.journey_type;
            this.journeyTypeData.entity_name = item.entity_name;
            this.journeyTypeData.program_name = item.program_name;
            this.journeyTypeData.filter_field = item.filter_field;
            this.journeyTypeData.filter_value = item.filter_value;
            this.journeyTypeData.crm_unique_field = item.crm_unique_field;

            this.uuid = item.uuid;
            this.dialog = true;
        },
        getJourneyTypes() {
            journeyTypeService
                .getJourneyTypes()
                .then((response) => {
                    this.JourneyTypesSet = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        getColor(status) {
            if (status == 'INACTIVE') return 'red';
            else return 'green';
        },
    },
    mounted() {
        this.getJourneyTypes();
    },
    computed: {
        sites() {
            return this.$store.state.common.sites;
        },
    },
};
</script>

<style scoped></style>
