import { HTTP } from './http-common';

export default {
    getEmailFormats() {
        return HTTP.get('/notification');
    },
    addEmailFormat(payload) {
        return HTTP.post(`/notification/create`, payload);
    },
    editEmailFormat(payload) {
        return HTTP.put(`/notification/${payload.uuid}`, payload);
    },
    deleteEmailFormat(payload) {
        return HTTP.delete(`/notification/${payload.uuid}`, payload);
    },
    getSystemMessages() {
        return HTTP.get('/systemMessage');
    },
    addSystemMessage(payload) {
        return HTTP.post(`/systemMessage/create`, payload);
    },
    editSystemMessage(payload) {
        return HTTP.put(`/systemMessage/${payload.uuid}`, payload);
    },
    deleteSystemMessage(payload) {
        return HTTP.delete(`/systemMessage/${payload.uuid}`, payload);
    },
};
