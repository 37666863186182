<template>
    <div>
        <v-card class="mx-auto mb-4 rounded-lg" elevation="0">
            <v-card-text class="secondary-font pa-0">
                <div
                    class="d-sm-flex d-xs-block justify-space-between align-center text-center mt-6"
                    v-if="application"
                >
                    <p class="mb-0 d-flex flex-column align-sm-start d-xs-block align-center">
                        <span>Application Number</span>
                        <span class="text-h6 font-weight-bold">{{
                            application.applicationNumber
                        }}</span>
                    </p>

                    <p class="mb-1">
                        <span class="font-weight-bold">Application Status</span>

                        <v-chip
                            class="ml-2 secondary-font lighten-2"
                            :color="getStatusColor(application.status)"
                            dark
                        >
                            {{ application.status }}
                        </v-chip>
                    </p>
                </div>

                <v-card class="mx-auto mt-4 rounded-lg" elevation="0" color="lightGray" outlined>
                    <v-card-title class="section-card-title journey-header-text">
                        Course Status
                    </v-card-title>
                    <v-card-text class="secondary-font pa-0">
                        <v-data-table
                            :headers="headers"
                            :items="component.component_default_value"
                            class="elevation-0 noborder-table rounded-t-0 rounded-b-lg"
                            hide-default-footer
                            sort-by="status"
                        >
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import statuses from '../../../config/statuses';

export default {
    name: 'ApplicationStatus',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: 'Course',
                    value: 'courseName',
                    sortable: false,
                },
                {
                    text: 'ECTS',
                    value: 'eCTS',
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'status',
                    sortable: false,
                },
            ],

            statusTypes: statuses,
        };
    },
    computed: {
        application() {
            return this.$store.state.common.curentApplication;
        },
    },
    methods: {
        getStatusColor(status) {
            let filteredStatus = this.statusTypes.filter((value) => {
                return value.status_name === status;
            });

            if (filteredStatus) {
                return filteredStatus[0].color;
            }

            return '';
        },
    },
};
</script>
