<template>
    <div>
        <v-list-group :value="true" no-action class="custom-list-group">
            <template v-slot:activator>
                <v-list-item-title>
                    <div class="d-flex align-center mt-1">
                        <div class="mr-3">
                            <v-btn x-small text icon color="darkGray">
                                <v-icon x-small>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn x-small text icon color="darkGray">
                                <v-icon x-small>mdi-arrow-down</v-icon>
                            </v-btn>
                        </div>
                        <span>{{ journey.tab }}</span>
                    </div>
                </v-list-item-title>
                <v-list-item-action>
                    <div class="d-flex">
                        <v-btn icon small @click="tabDialog = true">
                            <v-icon color="primary" small> mdi-pencil-outline </v-icon>
                        </v-btn>
                        <v-btn icon small>
                            <v-icon color="primary" small> mdi-delete-outline </v-icon>
                        </v-btn>
                    </div>
                </v-list-item-action>
            </template>
            <TabSection :sections="journey.sections" />
        </v-list-group>

        <v-dialog v-model="tabDialog" content-class="small-dialog">
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center secondary-font">
                    <p class="h6 primary--text font-weight-black mb-0">Create Tab</p>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" text icon color="primary" @click="tabDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="secondary-font pb-0">
                    <v-row>
                        <v-col cols="12">
                            <v-radio-group row hide-details class="mt-0">
                                <v-radio label="New Tab" value="new"></v-radio>
                                <v-radio label="Clone Tab" value="clone"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                            <p class="caption mb-0">Tab Name</p>
                            <v-text-field dense outlined hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Display Name</p>
                            <v-text-field dense outlined hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Entity Type</p>
                            <v-select :items="items" dense outlined hide-details></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-switch label="Admin Only" flat hide-details></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary mr-2" @click="tabDialog = false" elevation="0">
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TabSection from './Section';

export default {
    name: 'TabItem',
    components: {
        TabSection,
    },
    props: {
        journey: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            tabDialog: false,
            items: ['foo', 'bar'],
        };
    },
};
</script>
