<template>
    <div>
        <v-system-bar app color="secondary" class="secondary-font lighten-2">
            <v-spacer></v-spacer>
            <v-icon>mdi-account</v-icon>
            You are logged as <strong class="ml-1"> {{ loggedAsUser.name }}</strong>
            <v-spacer></v-spacer>
            <v-btn icon small @click="exitLoggedAs">
                <v-icon class="mr-0" small>mdi-close</v-icon>
            </v-btn>
        </v-system-bar>
        <confirm-alert ref="confirm" />
    </div>
</template>

<script>
import ConfirmAlert from './ConfirmAlert.componet';

export default {
    name: 'LogAsUserAlert.component',
    components: { ConfirmAlert },
    data() {
        return {
            loggedAsUser: {},
        };
    },
    methods: {
        exitLoggedAs() {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to log out this user ?', {
                    color: 'error',
                })
                .then((confirm) => {
                    if (confirm) {
                        localStorage.removeItem('log_as_user');
                        localStorage.setItem('log_as_user_status', 'false');
                        location.reload();
                    }
                });
        },
    },
    created() {
        this.loggedAsUser =
            localStorage.getItem('log_as_user_status') &&
            localStorage.getItem('log_as_user_status') === 'true'
                ? JSON.parse(localStorage.getItem('log_as_user'))
                : {};
    },
};
</script>

<style scoped></style>
