export default {
    filteredUsers: (state) => (filters) => {
        const filterRoles = filters.role.map((role) => role.value);
        const filterMemberships = filters.membership.map((membership) => membership.value);
        return state.usersList
            .filter((user) => {
                return (
                    user.role
                        .map((role) => role.name)
                        .filter((value) => filterRoles.includes(value)).length > 0
                );
            })
            .filter((user) => {
                return filterMemberships.includes(user.status);
            })
            .filter(
                (user) =>
                    user.name.toLowerCase().includes(filters.keyword.toLowerCase()) ||
                    user.email.toLowerCase().includes(filters.keyword.toLowerCase())
            );
    },
};
