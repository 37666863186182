<template>
    <v-dialog v-model="openEditSectionDialog" persistent content-class="small-dialog">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 primary--text font-weight-black mb-0">{{ dialogTitle }}</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="secondary-font pb-0">
                    <v-row>
                        <v-col cols="12" v-if="!isEdit">
                            <v-radio-group v-model="createMode">
                                <v-row>
                                    <v-col cols="12" sm="6" xs="12">
                                        <v-radio
                                            label="New Section"
                                            name="createMode"
                                            value="NEW"
                                        ></v-radio>
                                    </v-col>
                                    <v-col cols="12" sm="6" xs="12">
                                        <v-radio
                                            label="Clone Section"
                                            name="createMode"
                                            value="CLONE"
                                        ></v-radio>
                                    </v-col>
                                </v-row>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" v-if="createMode === 'CLONE'">
                            <div class="inner-container">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="6">
                                            <p class="caption mb-0">Journey</p>
                                            <v-select
                                                :items="journeysList"
                                                v-model="selectedJourney"
                                                item-text="journey_name"
                                                item-value="uuid"
                                                dense
                                                outlined
                                                :rules="[(v) => !!v || 'Journey is required']"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6">
                                            <p class="caption mb-0">Tab</p>
                                            <v-select
                                                :items="tabsList"
                                                v-model="selectedCloningTab"
                                                item-text="tab_display_name"
                                                item-value="tab_name"
                                                dense
                                                outlined
                                                :rules="[(v) => !!v || 'Tab is required']"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6">
                                            <p class="caption mb-0">Section</p>
                                            <v-select
                                                :items="sectionsList"
                                                v-model="selectedCloningSection"
                                                item-text="section_display_name"
                                                item-value="section_name"
                                                dense
                                                outlined
                                                :rules="[(v) => !!v || 'Section is required']"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" xs="12">
                            <p class="caption mb-0">Section Display Name</p>
                            <v-text-field
                                v-model="section.section_display_name"
                                dense
                                outlined
                                @input="generateSectionName()"
                                :rules="[(v) => !!v || 'Section Display name is required']"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Entity</p>
                            <v-select
                                :items="entityTypes"
                                v-model="section.section_entity"
                                item-text="name"
                                item-value="entity_type"
                                dense
                                outlined
                                :rules="[(v) => !!v || 'Entity Type is required']"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-switch
                            label="Admin Only"
                            v-model="isAdmin"
                            class="pl-3 pr-3"
                            flat
                            hide-details
                        ></v-switch>
                        <v-switch
                            v-model="section.advance_validation_rules.is_required"
                            label="Required"
                            hide-details
                            class="pl-3"
                            dense
                        ></v-switch>
                        <v-switch
                            label="Show Title"
                            v-model="section.is_show_title"
                            flat
                            hide-details
                        ></v-switch>
                        <v-col cols="12" xs="12" sm="4" md="4">
                            <v-checkbox
                                v-model="section.is_advance_condition"
                                label="Advanced Condition"
                                @change="changeAdvanceCondition"
                                outlined
                                hide-details
                                dense
                                class="mt-2"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if="section.is_advance_condition">
                        <v-col cols="12">
                            <v-tabs v-model="tab">
                                <v-tab :key="1"> Enability</v-tab>
                                <v-tab :key="2"> Visibility</v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                                <v-tab-item :key="1">
                                    <v-row
                                        class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center mt-3 pl-3"
                                        v-for="(option, index) in section.advance_validation_rules
                                            .enability_rules"
                                        :key="index"
                                    >
                                        <v-col cols="12" xs="12" sm="3" md="3" class="ma-0 pa-0">
                                            <v-text-field
                                                outlined
                                                label="CRM Field"
                                                dense
                                                :rules="getValidationRules('CRM field')"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .enability_rules[index].parent_field
                                                "
                                                class="ml-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" md="3" class="ma-0 pa-0">
                                            <v-text-field
                                                dense
                                                flat
                                                outlined
                                                label="Value"
                                                :rules="getValidationRules('Value')"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .enability_rules[index].value
                                                "
                                                class="ml-2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="5" sm="3" md="3" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="enableActions"
                                                label="Action"
                                                @change="changeAction('enability_rules', index)"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .enability_rules[index].action
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('Action')"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="5" sm="2" md="2" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="operators"
                                                label="Operator"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .enability_rules[index].operator
                                                "
                                                :rules="getValidationRules('Operator')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="2" sm="1" md="1" class="ma-0 pa-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                class="mb-md-5"
                                                @click="resetValidation('enability', index)"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        small
                                        class="mt-3 mb-3"
                                        color="secondary"
                                        elevation="0"
                                        @click="addCondition('enability')"
                                    >
                                        Add Conditions
                                    </v-btn>
                                </v-tab-item>
                                <v-tab-item :key="2">
                                    <v-row
                                        class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center mt-3 pl-3"
                                        v-for="(option, index) in section.advance_validation_rules
                                            .visibility_rules"
                                        :key="index"
                                    >
                                        <v-col cols="12" xs="12" sm="3" class="ma-0 pa-0">
                                            <v-text-field
                                                outlined
                                                label="CRM Field"
                                                dense
                                                v-model="
                                                    section.advance_validation_rules
                                                        .visibility_rules[index].parent_field
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('CRM field')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="3" class="ma-0 pa-0">
                                            <v-text-field
                                                dense
                                                flat
                                                outlined
                                                label="Value"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .visibility_rules[index].value
                                                "
                                                class="ml-2"
                                                :rules="getValidationRules('Value')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" xs="5" sm="3" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="visibleActions"
                                                label="Action"
                                                @change="changeAction('visibility_rules', index)"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .visibility_rules[index].action
                                                "
                                                :rules="getValidationRules('Action')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="2" class="ma-0 pa-0">
                                            <v-select
                                                dense
                                                flat
                                                outlined
                                                :items="operators"
                                                label="Operator"
                                                v-model="
                                                    section.advance_validation_rules
                                                        .visibility_rules[index].operator
                                                "
                                                :rules="getValidationRules('Operator')"
                                                class="ml-2"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="1" class="ma-0 pa-0">
                                            <v-btn
                                                text
                                                icon
                                                color="darkGray"
                                                class="mb-md-5"
                                                @click="resetValidation('visibility', index)"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        small
                                        class="mt-3 mb-3"
                                        color="secondary"
                                        elevation="0"
                                        @click="addCondition('visibility')"
                                    >
                                        Add Conditions
                                    </v-btn>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary mr-2" @click="save" elevation="0">Save</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '../../store/index';
import common from '../../constant/common-data';
import SectionModel from '../../models/Component';
import journeyService from '../../services/journey-service';
export default {
    name: 'EditSectionComponent',
    store,
    common,
    props: {
        tabKey: Number,
        sectionKey: Number,
        addSectionAfter: Number,
    },
    data() {
        return {
            section: {
                section_name: '',
                section_display_name: '',
                is_enable: true,
                is_hidden: false,
                is_show_title: true,
                section_entity: null,
                components: [],
                status: 'ACTIVE',
                is_advance_condition: false,
                advance_validation_rules: {
                    is_required: false,
                    field_type: null,
                    enability_rules: [],
                    visibility_rules: [],
                },
                conditions: [
                    {
                        type: '',
                        attribute: '',
                        value: '',
                    },
                ],
            },

            tab: null,
            types: [],
            fieldLogic: [],
            valid: false,
            isAdmin: false,
            isEdit: false,
            dialogTitle: 'Create Section',
            createMode: 'NEW',
            journeysList: [],
            selectedJourney: null,
            selectedCloningTab: null,
            selectedCloningSection: null,
            showAdvancedConditions: false,
            visibleActions: common.visibleActions,
            enableActions: common.enableActions,
            operators: common.operators,
        };
    },
    methods: {
        changeAction(model, index) {
            if (this.section.advance_validation_rules[model].length > 1) {
                this.section.advance_validation_rules[model].map((item, i) => {
                    if (i !== index) {
                        item.action = this.section.advance_validation_rules[model][index].action;
                    }
                });
            }
        },
        changeAdvanceCondition() {
            if (this.section.is_advance_condition === false) {
                this.section.advance_validation_rules.enability_rules = Object.assign(
                    {},
                    this.$options.data().section.advance_validation_rules.enability_rules
                );
                this.section.advance_validation_rules.visibility_rules = Object.assign(
                    {},
                    this.$options.data().section.advance_validation_rules.visibility_rules
                );
            }
        },
        addCondition(value) {
            switch (value) {
                case 'enability':
                    this.section.advance_validation_rules.enability_rules.push({
                        parent_field: '',
                        value: null,
                        action: '',
                        operator: '',
                    });
                    break;

                case 'visibility':
                    this.section.advance_validation_rules.visibility_rules.push({
                        parent_field: '',
                        value: null,
                        action: '',
                        operator: '',
                    });
                    break;
            }
        },
        addOption() {
            let currentHighestValue = 0;

            if (this.section.conditions.length > 0) {
                currentHighestValue = Math.max.apply(
                    Math,
                    this.section.conditions.map((option) => {
                        return option.id;
                    })
                );
            }
            let option = {
                type: '',
                attribute: '',
                value: '',
            };

            this.section.conditions.push(option);
            this.changeOptionValues();
        },

        removeOption(index) {
            this.section.conditions.splice(index, 1);
            this.changeOptionValues();
        },
        changeOptionValues() {
            this.$emit('update-values', this.section);
        },
        resetValidation(value, index) {
            switch (value) {
                case 'visibility':
                    this.section.advance_validation_rules.visibility_rules.splice(index, 1);
                    break;
                case 'enability':
                    this.section.advance_validation_rules.enability_rules.splice(index, 1);
                    break;
                default:
                    break;
            }
        },
        getValidationRules(model, field, name) {
            switch (field) {
                case 'parent_field':
                    if (
                        (this.section.advance_validation_rules[model].value !== null &&
                            this.section.advance_validation_rules[model].value !== '') ||
                        (this.section.advance_validation_rules[model].action !== null &&
                            this.section.advance_validation_rules[model].action !== '')
                    ) {
                        return [(v) => !!v || `${name} is required`];
                    }
                    break;
                case 'value':
                    if (
                        (this.section.advance_validation_rules[model].parent_field !== null &&
                            this.section.advance_validation_rules[model].parent_field !== '') ||
                        (this.section.advance_validation_rules[model].action !== null &&
                            this.section.advance_validation_rules[model].action !== '')
                    ) {
                        return [(v) => !!v || `${name} is required`];
                    }
                    break;

                case 'action':
                    if (
                        (this.section.advance_validation_rules[model].value !== null &&
                            this.section.advance_validation_rules[model].value !== '') ||
                        (this.section.advance_validation_rules[model].action !== null &&
                            this.section.advance_validation_rules[model].action !== '')
                    ) {
                        return [(v) => !!v || `${name} is required`];
                    }
                    break;
                default:
                    break;
            }
        },
        changeType() {
            if (this.section.component_type === 'TEXT_FIELD') {
                this.types = common.textFieldTypes;
            } else if (this.section.component_type === 'DATE') {
                this.types = common.dateFieldTypes;
            } else {
                this.types = [];
            }
            this.fieldLogic = [];
            this.section.crm_required = common.inputTypes.find(
                (item) => item.value === this.section.component_type
            ).crm_required;
            this.section.component_default_value = null;
            this.section.component_data_set = [];
        },
        generateSectionName() {
            let name = this.section.section_display_name;
            this.section.section_name = name.replace(/[^A-Z0-9]/gi, '_').toLowerCase();
        },
        setInitials() {
            if (this.sectionKey != null) {
                if (
                    this.journeyState[this.tabKey].sections[this.sectionKey]
                        .advance_validation_rules
                ) {
                    this.section.advance_validation_rules.enability_rules =
                        this.journeyState[this.tabKey].sections[
                            this.sectionKey
                        ].advance_validation_rules.enability_rules;

                    this.section.advance_validation_rules.visibility_rules =
                        this.journeyState[this.tabKey].sections[
                            this.sectionKey
                        ].advance_validation_rules.visibility_rules;

                    this.section.advance_validation_rules.is_required =
                        this.journeyState[this.tabKey].sections[
                            this.sectionKey
                        ].advance_validation_rules.is_required;
                }
                this.section.is_advance_condition =
                    this.journeyState[this.tabKey].sections[this.sectionKey].is_advance_condition;
                this.isEdit = true;
                this.dialogTitle = 'Update Section';
                this.section.section_name =
                    this.journeyState[this.tabKey].sections[this.sectionKey].section_name;
                this.section.section_display_name =
                    this.journeyState[this.tabKey].sections[this.sectionKey].section_display_name;
                this.section.section_entity =
                    this.journeyState[this.tabKey].sections[this.sectionKey].section_entity;
                this.section.is_enable =
                    this.journeyState[this.tabKey].sections[this.sectionKey].is_enable;
                this.section.is_hidden =
                    this.journeyState[this.tabKey].sections[this.sectionKey].is_hidden;
                this.section.components =
                    this.journeyState[this.tabKey].sections[this.sectionKey].components;
                this.section.status =
                    this.journeyState[this.tabKey].sections[this.sectionKey].status;

                this.section.is_show_title =
                    this.journeyState[this.tabKey].sections[this.sectionKey].is_show_title;
                this.isAdmin =
                    this.journeyState[this.tabKey].sections[this.sectionKey].status === 'ACTIVE'
                        ? false
                        : true;
                this.createMode = 'NEW';
                this.journeysList = [];
                this.selectedJourney = null;
                this.selectedCloningTab = null;
                this.selectedCloningSection = null;
            } else {
                this.section.section_entity = this.defaultEntity ? this.defaultEntity : null;
            }

            this.section.conditions =
                this.courseCondition && this.courseCondition.length ? this.courseCondition : [];
            this.changeOptionValues();
        },
        close() {
            this.$emit('close-section', 'close');
            store.commit('journey/closeEditSectionDialog');
            this.$nextTick(() => {
                Object.assign(this.$data, this.$options.data());
            });
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.isEdit) {
                    const params = {
                        tabKey: this.tabKey,
                        sectionKey: this.sectionKey,
                        sectionData: this.section,
                    };
                    store.commit('journey/updateSection', { ...params });
                    this.$emit('close-section');
                } else {
                    if (this.createMode === 'NEW') {
                        this.section.components = [new SectionModel().components[0]];
                        this.section.components[0].component_entity = this.defaultEntity;
                    }
                    const params = {
                        tabKey: this.tabKey,
                        sectionKey: this.addSectionAfter,
                        sectionData: this.section,
                    };
                    store.commit('journey/addNewSection', { ...params });
                    this.$emit('close-section', 'success');
                }
                Object.assign(this.$data, this.$options.data());
                store.commit('journey/closeEditSectionDialog');
            }
        },
        loadJourneys() {
            journeyService.getJourneys().then((response) => {
                this.journeysList = response.data.data;
            });
        },
    },
    watch: {
        isAdmin() {
            this.section.status = this.isAdmin === true ? 'INACTIVE' : 'ACTIVE';
        },
        createMode() {
            if (this.createMode === 'NEW' && !this.isEdit) {
                this.selectedJourney = null;
                this.section.components = [new SectionModel().components[0]];
            }
        },
        selectedCloningSection() {
            if (this.selectedCloningSection) {
                const cloningSection = this.sectionsList.find(
                    (section) => section.section_name === this.selectedCloningSection
                );
                if (cloningSection) {
                    this.section.components = [...cloningSection.components];
                } else {
                    this.section.components = [new SectionModel().components[0]];
                }
            } else {
                this.section.components = [new SectionModel().components[0]];
            }
        },
    },
    mounted() {
        this.setInitials();
        this.loadJourneys();
    },
    computed: {
        journeyState: () => {
            return store.state.journey.journey;
        },
        openEditSectionDialog: () => {
            return store.state.journey.openEditSectionDialog;
        },
        defaultEntity: () => {
            return store.state.journey.defaultEntity;
        },
        entityTypes: () => {
            return store.state.journey.entityTypes;
        },
        tabsList() {
            if (this.selectedJourney) {
                const journey = this.journeysList.find(
                    (journey) => journey.uuid === this.selectedJourney
                );
                const tabs = JSON.parse(journey.form_data);
                return tabs;
            }
            return [];
        },
        sectionsList() {
            if (this.selectedCloningTab) {
                const tab = this.tabsList.find((tab) => tab.tab_name === this.selectedCloningTab);
                return tab.sections;
            }
            return [];
        },
    },
};
</script>
