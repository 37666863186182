<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Manage Document Type</h1>
            <v-dialog v-model="dialog" persistent content-class="small-dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="secondary"
                        dark
                        class="text-capitalize mt-2"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                    >
                        Create New Document Type
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-space-between align-center secondary-font">
                        <p class="h6 primary--text font-weight-bold mb-0">{{ dialogTitle }}</p>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" text icon color="primary" @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card-text class="secondary-font pb-0">
                            <v-row>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Document type name</p>
                                    <v-text-field
                                        v-model="documentTypeData.name"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Document type name is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" xs="6">
                                    <p class="caption mb-0">Document type value</p>
                                    <v-text-field
                                        v-model="documentTypeData.value"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Document type value is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary mr-2" @click="save" elevation="0"> Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
            <v-card-text class="secondary-font pa-3">
                <v-data-table
                    :headers="headers"
                    :items="documentTypeSet"
                    class="elevation-0 noborder-table body-primary"
                >
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="darkGray" @click="openEditDialog(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="darkGray" @click="deleteEntityType(item.uuid)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <alert ref="confirm" />
    </v-container>
</template>

<script>
import DocumentTypeService from '../../services/document-type-service';
import notificationsUtility from '../../utilities/notifications-utility';
import common from '../../constant/common-data';
import Alert from '../../components/alert/ConfirmAlert.componet';

export default {
    name: 'DocumentTypes',
    components: {
        Alert,
    },
    data() {
        return {
            dialogTitle: 'Create Document Type',
            documentTypeData: {
                name: null,
                value: null,
            },
            uuid: null,
            isEdit: false,
            valid: false,
            dialog: false,
            documentTypeSet: [],
            headers: [
                { text: 'Document Type Name', value: 'name', sortable: false },
                { text: 'Document Type Value', value: 'value', sortable: false },
                { text: 'Action', value: 'action', align: 'right', sortable: false },
            ],
            status: common.status,
        };
    },
    methods: {
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
                this.documentTypeData = Object.assign({}, this.$options.data().documentTypeData);
            });
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.isEdit) {
                    DocumentTypeService.updateDocumentType(this.documentTypeData, this.uuid)
                        .then((response) => {
                            if (response.data.data) {
                                this.documentTypeSet = response.data.data;
                                this.isEdit = false;
                                this.close();
                                notificationsUtility.pushNotifications([
                                    {
                                        message: 'Document type updated successfully',
                                        type: 'success',
                                    },
                                ]);
                            }
                        })
                        .catch((error) => {
                            notificationsUtility.showNotifications(error.response.data);
                        });
                } else {
                    DocumentTypeService.saveDocumentType(this.documentTypeData)
                        .then((response) => {
                            this.documentTypeSet = response.data.data;
                            notificationsUtility.pushNotifications([
                                { message: 'Document type created successfully', type: 'success' },
                            ]);
                            this.close();
                        })
                        .catch((error) => {
                            this.universities = [];
                            notificationsUtility.showNotifications(error.response.data);
                        });
                }
            }
        },
        deleteEntityType(uuid) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this document type ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        DocumentTypeService.deleteDocumentType(uuid)
                            .then((response) => {
                                if (response.data.status === 1) {
                                    const entityIndex = this.documentTypeSet.findIndex(
                                        (x) => x.uuid === uuid
                                    );
                                    if (entityIndex !== -1)
                                        this.documentTypeSet.splice(entityIndex, 1);
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Document type deleted successfully',
                                            type: 'success',
                                        },
                                    ]);
                                }
                            })
                            .catch((error) => {
                                if (error.response.data.status === 0) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: error.response.data.message,
                                            type: 'error',
                                        },
                                    ]);
                                } else {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    }
                });
        },
        openEditDialog(item) {
            this.isEdit = true;
            this.documentTypeData.name = item.name;
            this.documentTypeData.value = item.value;
            this.uuid = item.uuid;
            this.dialog = true;
        },
        getDocumentTypes() {
            DocumentTypeService.getDocumentTypes()
                .then((response) => {
                    this.documentTypeSet = response.data.data;
                })
                .catch(() => {
                    notificationsUtility.pushNotifications([
                        {
                            message: 'Something went wrong. Please try contact server admin',
                            type: 'error',
                        },
                    ]);
                });
        },
    },
    mounted() {
        this.getDocumentTypes();
    },

    computed: {
        sites() {
            return this.$store.state.common.sites;
        },
    },
};
</script>

<style scoped></style>
