<template>
    <div v-if="profileForm.length > 0">
        <v-form ref="form" v-model="isFormValid">
            <v-row>
                <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    :md="component.grid_size"
                    v-for="(component, index) in profileForm"
                    :key="index"
                    class="pb-0"
                >
                    <div
                        v-if="component.component_type === 'TEXT_EDITOR'"
                        v-html="component.component_default_value"
                        :hidden="isHidden(component)"
                    ></div>

                    <TextFieldComponent
                        v-if="component.component_type === 'TEXT_FIELD'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <DropDownComponent
                        v-if="component.component_type === 'DROP_DOWN'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <CheckBoxComponent
                        v-if="component.component_type === 'CHECK_BOX'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <RadioComponent
                        v-if="component.component_type === 'RADIO'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <DateComponent
                        v-if="component.component_type === 'DATE'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                        :dateRangeValidations="component.advance_validation_rules.validation_rules"
                    />

                    <TimeComponent
                        v-if="component.component_type === 'TIME'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <FileUploadComponent
                        v-if="component.component_type === 'FILE_UPLOAD'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <DatabaseComponent
                        v-if="component.component_type === 'DATABASE'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <CrmDropDownComponent
                        v-if="component.component_type === 'CRM_DROP_DOWN'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <TextAreaComponent
                        v-if="component.component_type === 'TEXT_AREA'"
                        v-on:inputChanged="inputChanged"
                        :component="component"
                        :isRequired="isRequired(component.advance_validation_rules)"
                        :hidden="advancedConditionsVisibility(component.is_hidden, component)"
                        :enability="advancedConditionsEnability(component.is_disable, component)"
                        :validations="
                            getValidations(
                                component.advance_validation_rules,
                                component.component_label
                            )
                        "
                    />

                    <PaymentComponent
                        v-if="component.component_type === 'PAYMENT'"
                        :hidden="isHidden(component)"
                    />

                    <p
                        class="mb-0"
                        v-if="component.component_type === 'LABEL'"
                        :hidden="isHidden(component)"
                    >
                        {{ component.component_label }}
                    </p>

                    <div
                        v-if="component.component_type === 'SPACER'"
                        :hidden="isHidden(component)"
                    ></div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import TextFieldComponent from '../application/components/TextField';
import DropDownComponent from '../application/components/DropDown';
import CheckBoxComponent from '../application/components/CheckBox';
import RadioComponent from '../application/components/Radio';
import DateComponent from '../application/components/Date';
import TimeComponent from '../application/components/Time';
import FileUploadComponent from '../application/components/FileUpload';
import DatabaseComponent from '../application/components/Database';
import CrmDropDownComponent from '../application/components/CrmDropDown';
import TextAreaComponent from '../application/components/TextArea';
import PaymentComponent from '../application/components/payments/Payment';
export default {
    name: 'EditProfile.component',
    components: {
        TextFieldComponent,
        DropDownComponent,
        CheckBoxComponent,
        RadioComponent,
        DateComponent,
        TimeComponent,
        FileUploadComponent,
        DatabaseComponent,
        CrmDropDownComponent,
        TextAreaComponent,
        PaymentComponent,
    },
    props: {
        profileForm: Array,
    },
    data() {
        return {
            isFormValid: false,
            sectionComponents: [],
        };
    },
    methods: {
        inputChanged(input) {
            this.$emit('changeFormValues', input);
        },

        getValidations(validationRules, name) {
            let rules = [];
            if (validationRules.is_required) {
                rules.push((v) => !!v || `${name} is required`);

                if (validationRules.validation_rules.field_type) {
                    switch (validationRules.validation_rules.field_type) {
                        case 'text':
                            if (validationRules.validation_rules) {
                                switch (validationRules.validation_rules.type) {
                                    case 'maxLength':
                                        rules.push(
                                            (v) =>
                                                (v || '').length <=
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `A maximum of ${validationRules.validation_rules.value}  characters is allowed`
                                        );
                                        break;

                                    case 'minLength':
                                        rules.push(
                                            (v) =>
                                                (v || '').length >=
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `A minimum of ${validationRules.validation_rules.value}  characters is allowed`
                                        );
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;

                        case 'number':
                            rules.push((v) => !isNaN(v) || `${name} should be number`);
                            if (validationRules.validation_rules) {
                                switch (validationRules.validation_rules.type) {
                                    case 'equal':
                                        rules.push(
                                            (v) =>
                                                (!!v && v) ===
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `${name} should be equal to ${validationRules.validation_rules.value}`
                                        );
                                        break;

                                    case 'notEqual':
                                        rules.push(
                                            (v) =>
                                                (!!v && v) !==
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `${name} should be not equal to ${validationRules.validation_rules.value}`
                                        );
                                        break;

                                    case 'greaterThan':
                                        rules.push(
                                            (v) =>
                                                (!!v && v) >
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `${name} should be greater than ${validationRules.validation_rules.value}`
                                        );
                                        break;

                                    case 'greaterThanEqual':
                                        rules.push(
                                            (v) =>
                                                (!!v && v) >=
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `${name} should be greater than or equal to ${validationRules.validation_rules.value} `
                                        );
                                        break;

                                    case 'lessThan':
                                        rules.push(
                                            (v) =>
                                                (!!v && v) <
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `${name} should less than to ${validationRules.validation_rules.value} `
                                        );
                                        break;

                                    case 'lessThanEqual':
                                        rules.push(
                                            (v) =>
                                                (!!v && v) <=
                                                    parseInt(
                                                        validationRules.validation_rules.value
                                                    ) ||
                                                `${name} should be less than or equal to ${validationRules.validation_rules.value} `
                                        );
                                        break;

                                    default:
                                        break;
                                }
                            }
                            break;

                        case 'email':
                            rules.push((v) => /.+@.+\..+/.test(v) || `${name} must be valid`);
                            break;

                        default:
                            break;
                    }
                }
            }

            return rules;
        },

        isRequired(advanceValidationRules) {
            return advanceValidationRules && advanceValidationRules.is_required ? true : false;
        },

        isHidden(component) {
            if (component.is_hidden) {
                return true;
            } else {
                if (component.is_admin_only) {
                    let user = this.$store.state.auth.user;
                    return user.role[0].name === 'ADMIN' ? false : true;
                } else {
                    return false;
                }
            }
        },

        advancedConditionsEnability(currentCondition, component) {
            let condition = currentCondition;

            if (!component.is_hidden) {
                let enabilityRules = this.checkHasRules(
                    component.advance_validation_rules.enability_rules
                );
                let visibilityRules = this.checkHasRules(
                    component.advance_validation_rules.visibility_rules
                );
                let editRules = this.checkHasRules(component.advance_validation_rules.edit_rules);

                let isComponentVisible = true;
                if (visibilityRules) {
                    isComponentVisible = this.advancedConditionsVisibility(
                        component.is_hidden,
                        component
                    );
                }

                if (isComponentVisible) {
                    if (enabilityRules) {
                        condition = this.applyEnabilityCondition(
                            component.advance_validation_rules.enability_rules,
                            component
                        );
                    }

                    if (this.isEditMood && editRules) {
                        condition = this.applyEnabilityCondition(
                            component.advance_validation_rules.edit_rules,
                            component,
                            true
                        );
                    }
                }

                return condition;
            }

            return condition;
        },

        advancedConditionsVisibility(currentCondition, component) {
            if (!this.isAdmin && component.is_admin_only) {
                return true;
            }

            let condition = currentCondition;
            if (!component.is_hidden) {
                let visibilityRules = this.checkHasRules(
                    component.advance_validation_rules.visibility_rules
                );

                if (visibilityRules) {
                    condition = this.applyVisibilityCondition(
                        component.advance_validation_rules.visibility_rules
                    );
                }
                return condition;
            }

            return condition;
        },

        applyEnabilityCondition(rule, component, isEditRules = false) {
            let allComponents = this.getComponents(isEditRules);

            let parentComponent = allComponents.filter((component) => {
                if (component.crm_field === rule.parent_field) {
                    return component;
                }
            });

            if (parentComponent.length > 0 && parentComponent[0].value) {
                var valuesArray = rule.value.split(',');

                if (valuesArray.includes(parentComponent[0].value.toString())) {
                    return rule.action === 'enable' ? false : true;
                }

                return rule.action === 'enable' ? true : false;
            } else {
                if (isEditRules) {
                    return component.is_disable;
                }
            }

            return true;
        },

        applyVisibilityCondition(rule) {
            let allComponents = this.profileForm;

            let parentComponent = allComponents.filter((component) => {
                if (component.crm_field === rule.parent_field) {
                    return component;
                }
            });

            var valuesArray = rule.value.split(',');

            if (parentComponent.length > 0 && parentComponent[0].value) {
                if (valuesArray.includes(parentComponent[0].value.toString())) {
                    return rule.action === 'hide' ? false : true;
                }

                return rule.action === 'show' ? false : true;
            }

            return false;
        },

        checkHasRules(rule) {
            return rule.parent_field && rule.value && rule.action ? true : false;
        },
    },
    watch: {
        isFormValid() {
            this.$emit('formValidity', this.isFormValid);
        },
    },
    mounted() {},
};
</script>

<style scoped></style>
