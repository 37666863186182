<template>
    <div class="height100">
        <v-container class="boundry height100">
            <v-row class="d-flex align-center justify-center height100">
                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="4">
                    <v-card flat class="rounded-0">
                        <v-card-text class="primary--text text-center">
                            <p class="text-h4 mb-5">Change Email</p>

                            <v-form ref="form" class="transparent-inputs">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12 my-0 py-0">
                                            <v-text-field
                                                type="email"
                                                label="Current Email"
                                                solo
                                                flat
                                                outlined
                                                dense
                                                validate-on-blur
                                                name="current_email"
                                                v-model="formData.current_email"
                                                :rules="[validations.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12 my-0 py-0">
                                            <v-text-field
                                                type="email"
                                                label="New Email"
                                                solo
                                                flat
                                                outlined
                                                dense
                                                validate-on-blur
                                                name="email"
                                                v-model="formData.email"
                                                :rules="[validations.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12 my-0 py-0">
                                            <v-text-field
                                                type="email"
                                                label="Confirm New Email"
                                                solo
                                                flat
                                                outlined
                                                dense
                                                validate-on-blur
                                                name="email_confirmation"
                                                v-model="formData.email_confirmation"
                                                :rules="[validations.required]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-btn
                                        color="secondary"
                                        @click="changeEmail"
                                        elevation="0"
                                        class="text-capitalize mt-6"
                                        large
                                        block
                                    >
                                        Change Email
                                    </v-btn>
                                </v-container>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import validations from '../../utilities/validations-utility';
import notificationsUtility from '../../utilities/notifications-utility';
import authService from '../../services/auth-service';

export default {
    name: 'changeEmail',
    data() {
        return {
            formData: {
                current_email: null,
                email: null,
                email_confirmation: null,
            },
            validations,
        };
    },
    computed: {
        site() {
            return this.$store.state.common.site;
        },
    },
    methods: {
        changeEmail() {
            if (this.$refs.form.validate()) {
                authService
                    .changeEmail(this.formData)
                    .then((response) => {
                        this.$router.push(`/${this.site}/`);
                        notificationsUtility.showNotifications(response.data);
                    })
                    .catch((error) => {
                        notificationsUtility.showNotifications(error.response.data);
                    });
            }
        },
    },
};
</script>
