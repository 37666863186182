<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="3" md="3">
                <v-switch
                    label="Show Label"
                    v-model="componentValues.is_show_label"
                    hide-details
                    class="mr-4 mt-2"
                    dense
                ></v-switch>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2">
                <v-checkbox
                    label="Hidden"
                    v-model="componentValues.is_hidden"
                    outlined
                    hide-details
                    dense
                    class="mt-2"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" xs="12" sm="7" md="7">
                <v-textarea
                    flat
                    outlined
                    dense
                    v-model="componentValues.component_default_value"
                    label="Default Value"
                ></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'TextArea',
    props: {
        isHidden: Boolean,
        isShowLabel: Boolean,
        defaultValue: String,
    },
    data() {
        return {
            componentValues: {
                component_default_value: null,
                is_show_label: false,
                is_hidden: false,
            },
        };
    },
    methods: {
        setInitials() {
            this.componentValues.component_default_value = this.defaultValue
                ? this.defaultValue
                : null;
            this.componentValues.is_show_label = this.isShowLabel ? this.isShowLabel : null;
            this.componentValues.is_hidden = this.isHidden ? this.isHidden : null;
        },
    },
    watch: {
        'componentValues.component_default_value'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_show_label'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_hidden'() {
            this.$emit('update-values', this.componentValues);
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>
