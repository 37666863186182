export default {
    addCreateFormData: (state, value) => {
        state.profileFormData = JSON.parse(value);
    },
    updateComponentPosition: (state, params) => {
        let element = state.profileFormData[params.componentKey];
        state.profileFormData.splice(params.componentKey, 1);
        if (params.action === 'up') {
            let newPosition = params.componentKey - 1;
            if (params.componentKey > 0) {
                state.profileFormData.splice(newPosition, 0, element);
            }
        }
        if (params.action === 'down') {
            let newPosition = params.componentKey + 1;
            state.profileFormData.splice(newPosition, 0, element);
        }
    },

    updateComponent: (state, params) => {
        state.profileFormData[params.selectedComponent] = params.component;
    },

    addComponent: (state, params) => {
        state.profileFormData.push(params);
    },
    addNewComponent: (state, params) => {
        state.profileFormData[params.selectedComponent] = params.component;
        state.profileFormData.splice(params.selectedComponent + 1, 0, params.newComponent);
    },
    deleteComponent: (state, params) => {
        state.profileFormData.splice(params, 1);
    },
    duplicateComponent: (state, params) => {
        state.profileFormData.splice(params.selectedComponent + 1, 0, params.component);
    },
    minimizeCurrentComponent(state, params) {
        state.profileFormData[params].hide = true;
    },
    expandCurrentComponent(state, params) {
        state.profileFormData[params].hide = !state.profileFormData[params].hide;
        state.profileFormData.forEach((component, index) => {
            if (index !== params) {
                component.hide = true;
            }
        });
    },
    updateEntityTypes(state, params) {
        state.entityTypes = params;
    },
};
