<template>
    <v-row>
        <v-col cols="12" xs="12" sm="3" md="3">
            <v-switch
                label="Show Label"
                v-model="componentValues.is_show_label"
                hide-details
                class="mr-4 mt-2"
                dense
            ></v-switch>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2">
            <v-checkbox
                label="Hidden"
                v-model="componentValues.is_hidden"
                outlined
                hide-details
                dense
                class="mt-2"
            ></v-checkbox>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2">
            <v-checkbox
                label="New tab"
                v-model="componentValues.is_open_on_tab"
                outlined
                hide-details
                dense
                class="mt-2"
            ></v-checkbox>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="6">
            Button Color
            <v-color-picker
                v-model="componentValues.button_color"
                dot-size="25"
                mode="hexa"
                swatches-max-height="200"
            ></v-color-picker>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Button',
    props: {
        isHidden: Boolean,
        isShowLabel: Boolean,
        defaultValue: String,
        isOpenOnTab: Boolean,
        isButtonColor: String,
    },
    data() {
        return {
            componentValues: {
                component_default_value: null,
                is_show_label: false,
                is_hidden: true,
                is_open_on_tab: true,
                button_color: 'primary',
            },
        };
    },
    methods: {
        setInitials() {
            this.componentValues.component_default_value = this.defaultValue
                ? this.defaultValue
                : null;
            this.componentValues.is_show_label = this.isShowLabel ? this.isShowLabel : null;
            this.componentValues.is_hidden = this.isHidden ? this.isHidden : null;
            this.componentValues.is_open_on_tab = this.isOpenOnTab ? this.isOpenOnTab : null;
            this.componentValues.button_color = this.isButtonColor ? this.isButtonColor : null;
        },
    },
    watch: {
        'componentValues.component_default_value'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_show_label'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_hidden'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_open_on_tab'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.button_color'() {
            this.$emit('update-values', this.componentValues);
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
