<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Configure System General Settings</h1>
        </div>
        <v-card class="mx-auto mt-5 rounded-10" flat>
            <v-card-text class="secondary-font pb-0">
                <v-row>
                    <v-col cols="12" class="d-flex my-5">
                        <v-divider class="my-auto"></v-divider>
                        <span class="primary--text font-weight-bold mx-5 my-auto">General</span>
                        <v-divider class="my-auto"></v-divider>
                    </v-col>
                    <v-col cols="6" class="d-flex flex-column logo-container">
                        <p class="caption mb-0 mx-auto text-center">Light Logo</p>
                        <v-img
                            :src="logoLightPreview"
                            contain
                            class="grey lighten-2 mx-auto"
                            width="80%"
                            min-height="120"
                        />
                        <div class="logo-button-container">
                            <v-btn
                                color="secondary"
                                class="ma-1 white--text"
                                fab
                                @click="browseLightFile"
                                x-small
                            >
                                <v-icon dark> mdi-cloud-upload </v-icon> </v-btn
                            ><v-btn
                                color="secondary"
                                class="ma-1 white--text"
                                fab
                                @click="removeLightLogo"
                                x-small
                            >
                                <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                        </div>
                        <input
                            ref="fileLightInput"
                            class="d-none"
                            type="file"
                            @change="onLightFileChange"
                            accept="image/png, image/jpeg, image/bmp"
                        />
                    </v-col>
                    <v-col cols="6" class="d-flex flex-column logo-container">
                        <p class="caption mb-0 mx-auto text-center">Dark Logo</p>
                        <v-img
                            :src="logoDarkPreview"
                            contain
                            class="grey lighten-2 mx-auto"
                            width="80%"
                            min-height="120"
                        />
                        <div class="logo-button-container">
                            <v-btn
                                color="secondary"
                                class="ma-1 white--text"
                                fab
                                @click="browseDarkFile"
                                x-small
                            >
                                <v-icon dark> mdi-cloud-upload </v-icon> </v-btn
                            ><v-btn
                                color="secondary"
                                class="ma-1 white--text"
                                fab
                                @click="removeDarkLogo"
                                x-small
                            >
                                <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                        </div>
                        <input
                            ref="fileDarkInput"
                            class="d-none"
                            type="file"
                            @change="onDarkFileChange"
                            accept="image/png, image/jpeg, image/bmp"
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0 mx-auto text-center">
                            This logo is displayed on dark backgrounds
                        </p>
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0 mx-auto text-center">
                            This logo is displayed on light backgrounds
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <p class="caption mb-0">Application Heading</p>
                        <v-text-field
                            v-model="settings.application_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <p class="caption mb-0">Primary Color</p>
                        <v-color-picker
                            v-model="settings.primary_color"
                            dense
                            outlined
                            hide-details
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <p class="caption mb-0">Secondary Color</p>
                        <v-color-picker
                            v-model="settings.secondary_color"
                            dense
                            outlined
                            hide-details
                        ></v-color-picker>
                    </v-col>
                    <v-col cols="12">
                        <p class="caption mb-0">Maintenance Mode</p>
                        <v-switch v-model="settings.maintenance_mode" />
                    </v-col>
                    <v-col cols="12" class="d-flex mb-5">
                        <v-divider class="my-auto"></v-divider>
                        <span class="primary--text font-weight-bold mx-5 my-auto">Login Page</span>
                        <v-divider class="my-auto"></v-divider>
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Registered User Heading</p>
                        <v-text-field
                            v-model="settings.registered_user_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">New User Heading</p>
                        <v-text-field
                            v-model="settings.new_user_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <p class="caption mb-0">New User Description</p>
                        <quill-editor
                            v-model="settings.new_user_description"
                            :options="quillOptions"
                            class="quill-text-editor"
                        />
                        <v-textarea
                            class="quill-content-container"
                            v-model="settings.new_user_description"
                            dense
                            outlined
                            rows="2"
                        >
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" class="d-flex mb-5">
                        <v-divider class="my-auto"></v-divider>
                        <span class="primary--text font-weight-bold mx-5 my-auto">Home Page</span>
                        <v-divider class="my-auto"></v-divider>
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Welcome Heading</p>
                        <v-text-field
                            v-model="settings.welcome_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Welcome Sub Heading</p>
                        <v-text-field
                            v-model="settings.welcome_sub_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Applications Section Heading</p>
                        <v-text-field
                            v-model="settings.applications_section_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Applications Section Sub Heading</p>
                        <v-text-field
                            v-model="settings.applications_section_sub_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Programs Section Heading</p>
                        <v-text-field
                            v-model="settings.programs_section_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6">
                        <p class="caption mb-0">Programs Section Sub Heading</p>
                        <v-text-field
                            v-model="settings.programs_section_sub_heading"
                            dense
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" class="d-flex">
                        <v-btn color="secondary" class="ml-auto" @click="handleSave">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <confirm-alert ref="confirm" />
    </v-container>
</template>

<script>
import commonService from '../../services/common-service';
import notificationsUtility from '../../utilities/notifications-utility';
import ConfirmAlert from '../../components/alert/ConfirmAlert.componet';

export default {
    name: 'GeneralSettings',
    components: { ConfirmAlert },
    data() {
        return {
            settings: {
                application_heading: this.applicationHeader,
                logo_light: null,
                logo_light_file: null,
                delete_logo_light: false,
                logo_dark: null,
                logo_dark_file: null,
                delete_logo_dark: false,
                maintenance_mode: false,
                primary_color: '#00859b',
                secondary_color: '#df7a0e',
                registered_user_heading: null,
                new_user_heading: null,
                new_user_description: null,
                welcome_heading: null,
                welcome_sub_heading: null,
                applications_section_heading: null,
                applications_section_sub_heading: null,
                programs_section_heading: null,
                programs_section_sub_heading: null,
            },
            quillOptions: {
                placeholder: null,
            },
        };
    },
    computed: {
        sites() {
            return this.$store.state.common.sites;
        },
        site() {
            return this.$store.state.common.site;
        },
        logoLightPreview() {
            const logo_light = this.$store.getters['common/logoLight'];
            return this.settings.delete_logo_light
                ? null
                : this.settings.logo_light_file
                ? this.settings.logo_light
                : logo_light
                ? logo_light.value
                : null;
        },
        logoDarkPreview() {
            const logo_dark = this.$store.getters['common/logoDark'];
            return this.settings.delete_logo_dark
                ? null
                : this.settings.logo_dark_file
                ? this.settings.logo_dark
                : logo_dark
                ? logo_dark.value
                : null;
        },
        applicationHeader() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const applicationHeader = applicationHeading ? applicationHeading.value : null;
            this.setApplicationHeader(applicationHeader);
            return applicationHeader;
        },
        maintenanceMode() {
            const maintenanceModeSetting = this.$store.getters['common/maintenanceMode'];
            const maintenanceMode = maintenanceModeSetting
                ? maintenanceModeSetting.value === 'true'
                : false;
            this.setMaintenanceMode(maintenanceMode);
            return maintenanceMode;
        },
        primaryColor() {
            const primaryColor = this.$store.getters['common/primaryColor'];
            const primaryColorCode = primaryColor ? primaryColor.value : '#00859b';
            this.setPrimaryColor(primaryColorCode);
            return primaryColorCode;
        },
        secondaryColor() {
            const secondaryColor = this.$store.getters['common/secondaryColor'];
            const secondaryColorCode = secondaryColor ? secondaryColor.value : '#df7a0e';
            this.setSecondaryColor(secondaryColorCode);
            return secondaryColorCode;
        },
        registeredUserHeading() {
            const dataSetting = this.$store.getters['common/registeredUserHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setRegisteredUserHeading(data);
            return data;
        },
        newUserHeading() {
            const dataSetting = this.$store.getters['common/newUserHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setNewUserHeading(data);
            return data;
        },
        newUserDescription() {
            const dataSetting = this.$store.getters['common/newUserDescription'];
            const data = dataSetting ? dataSetting.value : null;
            this.setNewUserDescription(data);
            return data;
        },
        welcomeHeading() {
            const dataSetting = this.$store.getters['common/welcomeHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setWelcomeHeading(data);
            return data;
        },
        welcomeSubHeading() {
            const dataSetting = this.$store.getters['common/welcomeSubHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setWelcomeSubHeading(data);
            return data;
        },
        applicationsSectionHeading() {
            const dataSetting = this.$store.getters['common/applicationsSectionHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setApplicationsSectionHeading(data);
            return data;
        },
        applicationsSectionSubHeading() {
            const dataSetting = this.$store.getters['common/applicationsSectionSubHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setApplicationsSectionSubHeading(data);
            return data;
        },
        programsSectionHeading() {
            const dataSetting = this.$store.getters['common/programsSectionHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setProgramsSectionHeading(data);
            return data;
        },
        programsSectionSubHeading() {
            const dataSetting = this.$store.getters['common/programsSectionSubHeading'];
            const data = dataSetting ? dataSetting.value : null;
            this.setProgramsSectionSubHeading(data);
            return data;
        },
    },
    watch: {
        maintenanceMode() {
            this.settings.maintenance_mode = this.maintenanceMode;
        },
        applicationHeader() {
            this.settings.application_heading = this.applicationHeader;
        },
        primaryColor() {
            this.settings.primary_color = this.primaryColor;
        },
        secondaryColor() {
            this.settings.secondary_color = this.secondaryColor;
        },
        registeredUserHeading() {
            this.settings.registered_user_heading = this.registeredUserHeading;
        },
        newUserHeading() {
            this.settings.new_user_heading = this.newUserHeading;
        },
        newUserDescription() {
            this.settings.new_user_description = this.newUserDescription;
        },
        welcomeHeading() {
            this.settings.welcome_heading = this.welcomeHeading;
        },
        welcomeSubHeading() {
            this.settings.welcome_sub_heading = this.welcomeSubHeading;
        },
        applicationsSectionHeading() {
            this.settings.applications_section_heading = this.applicationsSectionHeading;
        },
        applicationsSectionSubHeading() {
            this.settings.applications_section_sub_heading = this.applicationsSectionSubHeading;
        },
        programsSectionHeading() {
            this.settings.programs_section_heading = this.programsSectionHeading;
        },
        programsSectionSubHeading() {
            this.settings.programs_section_sub_heading = this.programsSectionSubHeading;
        },
    },
    methods: {
        setApplicationHeader(applicationHeader) {
            this.settings.application_heading = applicationHeader;
        },
        setRegisteredUserHeading(data) {
            this.settings.registered_user_heading = data;
        },
        setNewUserHeading(data) {
            this.settings.new_user_heading = data;
        },
        setNewUserDescription(data) {
            this.settings.new_user_description = data;
        },
        setWelcomeHeading(data) {
            this.settings.welcome_heading = data;
        },
        setWelcomeSubHeading(data) {
            this.settings.welcome_sub_heading = data;
        },
        setApplicationsSectionHeading(data) {
            this.settings.applications_section_heading = data;
        },
        setApplicationsSectionSubHeading(data) {
            this.settings.applications_section_sub_heading = data;
        },
        setProgramsSectionHeading(data) {
            this.settings.programs_section_heading = data;
        },
        setProgramsSectionSubHeading(data) {
            this.settings.programs_section_sub_heading = data;
        },
        setMaintenanceMode(mode) {
            this.settings.maintenance_mode = mode;
        },
        setPrimaryColor(color) {
            this.settings.primary_color = color;
        },
        setSecondaryColor(color) {
            this.settings.secondary_color = color;
        },
        browseLightFile() {
            this.$refs.fileLightInput.click();
        },
        onLightFileChange(event) {
            if (event.target.files.length) {
                if (!['image/png', 'image/jpeg'].includes(event.target.files[0].type)) {
                    notificationsUtility.pushNotifications([
                        {
                            message: 'Invalid image format',
                            type: 'error',
                        },
                    ]);
                    this.removeLightLogo();
                } else {
                    this.settings.logo_light_file = event.target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onloadend = () => {
                        this.settings.delete_logo_light = false;
                        this.settings.logo_light = reader.result;
                    };
                }
            } else {
                this.removeLightLogo();
            }
        },
        removeLightLogo() {
            this.settings.delete_logo_light = this.settings.logo_light ? false : true;
            this.settings.logo_light = null;
            this.settings.logo_light_file = null;
            this.$refs.fileLightInput.value = null;
        },
        browseDarkFile() {
            this.$refs.fileDarkInput.click();
        },
        onDarkFileChange(event) {
            if (event.target.files.length) {
                if (!['image/png', 'image/jpeg'].includes(event.target.files[0].type)) {
                    notificationsUtility.pushNotifications([
                        {
                            message: 'Invalid image format',
                            type: 'error',
                        },
                    ]);
                    this.removeDarkLogo();
                } else {
                    this.settings.logo_dark_file = event.target.files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onloadend = () => {
                        this.settings.delete_logo_dark = false;
                        this.settings.logo_dark = reader.result;
                    };
                }
            } else {
                this.removeDarkLogo();
            }
        },
        removeDarkLogo() {
            this.settings.delete_logo_dark = this.settings.logo_dark ? false : true;
            this.settings.logo_dark = null;
            this.settings.logo_dark_file = null;
            this.$refs.fileDarkInput.value = null;
        },
        handleSave() {
            const formData = new FormData();

            if (this.settings.logo_light_file)
                formData.append('logo_light_file', this.settings.logo_light_file);
            if (this.settings.delete_logo_light)
                formData.append('delete_logo_light', this.settings.delete_logo_light);
            if (this.settings.logo_dark_file)
                formData.append('logo_dark_file', this.settings.logo_dark_file);
            if (this.settings.delete_logo_dark)
                formData.append('delete_logo_dark', this.settings.delete_logo_dark);
            if (this.settings.application_heading)
                formData.append('application_heading', this.settings.application_heading);
            if (this.settings.registered_user_heading)
                formData.append('registered_user_heading', this.settings.registered_user_heading);
            if (this.settings.new_user_heading)
                formData.append('new_user_heading', this.settings.new_user_heading);
            if (this.settings.new_user_description)
                formData.append('new_user_description', this.settings.new_user_description);
            if (this.settings.welcome_heading)
                formData.append('welcome_heading', this.settings.welcome_heading);
            if (this.settings.welcome_sub_heading)
                formData.append('welcome_sub_heading', this.settings.welcome_sub_heading);
            if (this.settings.applications_section_heading)
                formData.append(
                    'applications_section_heading',
                    this.settings.applications_section_heading
                );
            if (this.settings.applications_section_sub_heading)
                formData.append(
                    'applications_section_sub_heading',
                    this.settings.applications_section_sub_heading
                );
            if (this.settings.programs_section_heading)
                formData.append('programs_section_heading', this.settings.programs_section_heading);
            if (this.settings.programs_section_sub_heading)
                formData.append(
                    'programs_section_sub_heading',
                    this.settings.programs_section_sub_heading
                );
            if (this.settings.maintenance_mode)
                formData.append('maintenance_mode', this.settings.maintenance_mode);
            if (this.settings.primary_color)
                formData.append('primary_color', this.settings.primary_color);
            if (this.settings.secondary_color)
                formData.append('secondary_color', this.settings.secondary_color);
            commonService
                .saveSettings(formData, { 'Content-Type': 'multipart/form-data' })
                .then((response) => {
                    this.$store.commit('common/SET_SYSTEM_SETTINGS', response.data.data.general);
                    notificationsUtility.showNotifications(response.data);
                });
        },
    },
};
</script>
