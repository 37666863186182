<template>
    <v-container v-show="isVisible" v-if="isVisibleTabClick">
        <div class="p-5">
            <p class="header-text mb-5 text-capitalize journey-header-text">
                {{ journeyItem.tab_display_name }}
            </p>

            <v-form :ref="journeyItem.tab_name" v-model="isFormValid">
                <journey-section
                    :journeySection="section"
                    :section="sections[index]"
                    :appearanceSection="appearanceSections[index]"
                    :allSections="sections"
                    :allTabs="allTabs"
                    v-for="(section, index) in onlyActiveSections"
                    :key="index"
                    v-on:sectionComponents="sectionComponents"
                    v-on:appearanceSectionComponents="appearanceSectionComponents"
                    :isEditMood="isEditMood"
                    :activeTab="activeTab"
                    :isVisible="isVisible"
                    :isDisable="isDisable"
                    :isSectionDisable="
                        appearanceSections.hasOwnProperty('is_disable')
                            ? !appearanceSections[index].is_disable
                            : false
                    "
                    :isSectionVisible="
                        appearanceSections.hasOwnProperty('is_hidden')
                            ? !appearanceSections[index].is_hidden
                            : true
                    "
                    :applicationSubmit="applicationSubmit"
                />
                <div class="d-flex justify-end">
                    <v-btn
                        v-if="
                            !application ||
                            (application && application.applicationSubmitted === 'No')
                        "
                        :color="journeyItem.is_submit ? 'secondary' : 'primary'"
                        elevation="0"
                        class="text-capitalize mt-2 text-end"
                        large
                        dark
                        @click="journeyItem.read_only ? triggerNextTab() : triggerSave()"
                        :disabled="
                            isDisable ||
                            (journeyItem.is_submit
                                ? !isFormValid || !eligibleForSubmit
                                : !isFormValid)
                        "
                    >
                        {{ journeyItem.button_text }}
                    </v-btn>
                    <v-btn
                        v-if="application && application.applicationSubmitted === 'Yes'"
                        color="primary"
                        elevation="0"
                        class="text-capitalize mt-2 text-end"
                        large
                        dark
                        @click="journeyItem.read_only ? triggerNextTab() : triggerSave()"
                        :disabled="!isFormValid"
                    >
                        Save & Next
                    </v-btn>
                </div>
            </v-form>
        </div>
    </v-container>
</template>

<script>
import JourneySection from './JourneySection.vue';
import advancedValidation from '../../assets/scripts/advanced-validation';

export default {
    components: { JourneySection },
    name: 'JourneyItem',
    props: {
        journeyItem: {
            type: [Object, Array],
            default: null,
        },
        sections: {
            type: [Object, Array],
            default: false,
        },
        isEditMood: {
            type: Boolean,
            default: false,
        },
        allTabs: {
            type: [Object, Array],
            default: null,
        },
        activeTab: {
            type: Number,
            default: 0,
        },
        appearanceSections: {
            type: [Object, Array],
            default: false,
        },
        eligibleForSubmit: {
            type: Boolean,
            default: false,
        },
        isVisible: {
            type: Boolean,
            default: true,
        },
        isDisable: {
            type: Boolean,
            default: false,
        },
        isSectionDisable: {
            type: Boolean,
            default: false,
        },
        isSectionVisible: {
            type: Boolean,
            default: true,
        },
        applicationSubmit: {
            type: String,
            default: 'No',
        },
    },
    data() {
        return {
            journeySections: [],
            isFormValid: false,
        };
    },

    computed: {
        isVisibleTabClick() {
            return (tab) => {
                return this.isVisibleTabClickNext(tab);
            };
        },

        isAdmin() {
            let logAsUserStatus = localStorage.getItem('log_as_user_status');
            let logAsUser = localStorage.getItem('log_as_user');

            let currentUser =
                logAsUserStatus && logAsUserStatus === 'true'
                    ? JSON.parse(logAsUser)
                    : this.$store.state.auth.user;

            if (currentUser && currentUser.role) {
                return currentUser.role[0].name === 'ADMIN' ? true : false;
            }

            return false;
        },

        onlyActiveSections() {
            if (this.journeyItem.sections && this.journeyItem.sections.length > 0) {
                if (!this.isAdmin) {
                    return this.journeyItem.sections.filter(
                        (section) => section.status === 'ACTIVE'
                    );
                }

                return this.journeyItem.sections;
            }

            return [];
        },

        application() {
            return this.$store.state.common.curentApplication;
        },
    },

    methods: {
        isVisibleTabClickNext(tabIndex) {
            this.$emit('checkIfNextTabIsVisibleActiveNext', tabIndex);
        },
        hideJourneySectionItems(currentSection) {
            let is_hidden = advancedValidation.advancedConditionsVisibilitySection(
                false,
                currentSection,
                this.isAdmin,
                this.applicationSubmit
            );
            if (is_hidden) {
                return true;
            }
            return false;
        },

        disableJourneySectionItems(currentSection) {
            let is_disable = advancedValidation.advancedConditionsEnabilitySection(
                false,
                currentSection,
                this.isAdmin,
                this.applicationSubmit
            );

            if (is_disable) {
                return true;
            }
            return false;
        },

        isActiveSection(status) {
            let active = true;
            if (!this.isAdmin) {
                active = status === 'ACTIVE' ? true : false;
            }

            return active;
        },

        sectionComponents(data) {
            this.sections.forEach((section) => {
                if (section.section_name === data.name) {
                    section['components'] = data.value;
                }
                return;
            });

            let inputValue = {
                key: this.journeyItem.tab_name,
                value: this.sections,
                isFormValid: this.isFormValid,
            };

            this.$emit('journeySections', inputValue);
        },

        appearanceSectionComponents(data) {
            this.appearanceSections.forEach((section) => {
                if (section.section_name === data.name) {
                    section['components'] = data.value;
                }
                return;
            });

            let inputValue = {
                key: this.journeyItem.tab_name,
                value: this.appearanceSections,
            };

            this.$emit('appearanceJourneySections', inputValue);
        },

        triggerSave() {
            this.$emit('saveApplication');
        },

        triggerNextTab() {
            this.$emit('gotoNextTab');
        },
    },
};
</script>
