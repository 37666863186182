<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div
                    class="d-sm-flex d-xs-block justify-end align-center text-center secondary-font"
                >
                    <p class="mb-0">Default</p>
                </div>
                <div
                    class="d-sm-flex d-xs-block justify-space-between justify-center align-center text-center"
                    v-for="(option, index) in options"
                    :key="index"
                >
                    <div class="d-flex align-center secondary-font width80">
                        <div class="mr-3 mt-2">
                            <v-btn x-small text icon color="darkGray">
                                <v-icon x-small>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn x-small text icon color="darkGray">
                                <v-icon x-small>mdi-arrow-down</v-icon>
                            </v-btn>
                        </div>
                        <span class="mt-2">{{ option.id }}.</span>
                        <v-text-field
                            hide-details
                            dense
                            :value="option.name"
                            class="ml-2"
                        ></v-text-field>
                    </div>

                    <div class="d-flex align-center justify-center secondary-font">
                        <v-switch
                            :input-value="option.default"
                            v-on:click.native="setDefault(index)"
                            hide-details
                        ></v-switch>
                        <v-btn icon class="mt-4" @click="removeOption(index)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-btn small class="mt-2" color="secondary" elevation="0" @click="addOption">
                    Add Option
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    data() {
        return {
            items: ['foo', 'bar'],
            options: [
                {
                    id: 1,
                    name: 'Option 1',
                    default: true,
                },
                {
                    id: 2,
                    name: 'Option 2',
                    default: false,
                },
                {
                    id: 3,
                    name: 'Option 3',
                    default: false,
                },
                {
                    id: 4,
                    name: 'Option 4',
                    default: false,
                },
            ],

            defaultSwitch: true,
        };
    },
    methods: {
        setDefault(currentIndex) {
            this.options[currentIndex].default = true;

            this.options.forEach((option, index) => {
                if (index !== currentIndex) {
                    option.default = false;
                }
            });
        },

        addOption() {
            let currentHighestValue = 0;

            if (this.options.length > 0) {
                currentHighestValue = Math.max.apply(
                    Math,
                    this.options.map((option) => {
                        return option.id;
                    })
                );
            }

            let option = {
                id: currentHighestValue + 1,
                name: 'Untitled option',
                default: false,
            };

            this.options.push(option);
        },

        removeOption(index) {
            this.options.splice(this.options.indexOf(index), 1);
        },
    },
};
</script>
