import _ from 'lodash';

export default {
    getComponents(editRules, allTabs, allSections) {
        let allComponents = [];
        if (editRules) {
            allTabs.forEach((tab) => {
                tab.sections.forEach((section) => {
                    section.components.forEach((component) => {
                        allComponents.push(component);
                    });
                });
            });
        } else {
            allSections.forEach((section) => {
                section.components.forEach((component) => {
                    allComponents.push(component);
                });
            });
        }

        return allComponents;
    },
    getAllComponentsInTab(allTabs) {
        let allComponents = [];

        allTabs.forEach((tab) => {
            tab.sections.forEach((section) => {
                section.components.forEach((component) => {
                    allComponents.push(component);
                });
            });
        });

        return allComponents;
    },
    getSectionComponents(section) {
        let allComponents = [];

        section.components.forEach((component) => {
            allComponents.push(component);
        });
        return allComponents;
    },
    advancedConditionsEnability(
        currentCondition,
        component,
        isEditMood,
        allTabs,
        allSections,
        isAdmin,
        isApplicationSubmitted
    ) {
        let condition = currentCondition;

        if (!component.is_hidden) {
            let enabilityRules = this.checkHasRules(
                component.advance_validation_rules.enability_rules
            );
            let visibilityRules = this.checkHasRules(
                component.advance_validation_rules.visibility_rules
            );
            let editRules = this.checkHasRules(component.advance_validation_rules.edit_rules);

            let isComponentVisible = true;
            if (visibilityRules) {
                isComponentVisible = this.advancedConditionsVisibility(
                    component.is_hidden,
                    component,
                    isEditMood,
                    allTabs,
                    allSections,
                    isAdmin,
                    isApplicationSubmitted
                );
            }

            if (isComponentVisible) {
                if (enabilityRules) {
                    condition = this.applyEnabilityCondition(
                        component.advance_validation_rules.enability_rules,
                        component,
                        isEditMood,
                        allTabs,
                        allSections,
                        isApplicationSubmitted,
                        false,
                        component.crm_data_enability
                    );
                }

                if (isEditMood && editRules) {
                    let checkValidity = this.applyEnabilityCondition(
                        component.advance_validation_rules.edit_rules,
                        component,
                        isEditMood,
                        allTabs,
                        allSections,
                        isApplicationSubmitted,
                        true,
                        component.crm_data_enability_edit
                    );

                    if (checkValidity !== 'invalid') {
                        condition = checkValidity;
                    }
                }
            }

            return condition;
        }

        return condition;
    },

    advancedConditionsVisibility(
        currentCondition,
        component,
        isEditMood,
        allTabs,
        allSections,
        isAdmin,
        isApplicationSubmitted
    ) {
        if (!isAdmin && component.is_admin_only) {
            return false;
        }

        let condition = !currentCondition;

        let visibilityRules = this.checkHasRules(
            component.advance_validation_rules.visibility_rules
        );
        if (visibilityRules) {
            condition = this.applyVisibilityCondition(
                component.advance_validation_rules.visibility_rules,
                isEditMood,
                allTabs,
                allSections,
                component,
                isApplicationSubmitted
            );
        }
        return condition;
    },
    advancedConditionsVisibilitySection(
        currentCondition,
        component,
        isAdmin,
        isApplicationSubmitted
    ) {
        if (!isAdmin && component.is_admin_only) {
            return false;
        }

        let condition = !currentCondition;
        if (component.advance_validation_rules) {
            let visibilityRules = this.checkHasRules(
                component.advance_validation_rules.visibility_rules
            );

            if (visibilityRules) {
                condition = this.applyVisibilityConditionSection(
                    component.advance_validation_rules.visibility_rules,
                    component,
                    isApplicationSubmitted
                );
            }
        }
        return condition;
    },
    advancedConditionsVisibilityTabSection(
        currentCondition,
        component,
        isEditMood,
        allTabs,
        allSections,
        isAdmin,
        isApplicationSubmitted
    ) {
        if (!isAdmin && component.is_admin_only) {
            return false;
        }
        let condition = !currentCondition;
        if (component.advance_validation_rules) {
            let visibilityRules = this.checkHasRules(
                component.advance_validation_rules.visibility_rules
            );

            if (visibilityRules) {
                condition = this.applyVisibilityConditionTab(
                    component.advance_validation_rules.visibility_rules,
                    isEditMood,
                    allTabs,
                    allSections,
                    component,
                    isApplicationSubmitted
                );
            }
        }

        return condition;
    },
    advancedConditionsEnabilitySection(
        currentCondition,
        component,
        isAdmin,
        isApplicationSubmitted
    ) {
        if (!isAdmin && component.is_admin_only) {
            return false;
        }

        let condition = !currentCondition;
        if (component.advance_validation_rules) {
            let enabilityRules = this.checkHasRules(
                component.advance_validation_rules.enability_rules
            );

            if (enabilityRules) {
                condition = this.applyEnabilityConditionSection(
                    component.advance_validation_rules.enability_rules,
                    component,
                    isApplicationSubmitted,
                    component.crm_data_enability
                );
            }
        }
        return condition;
    },
    advancedConditionsEnabilityTabSection(
        currentCondition,
        component,
        isEditMood,
        allTabs,
        allSections,
        isAdmin,
        isApplicationSubmitted
    ) {
        if (!isAdmin && component.is_admin_only) {
            return false;
        }

        let condition = !currentCondition;
        if (component.advance_validation_rules) {
            let enabilityRules = this.checkHasRules(
                component.advance_validation_rules.enability_rules
            );

            if (enabilityRules) {
                condition = this.applyEnabilityConditionTab(
                    component.advance_validation_rules.enability_rules,
                    isEditMood,
                    allTabs,
                    allSections,
                    component,
                    isApplicationSubmitted
                );
            }
        }
        return condition;
    },
    applyEnabilityConditionSection(rule, component, isApplicationSubmitted, crmData) {
        let allComponents = this.getSectionComponents(component);

        let parentComponent = [];
        if (isApplicationSubmitted === 'No') {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (
                        componentItem.crm_field !== 'applicationSubmitted' &&
                        componentItem.crm_field === ruleItem.parent_field
                    ) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        } else {
            parentComponent = allComponents.filter((componentItem) => {
                let parentComponent = rule.filter((ruleItem) => {
                    if (componentItem.crm_field === ruleItem.parent_field) {
                        return componentItem;
                    }
                });

                if (parentComponent.length > 0) {
                    return parentComponent;
                }
            });
        }

        let conditionArray = [];

        rule.forEach((ruleItem) => {
            let valuesArray = ruleItem.value.split(',');
            let cond = false;
            let ruleAction = ruleItem.action;

            let hasValue = parentComponent.filter((parentComponentItem) => {
                let parentComponentItemValue = parentComponentItem.value;
                return (
                    parentComponentItem.crm_field === ruleItem.parent_field &&
                    ((parentComponentItemValue &&
                        valuesArray.includes(parentComponentItemValue.toString())) ||
                        ((parentComponentItemValue == undefined ||
                            !parentComponentItemValue ||
                            (parentComponentItemValue && parentComponentItemValue.trim() === '')) &&
                            valuesArray.includes('null')))
                );
            });

            if (hasValue && hasValue.length > 0) {
                cond = true;
            }

            if (crmData && crmData.length > 0) {
                let hasCrmValue = crmData.filter((crmDataItem) => {
                    let crmDataItemValue = crmDataItem.crm_value;
                    return (
                        crmDataItem.crm_field === ruleItem.parent_field &&
                        ((crmDataItemValue && valuesArray.includes(crmDataItemValue.toString())) ||
                            ((crmDataItemValue == undefined ||
                                !crmDataItemValue ||
                                (crmDataItemValue && crmDataItemValue.trim() === '')) &&
                                valuesArray.includes('null')))
                    );
                });

                if (hasCrmValue && hasCrmValue.length > 0) {
                    cond = true;
                }
            }

            conditionArray.push({
                operator: ruleItem.operator,
                value: cond,
                action: ruleAction,
            });
        });

        if (conditionArray.length === 0) {
            return 'invalid';
        }

        const groupedByOperator = _.groupBy(conditionArray, 'operator');

        let andOperatorValue = true;
        if (groupedByOperator.AND && groupedByOperator.AND.length > 0) {
            let resultAnd = groupedByOperator.AND[0];
            andOperatorValue = false;

            groupedByOperator.AND.forEach((andCondition) => {
                andOperatorValue = resultAnd.value && andCondition.value;

                resultAnd = {
                    operator: andCondition.operator,
                    value: andOperatorValue,
                };
            });
        }

        let orOperatorValue = true;
        if (groupedByOperator.OR && groupedByOperator.OR.length > 0) {
            let resultOr = groupedByOperator.OR[0];
            orOperatorValue = false;

            groupedByOperator.OR.forEach((orCondition) => {
                orOperatorValue = resultOr.value || orCondition.value;

                resultOr = {
                    operator: orCondition.operator,
                    value: orOperatorValue,
                };
            });
        }

        if (andOperatorValue && orOperatorValue) {
            if (conditionArray[0].action === 'enable') {
                return false;
            }

            if (conditionArray[0].action === 'disable') {
                return true;
            }
        } else if (!andOperatorValue) {
            if (conditionArray[0].action === 'enable') {
                return true;
            }

            if (conditionArray[0].action === 'disable') {
                return false;
            }
        } else if (!orOperatorValue) {
            if (conditionArray[0].action === 'enable') {
                return true;
            }

            if (conditionArray[0].action === 'disable') {
                return false;
            }
        } else {
            return true;
        }
    },
    applyEnabilityCondition(
        rule,
        component,
        isEditMood,
        allTabs,
        allSections,
        isApplicationSubmitted,
        isEditRules,
        crmData
    ) {
        let allComponents = this.getComponents(isEditRules, allTabs, allSections);

        let parentComponent = [];

        if (isApplicationSubmitted === 'No') {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (
                        componentItem.crm_field !== 'applicationSubmitted' &&
                        componentItem.crm_field === ruleItem.parent_field
                    ) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        } else {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (componentItem.crm_field === ruleItem.parent_field) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        }

        let conditionArray = [];

        rule.forEach((ruleItem) => {
            let valuesArray = ruleItem.value.split(',');
            let cond = false;
            let ruleAction = ruleItem.action;

            let hasValue = parentComponent.filter((parentComponentItem) => {
                let parentComponentItemValue = parentComponentItem.value;
                return (
                    parentComponentItem.crm_field === ruleItem.parent_field &&
                    ((parentComponentItemValue &&
                        valuesArray.includes(parentComponentItemValue.toString())) ||
                        ((parentComponentItemValue == undefined ||
                            !parentComponentItemValue ||
                            (parentComponentItemValue && parentComponentItemValue.trim() === '')) &&
                            valuesArray.includes('null')))
                );
            });

            if (hasValue && hasValue.length > 0) {
                cond = true;
            }

            if (crmData && crmData.length > 0) {
                let hasCrmValue = crmData.filter((crmDataItem) => {
                    let crmDataItemValue = crmDataItem.crm_value;
                    return (
                        crmDataItem.crm_field === ruleItem.parent_field &&
                        ((crmDataItemValue && valuesArray.includes(crmDataItemValue.toString())) ||
                            ((crmDataItemValue == undefined ||
                                !crmDataItemValue ||
                                (crmDataItemValue && crmDataItemValue.trim() === '')) &&
                                valuesArray.includes('null')))
                    );
                });

                if (hasCrmValue && hasCrmValue.length > 0) {
                    cond = true;
                }
            }

            conditionArray.push({
                operator: ruleItem.operator,
                value: cond,
                action: ruleAction,
            });
        });

        if (conditionArray.length === 0) {
            return 'invalid';
        }

        const groupedByOperator = _.groupBy(conditionArray, 'operator');

        let andOperatorValue = true;
        if (groupedByOperator.AND && groupedByOperator.AND.length > 0) {
            let resultAnd = groupedByOperator.AND[0];
            andOperatorValue = false;

            groupedByOperator.AND.forEach((andCondition) => {
                andOperatorValue = resultAnd.value && andCondition.value;

                resultAnd = {
                    operator: andCondition.operator,
                    value: andOperatorValue,
                };
            });
        }

        let orOperatorValue = true;
        if (groupedByOperator.OR && groupedByOperator.OR.length > 0) {
            let resultOr = groupedByOperator.OR[0];
            orOperatorValue = false;

            groupedByOperator.OR.forEach((orCondition) => {
                orOperatorValue = resultOr.value || orCondition.value;

                resultOr = {
                    operator: orCondition.operator,
                    value: orOperatorValue,
                };
            });
        }

        if (andOperatorValue && orOperatorValue) {
            if (conditionArray[0].action === 'enable') {
                return false;
            }

            if (conditionArray[0].action === 'disable') {
                return true;
            }
        } else if (!isEditRules && !andOperatorValue) {
            if (conditionArray[0].action === 'enable') {
                return true;
            }

            if (conditionArray[0].action === 'disable') {
                return false;
            }
        } else if (!isEditRules && !orOperatorValue) {
            if (conditionArray[0].action === 'enable') {
                return true;
            }

            if (conditionArray[0].action === 'disable') {
                return false;
            }
        } else if (isEditMood && isEditRules) {
            return 'invalid';
        } else if (isEditRules) {
            return component.is_disable;
        } else {
            return true;
        }
    },

    ///needed
    applyVisibilityConditionTab(
        rule,
        isEditMood,
        allTabs,
        allSections,
        component,
        isApplicationSubmitted
    ) {
        let allComponents = this.getAllComponentsInTab(allTabs);

        let parentComponent = [];

        if (isApplicationSubmitted === 'No') {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (
                        componentItem.crm_field !== 'applicationSubmitted' &&
                        componentItem.crm_field === ruleItem.parent_field
                    ) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        } else {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (componentItem.crm_field === ruleItem.parent_field) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        }

        let conditionArray = [];

        rule.forEach((ruleItem) => {
            let valuesArray = ruleItem.value.split(',');
            let cond = false;
            let ruleAction = ruleItem.action;

            let hasValue = parentComponent.filter((parentComponentItem) => {
                let parentComponentItemValue = parentComponentItem.value;

                return (
                    parentComponentItem.crm_field === ruleItem.parent_field &&
                    ((parentComponentItemValue &&
                        valuesArray.includes(parentComponentItemValue.toString())) ||
                        ((parentComponentItemValue == undefined ||
                            !parentComponentItemValue ||
                            (parentComponentItemValue && parentComponentItemValue.trim() === '')) &&
                            valuesArray.includes('null')))
                );
            });
            if (hasValue && hasValue.length > 0) {
                cond = true;
            }

            if (component.crm_data_visibility && component.crm_data_visibility.length > 0) {
                let hasCrmValue = component.crm_data_visibility.filter((crmDataItem) => {
                    let crmDataItemValue = crmDataItem.crm_value;
                    return (
                        crmDataItem.crm_field === ruleItem.parent_field &&
                        ((crmDataItemValue && valuesArray.includes(crmDataItemValue.toString())) ||
                            ((crmDataItemValue == undefined ||
                                !crmDataItemValue ||
                                (crmDataItemValue && crmDataItemValue.trim() === '')) &&
                                valuesArray.includes('null')))
                    );
                });

                if (hasCrmValue && hasCrmValue.length > 0) {
                    cond = true;
                }
            }

            conditionArray.push({
                operator: ruleItem.operator,
                value: cond,
                action: ruleAction,
            });
        });

        if (conditionArray.length > 0) {
            const groupedByOperator = _.groupBy(conditionArray, 'operator');

            let andOperatorValue = true;
            if (groupedByOperator.AND && groupedByOperator.AND.length > 0) {
                let resultAnd = groupedByOperator.AND[0];
                andOperatorValue = false;

                groupedByOperator.AND.forEach((andCondition) => {
                    andOperatorValue = resultAnd.value && andCondition.value;

                    resultAnd = {
                        operator: andCondition.operator,
                        value: andOperatorValue,
                    };
                });
            }

            let orOperatorValue = true;
            if (groupedByOperator.OR && groupedByOperator.OR.length > 0) {
                let resultOr = groupedByOperator.OR[0];
                orOperatorValue = false;

                groupedByOperator.OR.forEach((orCondition) => {
                    orOperatorValue = resultOr.value || orCondition.value;

                    resultOr = {
                        operator: orCondition.operator,
                        value: orOperatorValue,
                    };
                });
            }
            if (andOperatorValue && orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return true;
                }

                if (conditionArray[0].action === 'hide') {
                    return false;
                }
            }
            if (!andOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            if (!orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            return false;
        }

        return false;
    },
    applyEnabilityConditionTab(
        rule,
        isEditMood,
        allTabs,
        allSections,
        component,
        isApplicationSubmitted
    ) {
        let allComponents = this.getComponents(isEditMood, allTabs, allSections);
        let parentComponent = [];
        if (isApplicationSubmitted === 'No') {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (
                        componentItem.crm_field !== 'applicationSubmitted' &&
                        componentItem.crm_field === ruleItem.parent_field
                    ) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        } else {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (componentItem.crm_field === ruleItem.parent_field) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        }

        let conditionArray = [];

        rule.forEach((ruleItem) => {
            let valuesArray = ruleItem.value.split(',');
            let cond = false;
            let ruleAction = ruleItem.action;

            let hasValue = parentComponent.filter((parentComponentItem) => {
                let parentComponentItemValue = parentComponentItem.value;
                return (
                    parentComponentItem.crm_field === ruleItem.parent_field &&
                    ((parentComponentItemValue &&
                        valuesArray.includes(parentComponentItemValue.toString())) ||
                        ((parentComponentItemValue == undefined ||
                            !parentComponentItemValue ||
                            (parentComponentItemValue && parentComponentItemValue.trim() === '')) &&
                            valuesArray.includes('null')))
                );
            });
            if (hasValue && hasValue.length > 0) {
                cond = true;
            }

            if (component.crm_data_enability && component.crm_data_enability.length > 0) {
                let hasCrmValue = component.crm_data_enability.filter((crmDataItem) => {
                    let crmDataItemValue = crmDataItem.crm_value;
                    return (
                        crmDataItem.crm_field === ruleItem.parent_field &&
                        ((crmDataItemValue && valuesArray.includes(crmDataItemValue.toString())) ||
                            ((crmDataItemValue == undefined ||
                                !crmDataItemValue ||
                                (crmDataItemValue && crmDataItemValue.trim() === '')) &&
                                valuesArray.includes('null')))
                    );
                });

                if (hasCrmValue && hasCrmValue.length > 0) {
                    cond = true;
                }
            }

            conditionArray.push({
                operator: ruleItem.operator,
                value: cond,
                action: ruleAction,
            });
        });

        if (conditionArray.length > 0) {
            const groupedByOperator = _.groupBy(conditionArray, 'operator');

            let andOperatorValue = true;
            if (groupedByOperator.AND && groupedByOperator.AND.length > 0) {
                let resultAnd = groupedByOperator.AND[0];
                andOperatorValue = false;

                groupedByOperator.AND.forEach((andCondition) => {
                    andOperatorValue = resultAnd.value && andCondition.value;

                    resultAnd = {
                        operator: andCondition.operator,
                        value: andOperatorValue,
                    };
                });
            }

            let orOperatorValue = true;
            if (groupedByOperator.OR && groupedByOperator.OR.length > 0) {
                let resultOr = groupedByOperator.OR[0];
                orOperatorValue = false;

                groupedByOperator.OR.forEach((orCondition) => {
                    orOperatorValue = resultOr.value || orCondition.value;

                    resultOr = {
                        operator: orCondition.operator,
                        value: orOperatorValue,
                    };
                });
            }

            if (andOperatorValue && orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return true;
                }

                if (conditionArray[0].action === 'hide') {
                    return false;
                }
            }
            if (!andOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            if (!orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            return false;
        }

        return false;
    },
    applyVisibilityConditionSection(rule, component, isApplicationSubmitted) {
        let parentComponent = [];

        if (isApplicationSubmitted === 'No') {
            parentComponent = component.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (
                        componentItem.crm_field !== 'applicationSubmitted' &&
                        componentItem.crm_field === ruleItem.parent_field
                    ) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        } else {
            parentComponent = component.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (componentItem.crm_field === ruleItem.parent_field) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        }

        let conditionArray = [];

        rule.forEach((ruleItem) => {
            let valuesArray = ruleItem.value.split(',');
            let cond = false;
            let ruleAction = ruleItem.action;

            let hasValue = parentComponent.filter((parentComponentItem) => {
                let parentComponentItemValue = parentComponentItem.value;
                return (
                    parentComponentItem.crm_field === ruleItem.parent_field &&
                    ((parentComponentItemValue &&
                        valuesArray.includes(parentComponentItemValue.toString())) ||
                        ((parentComponentItemValue == undefined ||
                            !parentComponentItemValue ||
                            (parentComponentItemValue && parentComponentItemValue.trim() === '')) &&
                            valuesArray.includes('null')))
                );
            });

            if (hasValue && hasValue.length > 0) {
                cond = true;
            }

            if (component.crm_data_visibility && component.crm_data_visibility.length > 0) {
                let hasCrmValue = component.crm_data_visibility.filter((crmDataItem) => {
                    let crmDataItemValue = crmDataItem.crm_value;
                    return (
                        crmDataItem.crm_field === ruleItem.parent_field &&
                        ((crmDataItemValue && valuesArray.includes(crmDataItemValue.toString())) ||
                            ((crmDataItemValue == undefined ||
                                !crmDataItemValue ||
                                (crmDataItemValue && crmDataItemValue.trim() === '')) &&
                                valuesArray.includes('null')))
                    );
                });

                if (hasCrmValue && hasCrmValue.length > 0) {
                    cond = true;
                }
            }

            conditionArray.push({
                operator: ruleItem.operator,
                value: cond,
                action: ruleAction,
            });
        });

        if (conditionArray.length > 0) {
            const groupedByOperator = _.groupBy(conditionArray, 'operator');

            let andOperatorValue = true;
            if (groupedByOperator.AND && groupedByOperator.AND.length > 0) {
                let resultAnd = groupedByOperator.AND[0];
                andOperatorValue = false;

                groupedByOperator.AND.forEach((andCondition) => {
                    andOperatorValue = resultAnd.value && andCondition.value;

                    resultAnd = {
                        operator: andCondition.operator,
                        value: andOperatorValue,
                    };
                });
            }

            let orOperatorValue = true;
            if (groupedByOperator.OR && groupedByOperator.OR.length > 0) {
                let resultOr = groupedByOperator.OR[0];
                orOperatorValue = false;

                groupedByOperator.OR.forEach((orCondition) => {
                    orOperatorValue = resultOr.value || orCondition.value;

                    resultOr = {
                        operator: orCondition.operator,
                        value: orOperatorValue,
                    };
                });
            }

            if (andOperatorValue && orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return true;
                }

                if (conditionArray[0].action === 'hide') {
                    return false;
                }
            }
            if (!andOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            if (!orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            return false;
        }

        return false;
    },
    applyVisibilityCondition(
        rule,
        isEditMood,
        allTabs,
        allSections,
        component,
        isApplicationSubmitted
    ) {
        let allComponents = this.getComponents(isEditMood, allTabs, allSections);

        let parentComponent = [];

        if (isApplicationSubmitted === 'No') {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (
                        componentItem.crm_field !== 'applicationSubmitted' &&
                        componentItem.crm_field === ruleItem.parent_field
                    ) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        } else {
            parentComponent = allComponents.filter((componentItem) => {
                let hasParentComponent = rule.filter((ruleItem) => {
                    if (componentItem.crm_field === ruleItem.parent_field) {
                        return componentItem;
                    }
                });

                if (hasParentComponent.length > 0) {
                    return hasParentComponent;
                }
            });
        }

        let conditionArray = [];

        rule.forEach((ruleItem) => {
            let valuesArray = ruleItem.value.split(',');
            let cond = false;
            let ruleAction = ruleItem.action;

            let hasValue = parentComponent.filter((parentComponentItem) => {
                let parentComponentItemValue = parentComponentItem.value;
                return (
                    parentComponentItem.crm_field === ruleItem.parent_field &&
                    ((parentComponentItemValue &&
                        valuesArray.includes(parentComponentItemValue.toString())) ||
                        ((parentComponentItemValue == undefined ||
                            !parentComponentItemValue ||
                            (parentComponentItemValue && parentComponentItemValue.trim() === '')) &&
                            valuesArray.includes('null')))
                );
            });

            if (hasValue && hasValue.length > 0) {
                cond = true;
            }

            if (component.crm_data_visibility && component.crm_data_visibility.length > 0) {
                let hasCrmValue = component.crm_data_visibility.filter((crmDataItem) => {
                    let crmDataItemValue = crmDataItem.crm_value;
                    return (
                        crmDataItem.crm_field === ruleItem.parent_field &&
                        ((crmDataItemValue && valuesArray.includes(crmDataItemValue.toString())) ||
                            ((crmDataItemValue == undefined ||
                                !crmDataItemValue ||
                                (crmDataItemValue && crmDataItemValue.trim() === '')) &&
                                valuesArray.includes('null')))
                    );
                });

                if (hasCrmValue && hasCrmValue.length > 0) {
                    cond = true;
                }
            }

            conditionArray.push({
                operator: ruleItem.operator,
                value: cond,
                action: ruleAction,
            });
        });

        if (conditionArray.length > 0) {
            const groupedByOperator = _.groupBy(conditionArray, 'operator');

            let andOperatorValue = true;
            if (groupedByOperator.AND && groupedByOperator.AND.length > 0) {
                let resultAnd = groupedByOperator.AND[0];
                andOperatorValue = false;

                groupedByOperator.AND.forEach((andCondition) => {
                    andOperatorValue = resultAnd.value && andCondition.value;

                    resultAnd = {
                        operator: andCondition.operator,
                        value: andOperatorValue,
                    };
                });
            }

            let orOperatorValue = true;
            if (groupedByOperator.OR && groupedByOperator.OR.length > 0) {
                let resultOr = groupedByOperator.OR[0];
                orOperatorValue = false;

                groupedByOperator.OR.forEach((orCondition) => {
                    orOperatorValue = resultOr.value || orCondition.value;

                    resultOr = {
                        operator: orCondition.operator,
                        value: orOperatorValue,
                    };
                });
            }

            if (andOperatorValue && orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return true;
                }

                if (conditionArray[0].action === 'hide') {
                    return false;
                }
            }
            if (!andOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            if (!orOperatorValue) {
                if (conditionArray[0].action === 'show') {
                    return false;
                }

                if (conditionArray[0].action === 'hide') {
                    return true;
                }
            }
            return false;
        }

        return false;
    },

    checkHasRules(rule) {
        return rule.length > 0 && rule[0].parent_field && rule[0].value && rule[0].action
            ? true
            : false;
    },
};
