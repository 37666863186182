import { HTTP } from './http-common';

export default {
    getEntityTypes() {
        return HTTP.get('common/get-entity-types');
    },
    saveEntityType(data) {
        return HTTP.post('entity-types', data);
    },
    updateEntityType(data, uuid) {
        return HTTP.put(`entity-types/${uuid}`, data);
    },
    deleteEntityType(uuid) {
        return HTTP.delete(`entity-types/${uuid}`);
    },
};
