import { HTTP } from './http-common';

export default {
    getUsers(payload) {
        return HTTP.get(
            `/users?page=${payload.page}&search=${
                payload.filters.keyword == null ? '' : payload.filters.keyword
            }&membership=${payload.filters.membership.map(
                (membership) => membership.value
            )}&role=${payload.filters.role.map((role) => role.value)}`
        );
    },
    updateStatus(payload) {
        const user = { ...payload.user, status: payload.status };
        return HTTP.put(`/user/${user.uuid}/update/status`, user);
    },
    updateRole(payload) {
        const user = { ...payload.user, role: payload.role };
        return HTTP.put(`/user/${user.uuid}/update/role`, user);
    },
};
