<template>
    <v-row>
        <v-col cols="12" xs="12" sm="3" md="3">
            <v-switch
                label="Show Label"
                v-model="componentValues.is_show_label"
                hide-details
                class="mr-4 mt-2"
                dense
            ></v-switch>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
            <v-switch
                label="Allow Insertion"
                v-model="componentValues.is_frontend_insertion"
                hide-details
                class="mr-4 mt-2"
                dense
            ></v-switch>
        </v-col>
    </v-row>
</template>

<script>
import common from '../../../constant/common-data';
export default {
    name: 'Competitor',
    props: {
        dbField: String,
        isShowLabel: Boolean,
        frontEndInsertion: Boolean,
    },
    data() {
        return {
            componentValues: {
                is_show_label: false,
                db_field: null,
                is_frontend_insertion: false,
            },
            options: common.databaseData,
        };
    },
    methods: {
        setInitials() {
            this.componentValues.db_field = 'competitors';
            this.componentValues.is_show_label = this.isShowLabel;
            this.componentValues.is_frontend_insertion = this.frontEndInsertion
                ? this.frontEndInsertion
                : false;
        },
    },
    watch: {
        'componentValues.is_show_label'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.db_field'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_frontend_insertion'() {
            this.$emit('update-values', this.componentValues);
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
