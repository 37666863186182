<template>
    <div class="height100 d-flex justify-center align-center">
        <div>
            <p>Redirecting... Please be patient!</p>
            <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PaymentSuccess',
    mounted() {
        let paymentData = localStorage.getItem('paymentData')
            ? JSON.parse(localStorage.getItem('paymentData'))
            : null;

        if (paymentData) {
            paymentData.status = 'success';
            localStorage.setItem('paymentData', JSON.stringify(paymentData));
            window.location.replace(paymentData.url);
        }
    },
};
</script>
