<template>
    <v-card class="rounded-12 section-card cursor-pointer hover-card" flat outlined>
        <v-card-text class="px-6">
            <p class="caption mb-0">
                {{ itemComponent.component_label }}

                <v-chip v-if="itemComponent.is_hidden" class="ma-2" x-small> Hidden </v-chip>
                <v-chip class="mb-0 float-right" x-small>
                    Ref - {{ itemComponent.component_ref ? itemComponent.component_ref : 'N/A' }}
                </v-chip>
            </p>

            <v-text-field
                v-if="itemComponent.component_type === 'TEXT_FIELD'"
                :value="itemComponent.component_default_value"
                dense
                outlined
                hide-details
            ></v-text-field>

            <v-textarea
                v-if="itemComponent.component_type === 'TEXT_AREA'"
                flat
                outlined
                dense
                :value="itemComponent.component_default_value"
                label="Default Value"
            ></v-textarea>

            <v-select
                v-if="itemComponent.component_type === 'DROP_DOWN'"
                :items="itemComponent.component_data_set"
                filled
                :label="itemComponent.component_label"
                item-text="name"
                item-value="name"
                flat
                outlined
                dense
                value=""
            ></v-select>

            <v-layout row v-if="itemComponent.component_type === 'CHECK_BOX'">
                <v-flex v-for="(item, index) in itemComponent.component_data_set" :key="index">
                    <v-checkbox :input-value="item.default" light :label="item.name"> </v-checkbox>
                </v-flex>
            </v-layout>

            <v-radio-group v-if="itemComponent.component_type === 'RADIO'" row>
                <v-radio
                    v-for="(item, index) in itemComponent.component_data_set"
                    :key="index"
                    :label="`${item.name}`"
                    :value="item.name"
                ></v-radio>
            </v-radio-group>

            <v-text-field
                v-if="itemComponent.component_type === 'DATE'"
                :value="dateFormat(itemComponent.component_default_value)"
                prepend-icon="mdi-calendar"
                dense
                outlined
                hide-details
            >
            </v-text-field>

            <v-text-field
                v-if="itemComponent.component_type === 'TIME'"
                :value="itemComponent.component_default_value"
                prepend-icon="mdi-clock"
                dense
                outlined
                hide-details
            >
            </v-text-field>

            <v-text-field
                v-if="itemComponent.component_type === 'FILE_UPLOAD'"
                prepend-icon="mdi-paperclip"
                dense
                outlined
                hide-details
            >
            </v-text-field>

            <v-text-field
                v-if="itemComponent.component_type === 'DOCUMENT'"
                prepend-icon="mdi-paperclip"
                dense
                outlined
                hide-details
            >
            </v-text-field>

            <div
                v-if="itemComponent.component_type === 'TEXT_EDITOR'"
                v-html="itemComponent.component_default_value"
            ></div>

            <v-select
                v-if="
                    itemComponent.component_type === 'DATABASE' ||
                    itemComponent.component_type === 'CRM_DROP_DOWN' ||
                    itemComponent.component_type === 'COMPETITOR'
                "
                filled
                :label="itemComponent.component_label"
                item-text="name"
                item-value="name"
                flat
                outlined
                dense
                value=""
            ></v-select>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment';
export default {
    name: 'TextFieldComponent',
    props: {
        itemComponent: Object,
        section: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            menu2: false,
        };
    },
    methods: {
        dateFormat(date) {
            return date ? moment(date).format('DD-MM-YYYY') : '';
        },
    },
};
</script>

<style scoped></style>
