export default {
    setDefaultEntity: (state, value) => {
        state.defaultEntity = value;
    },
    addJourney: (state, component) => {
        let journeyTabs = JSON.parse(component);
        if (journeyTabs.length > 0) {
            journeyTabs.map((tab, index) => {
                tab.tab_active = index === 0 ? true : false;
            });
        }
        state.journey = journeyTabs;
    },
    addUuid: (state, uuid) => {
        state.uuid = uuid;
    },
    openEditJourney: (state) => {
        state.openEditJourneyDialog = true;
    },
    closeEditJourney: (state) => {
        state.openEditJourneyDialog = false;
    },
    openEditTabDialog: (state) => {
        state.openEditTabDialog = true;
    },
    closeEditTabDialog: (state) => {
        state.openEditTabDialog = false;
    },
    openEditSectionDialog: (state) => {
        state.openEditSectionDialog = true;
    },
    closeEditSectionDialog: (state) => {
        state.openEditSectionDialog = false;
    },
    changeSelectedTab: (state, value) => {
        state.active_tab = value;
        state.active_section = null;
        state.active_component = null;
    },

    updateActiveSection: (state, value) => {
        state.active_section = value;
        state.active_component = null;
    },

    updateActiveComponent: (state, value) => {
        state.active_component = value;
    },
    addNewTab: (state, params) => {
        state.journey.push(params);
    },
    addNewSection: (state, params) => {
        state.journey[params.tabKey].sections.splice(params.sectionKey + 1, 0, params.sectionData);
    },
    updateTab: (state, params) => {
        state.journey[params.tabKey] = params.tabData;
    },
    updateSection: (state, params) => {
        state.journey[params.tabKey].sections[params.sectionKey] = params.sectionData;
    },
    deleteTab: (state, key) => {
        state.journey.splice(key, 1);
        if (state.journey && state.journey.length === 1) {
            state.journey[0].tab_active = true;
        }
        if (state.journey && state.journey.length > 1) {
            if (key === 0) {
                state.journey[key].tab_active = true;
            } else {
                state.journey[key - 1].tab_active = true;
            }
        }
    },

    deleteSection: (state, params) => {
        state.journey[params.tabKey].sections.splice(params.sectionKey, 1);
    },

    changeTabPosition: (state, params) => {
        let element = state.journey[params.tabKey];
        state.journey.splice(params.tabKey, 1);
        if (params.action === 'up') {
            let newPosition = params.tabKey - 1;
            if (params.tabKey > 0) {
                state.journey.splice(newPosition, 0, element);
            }
        }
        if (params.action === 'down') {
            let newPosition = params.tabKey + 1;
            state.journey.splice(newPosition, 0, element);
        }
    },

    changeSectionPosition: (state, params) => {
        let element = state.journey[params.tabKey].sections[params.sectionKey];
        state.journey[params.tabKey].sections.splice(params.sectionKey, 1);
        if (params.action === 'up') {
            let newPosition = params.sectionKey - 1;
            if (params.sectionKey > 0) {
                state.journey[params.tabKey].sections.splice(newPosition, 0, element);
            }
        }
        if (params.action === 'down') {
            let newPosition = params.sectionKey + 1;
            state.journey[params.tabKey].sections.splice(newPosition, 0, element);
        }
    },

    updateComponentPosition: (state, params) => {
        let element =
            state.journey[params.tabKey].sections[params.sectionKey].components[
                params.componentKey
            ];
        state.journey[params.tabKey].sections[params.sectionKey].components.splice(
            params.componentKey,
            1
        );
        if (params.action === 'up') {
            let newPosition = params.componentKey - 1;
            if (params.componentKey > 0) {
                state.journey[params.tabKey].sections[params.sectionKey].components.splice(
                    newPosition,
                    0,
                    element
                );
            }
        }
        if (params.action === 'down') {
            let newPosition = params.componentKey + 1;
            state.journey[params.tabKey].sections[params.sectionKey].components.splice(
                newPosition,
                0,
                element
            );
        }
    },

    updateComponent: (state, params) => {
        state.journey[params.selectedSection.tabKey].sections[
            params.selectedSection.sectionKey
        ].components[params.selectedComponent] = params.component;
    },

    addComponent: (state, params) => {
        state.journey[params.selectedSection.tabKey].sections[
            params.selectedSection.sectionKey
        ].components[params.selectedComponent] = params.component;

        state.journey[params.selectedSection.tabKey].sections[
            params.selectedSection.sectionKey
        ].components.splice(params.selectedComponent + 1, 0, params.newComponent);
    },
    deleteComponent: (state, params) => {
        state.journey[params.selectedSection.tabKey].sections[
            params.selectedSection.sectionKey
        ].components.splice(params.selectedComponent, 1);
    },
    duplicateComponent: (state, params) => {
        state.journey[params.selectedSection.tabKey].sections[
            params.selectedSection.sectionKey
        ].components.splice(params.selectedComponent + 1, 0, params.component);
    },
    minimizeCurrentComponent(state, params) {
        state.journey[params.tabKey].sections[params.sectionKey].components.forEach((component) => {
            component.hide = true;
        });
    },
    expandCurrentComponent(state, params) {
        state.journey[params.tabKey].sections[params.sectionKey].components[
            params.currentIndex
        ].hide =
            !state.journey[params.tabKey].sections[params.sectionKey].components[
                params.currentIndex
            ].hide;
        state.journey[params.tabKey].sections[params.sectionKey].components.forEach(
            (section, index) => {
                if (index !== params.currentIndex) {
                    section.hide = true;
                }
            }
        );
    },
    updateEntityTypes(state, params) {
        state.entityTypes = params;
    },
    updateDocumentTypes(state, params) {
        state.documentTypes = params;
    },
    setJourneysList(state, journeys) {
        state.journeysList = journeys;
    },
    addNewJourney(state, journey) {
        state.journeysList.push(journey);
    },
    SET_ACTIVE_JOURNEY(state, journey) {
        state.activeJourney = journey;
    },
    setTriggerAlert(state, payload) {
        state.triggerAlert = payload;
    },
};
