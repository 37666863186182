<template>
    <v-container>
        <v-card class="mx-auto rounded-12" flat>
            <v-card-text class="px-6 d-flex">
                <p class="text-h4 secondary-font mt-3">{{ journey.journey_name }}</p>
                <v-btn icon small @click="openEditJourney()">
                    <v-icon color="primary" small> mdi-pencil-outline</v-icon>
                </v-btn>
            </v-card-text>
        </v-card>
        <v-row class="mt-4">
            <v-col cols="12" md="4">
                <v-card class="mx-auto rounded-12" flat>
                    <v-card-text class="px-6 secondary-font">
                        <v-list dense class="journey-list">
                            <v-list-group
                                v-for="(journey, tabKey) in journeyState"
                                :key="tabKey"
                                v-model="journey.tab_active"
                                :value="false"
                                no-action
                                class="custom-list-group"
                                @click="changeSelectedTab(tabKey)"
                            >
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="d-flex align-center mt-1">
                                                <div class="mr-3">
                                                    <v-btn
                                                        x-small
                                                        text
                                                        icon
                                                        color="darkGray"
                                                        :disabled="tabKey === 0"
                                                        @click="changeTabPosition(tabKey, 'up')"
                                                    >
                                                        <v-icon x-small>mdi-arrow-up</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        x-small
                                                        text
                                                        icon
                                                        color="darkGray"
                                                        @click="changeTabPosition(tabKey, 'down')"
                                                    >
                                                        <v-icon x-small>mdi-arrow-down</v-icon>
                                                    </v-btn>
                                                </div>
                                                <span
                                                    v-bind:class="
                                                        journey.tab_active
                                                            ? 'tab_section_active'
                                                            : 'tab_section_inactive'
                                                    "
                                                >
                                                    {{ journey.tab_display_name }}
                                                </span>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <div class="d-flex">
                                            <v-btn icon small @click="editTab(tabKey)">
                                                <v-icon color="primary" small>
                                                    mdi-pencil-outline
                                                </v-icon>
                                            </v-btn>
                                            <v-btn icon small @click="deleteTab(tabKey)">
                                                <v-icon color="primary" small>
                                                    mdi-delete-outline
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-list-item-action>
                                </template>

                                <v-list-item
                                    class="pl-12 custom-list-item"
                                    v-for="(section, sectionKey) in journey.sections"
                                    :key="sectionKey"
                                    link
                                    selectable
                                    @click="changeSelectedSection(tabKey, sectionKey)"
                                >
                                    <v-list-item-title>
                                        <div class="d-flex align-center mt-1">
                                            <div class="mr-3">
                                                <v-btn
                                                    x-small
                                                    text
                                                    icon
                                                    color="darkGray"
                                                    :disabled="sectionKey === 0"
                                                    @click="
                                                        changeSectionPosition(
                                                            tabKey,
                                                            sectionKey,
                                                            'up'
                                                        )
                                                    "
                                                >
                                                    <v-icon x-small>mdi-arrow-up</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    x-small
                                                    text
                                                    icon
                                                    color="darkGray"
                                                    @click="
                                                        changeSectionPosition(
                                                            tabKey,
                                                            sectionKey,
                                                            'down'
                                                        )
                                                    "
                                                >
                                                    <v-icon x-small>mdi-arrow-down</v-icon>
                                                </v-btn>
                                            </div>
                                            <span
                                                :class="getActiveSectionClass(tabKey, sectionKey)"
                                            >
                                                {{ section.section_display_name }}
                                            </span>
                                        </div>
                                    </v-list-item-title>

                                    <v-list-item-action class="mr-6">
                                        <div class="d-flex">
                                            <v-btn
                                                icon
                                                small
                                                @click="editSection(tabKey, sectionKey)"
                                            >
                                                <v-icon color="primary" small>
                                                    mdi-pencil-outline
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                small
                                                @click="deleteSection(tabKey, sectionKey)"
                                            >
                                                <v-icon color="primary" small>
                                                    mdi-delete-outline
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                small
                                                @click="openEditSectionDialog(tabKey, sectionKey)"
                                            >
                                                <v-icon color="primary" small>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-group>
                        </v-list>
                        <v-btn outlined color="primary" @click="openEditTabDialog()" block large>
                            <v-icon left> mdi-plus-circle-outline</v-icon>
                            Add Tab
                        </v-btn>
                    </v-card-text>
                </v-card>
                <v-card class="mx-auto mt-5 rounded-12" v-show="false" flat>
                    <v-card-text class="px-6 secondary-font">
                        <v-btn
                            outlined
                            color="primary"
                            :loading="loading"
                            block
                            large
                            @click="saveJourney()"
                        >
                            <v-icon left>mdi-content-save-outline</v-icon>
                            Save Journey
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-card class="rounded-12" flat :height="height">
                    <v-card-text>
                        <div
                            class="d-flex mb-5"
                            v-for="(itemComponent, index) in sectionComponents"
                            :key="index"
                        >
                            <div class="mr-2 d-flex flex-column">
                                <v-btn
                                    x-small
                                    text
                                    icon
                                    color="darkGray"
                                    :disabled="index === 0"
                                    @click="updateComponentPosition(index, 'up')"
                                >
                                    <v-icon x-small>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn
                                    x-small
                                    text
                                    icon
                                    color="darkGray"
                                    @click="updateComponentPosition(index, 'down')"
                                >
                                    <v-icon x-small>mdi-arrow-down</v-icon>
                                </v-btn>
                            </div>
                            <TextFieldComponent
                                v-if="itemComponent.hide"
                                :itemComponent="itemComponent"
                                v-on:click.native="show(index)"
                            />
                            <JourneyComponentSection
                                v-if="!itemComponent.hide"
                                :selectedSection="selectedSection"
                                :componentData="itemComponent"
                                :selectedComponent="index"
                                :saveJourneyState="saveJourneyState"
                                v-on:after-save-Journey="afterSaveJourney"
                            />
                        </div>
                        <v-btn
                            fixed
                            bottom
                            right
                            color="primary"
                            elevation="3"
                            fab
                            @click="saveJourney"
                        >
                            <v-icon> mdi-content-save-outline</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="journeyState && journeyState.length === 0"
                            outlined
                            color="primary"
                            @click="openEditTabDialog()"
                            block
                            large
                        >
                            <v-icon left> mdi-plus-circle-outline</v-icon>
                            Create Your First Tab
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <edit-tab
                v-if="openTabDialog"
                :selectedTab="selectedTab"
                @close-dialog="closeEditTab"
            />
            <edit-section
                v-if="openSectionDialog"
                :tabKey="tabKey"
                :sectionKey="sectionKey"
                :addSectionAfter="addSectionAfter"
                @close-section="closeSectionEdit"
            />
            <edit-journey-dialog
                v-if="openJourneyDialog"
                :journeyData="journey"
                :journeyCount="journeyCount"
                @close-edit-journey-dialog="closeEditJourney"
            />
            <v-snackbar
                v-model="saveSuccess"
                bottom
                color="success"
                right
                text
                class="secondary-font"
            >
                save success

                <template v-slot:action="{ attrs }">
                    <v-btn color="success" icon v-bind="attrs" @click="saveSuccess = false">
                        <v-icon> mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <delete-alert ref="confirm" />
            <validation-popup
                v-if="openErrorDialog"
                :openDialog="openErrorDialog"
                @close-error-dialog="closeErrorDialg"
                :errorList="componentErrorList"
            />
        </v-row>
    </v-container>
</template>

<script>
import JourneyComponentSection from '../../components/journey/EditJourneyComponent';
import EditTab from '../../components/journey/EditTabComponent';
import TextFieldComponent from '../../components/journey/items/TextFieldComponent';
import EditSection from '../../components/journey/EditSectionComponent';
import store from '../../store/index';
import JourneyService from '../../services/journey-service';
import EditJourneyDialog from '../../components/journey/EditJourneyPopup.component';
import DeleteAlert from '../../components/alert/ConfirmAlert.componet';
import notificationsUtility from '../../utilities/notifications-utility';
import ValidationPopup from '../../components/journey/ValidationPopup.component';
import common from '../../constant/common-data';
import commonService from '../../services/common-service';

export default {
    store,
    name: 'EditJourney',
    components: {
        EditTab,
        JourneyComponentSection,
        EditSection,
        TextFieldComponent,
        EditJourneyDialog,
        DeleteAlert,
        ValidationPopup,
    },
    data() {
        return {
            journeyUuid: null,
            loading: false,
            saveSuccess: false,
            openTabDialog: false,
            openSectionDialog: false,
            openJourneyDialog: false,
            journey: {
                journey_name: '',
            },
            sectionComponents: [],
            selectedTab: null,
            selectedSection: {
                tabKey: null,
                sectionKey: null,
            },
            tabKey: null,
            sectionKey: null,
            addSectionAfter: null,
            openEditTab: false,
            valid: true,
            formData: {},
            height: '100%',
            saveJourneyState: false,
            openedComponent: false,
            componentErrorList: [],
            openErrorDialog: false,
            journeyCount: 0,
        };
    },
    mounted() {
        this.getJourneyCount();
        this.getJourneyDetails();
        this.getEntityTypes();
        this.getDocumentTypes();
    },
    methods: {
        closeErrorDialg() {
            this.openErrorDialog = false;
        },

        getActiveSectionClass(tabKey, sectionKey) {
            if (
                this.selectedSection.tabKey === tabKey &&
                this.selectedSection.sectionKey === sectionKey
            ) {
                return 'tab_section_active';
            } else {
                return 'tab_section_inactive';
            }
        },
        openEditJourney() {
            this.openJourneyDialog = true;
            this.$store.commit('journey/openEditJourney');
        },
        closeEditJourney(params) {
            if (params) {
                this.journey = params;
            }
            this.openJourneyDialog = false;
            this.$store.commit('journey/closeEditJourney');
        },
        show(currentIndex) {
            this.$store.commit('journey/expandCurrentComponent', {
                ...this.selectedSection,
                currentIndex,
            });
            this.openedComponent = true;
        },
        getEntityTypes() {
            this.$store.dispatch('journey/getEntityTypes');
        },
        getDocumentTypes() {
            this.$store.dispatch('journey/getDocumentTypes');
        },
        getJourneyDetails() {
            this.journeyUuid = this.$route.params.id;
            JourneyService.getJourneyByUuid(this.journeyUuid)
                .then((response) => {
                    if (response.data.data) {
                        this.journey = response.data.data;
                        this.$store.commit('journey/addUuid', response.data.data.uuid);
                        this.$store.commit('journey/addJourney', response.data.data.form_data);
                        this.$store.commit(
                            'journey/setDefaultEntity',
                            response.data.data.entity_type
                        );
                        this.setInitials();
                    }
                })
                .catch(() => {});
        },
        setInitials() {
            this.selectedSection.tabKey = 0;
            this.selectedSection.sectionKey = 0;
            this.sectionComponents = this.journeyState
                ? this.journeyState[0].sections[0].components
                : [];
        },

        changeSelectedSection(tabKey, sectionKey) {
            this.selectedSection.tabKey = tabKey;
            this.selectedSection.sectionKey = sectionKey;
            this.sectionComponents = this.journeyState[tabKey].sections[sectionKey].components;
            this.$store.commit('journey/minimizeCurrentComponent', this.selectedSection);
            this.openedComponent = false;
        },
        openEditSectionDialog(tabKey, sectionKey) {
            this.openSectionDialog = true;
            this.tabKey = tabKey;
            this.addSectionAfter = sectionKey;
            this.$store.commit('journey/openEditSectionDialog');
        },
        openEditTabDialog() {
            this.openTabDialog = true;
            this.$store.commit('journey/openEditTabDialog');
        },
        editTab(key) {
            this.selectedTab = key;
            this.openTabDialog = true;
            this.$store.commit('journey/openEditTabDialog');
        },
        closeEditTab() {
            this.openTabDialog = false;
            this.selectedTab = null;
            if (this.journeyState.length === 1) {
                this.sectionComponents = this.journeyState
                    ? this.journeyState[0].sections[0].components
                    : [];
            }
        },
        closeSectionEdit(value) {
            if (value === 'success') {
                this.changeSelectedSection(
                    this.selectedSection.tabKey,
                    this.selectedSection.sectionKey + 1
                );
            }
            this.openSectionDialog = false;
            this.tabKey = null;
            this.sectionKey = null;
            this.addSectionAfter = null;
        },

        deleteTab(key) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this tab ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        this.deleteCurrentTab(key);
                    }
                });
        },

        deleteCurrentTab(key) {
            this.$store.commit('journey/deleteTab', key);
            if (this.journeyState && this.journeyState.length === 0) {
                this.sectionComponents = [];
            }
            if (this.journeyState && this.journeyState.length === 1) {
                this.sectionComponents = this.journeyState[0].sections[0].components;
                this.selectedSection.tabKey = 0;
                this.selectedSection.sectionKey = 0;
            }
            if (this.journeyState && this.journeyState.length > 1) {
                if (key === 0) {
                    this.sectionComponents = this.journeyState[key].sections[0].components;
                    this.selectedSection.tabKey = key;
                } else {
                    this.sectionComponents = this.journeyState[key - 1].sections[0].components;
                    this.selectedSection.tabKey = this.selectedSection.tabKey - 1;
                }
                this.selectedSection.sectionKey = 0;
            }
        },

        deleteSection(tabKey, sectionKey) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this section ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        this.$store.commit('journey/deleteSection', { tabKey, sectionKey });
                        if (this.journeyState[tabKey].sections.length === 0) {
                            this.deleteCurrentTab(tabKey);
                        }
                        if (
                            this.journeyState[tabKey] &&
                            this.journeyState[tabKey].sections.length === 1
                        ) {
                            this.sectionComponents =
                                this.journeyState[tabKey].sections[0].components;
                            this.selectedSection.sectionKey = 0;
                        }
                        if (
                            this.journeyState[tabKey] &&
                            this.journeyState[tabKey].sections.length > 1
                        ) {
                            if (sectionKey === 0) {
                                this.sectionComponents =
                                    this.journeyState[tabKey].sections[sectionKey].components;
                            } else {
                                this.sectionComponents =
                                    this.journeyState[tabKey].sections[sectionKey - 1].components;
                                this.selectedSection.sectionKey =
                                    this.selectedSection.sectionKey - 1;
                            }
                        }
                    }
                });
        },

        editSection(tabKey, sectionKey) {
            this.openSectionDialog = true;
            this.tabKey = tabKey;
            this.sectionKey = sectionKey;
            this.$store.commit('journey/openEditSectionDialog');
        },

        changeTabPosition(tabKey, action) {
            this.$store.commit('journey/changeTabPosition', { tabKey, action });
        },

        changeSectionPosition(tabKey, sectionKey, action) {
            this.$store.commit('journey/changeSectionPosition', { tabKey, sectionKey, action });
        },

        updateComponentPosition(componentKey, action) {
            this.$store.commit('journey/updateComponentPosition', {
                componentKey,
                action,
                ...this.selectedSection,
            });
        },

        changeSelectedTab(key) {
            this.selectedSection.tabKey = key;
            this.selectedSection.sectionKey = 0;
            this.sectionComponents = this.journeyState[key].sections[0].components;
            this.$store.commit('journey/minimizeCurrentComponent', this.selectedSection);
            this.openedComponent = false;
        },
        checkRequiredFields() {
            if (this.journeyState.length > 0) {
                let errorList = [];
                this.journeyState.map((tab) => {
                    tab.sections.map((section) => {
                        let failedComps = [];
                        section.components.map((component) => {
                            let checkAdvance = true;
                            let checkcrm = true;
                            if (
                                component.is_advance_condition === true &&
                                common.inputTypes.find(
                                    (item) => item.value === component.component_type
                                ).validation_allow === true
                            ) {
                                checkAdvance = this.checkAdvanceCondition(
                                    component.advance_validation_rules
                                );
                            }
                            checkcrm = this.checkCrm(component);
                            if (
                                component.component_label === null ||
                                component.component_label === '' ||
                                checkcrm === false ||
                                checkAdvance === false
                            ) {
                                failedComps.push(
                                    component.component_name + ` (Ref : ${component.component_ref})`
                                );
                            }
                        });
                        if (failedComps.length > 0) {
                            if (!errorList.hasOwnProperty(tab.tab_display_name)) {
                                errorList[tab.tab_display_name] = [];
                            }
                            let obj = {
                                section: section.section_display_name,
                                components: failedComps,
                            };
                            errorList[tab.tab_display_name].push(obj);
                        }
                    });
                });
                this.componentErrorList = { ...errorList };
            }
        },
        checkCrm(component) {
            if (this.currentUser.role[0].name === 'ADMIN') {
                switch (component.component_type) {
                    case 'DOCUMENT':
                        return component.document_type === null ||
                            component.document_type === '' ||
                            (common.inputTypes.find(
                                (item) => item.value === component.component_type
                            ).crm_required === true &&
                                (component.component_crm_field === null ||
                                    component.component_crm_field === ''))
                            ? false
                            : true;
                        break;

                    case 'FILE_UPLOAD':
                        return component.allowed_file_types.length === 0 ||
                            (common.inputTypes.find(
                                (item) => item.value === component.component_type
                            ).crm_required === true &&
                                (component.component_crm_field === null ||
                                    component.component_crm_field === ''))
                            ? false
                            : true;
                        break;

                    case 'CRM_DROP_DOWN':
                        return component.crm_drop_down.entity === null ||
                            component.entity === '' ||
                            component.crm_drop_down.value_field === null ||
                            component.value_field === '' ||
                            component.crm_drop_down.display_field === null ||
                            component.display_field === '' ||
                            (common.inputTypes.find(
                                (item) => item.value === component.component_type
                            ).crm_required === true &&
                                (component.component_crm_field === null ||
                                    component.component_crm_field === ''))
                            ? false
                            : true;
                        break;

                    default:
                        return common.inputTypes.find(
                            (item) => item.value === component.component_type
                        ).crm_required === true &&
                            (component.component_crm_field === null ||
                                component.component_crm_field === '')
                            ? false
                            : true;
                        break;
                }
            } else {
                return true;
            }
        },
        checkAdvanceCondition(conditions) {
            const rules = ['visibility_rules', 'edit_rules', 'enability_rules', 'validation_rules'];

            const data = rules.every((element) => {
                if (element !== 'validation_rules') {
                    if (conditions[element].length > 0) {
                        return conditions[element].every((item) => {
                            if (item['action'] === null || item['action'] === '') return false;
                            if (item['parent_field'] === null || item['parent_field'] === '')
                                return false;
                            if (item['value'] === null || item['value'] === '') return false;

                            if (item['operator'] === null || item['operator'] === '') return false;
                            else return true;
                        });
                    }
                } else {
                    if (
                        (conditions[element]['value'] === null ||
                            conditions[element]['value'] === '') &&
                        conditions[element]['type'] !== null &&
                        conditions[element]['type'] !== '' &&
                        !['pastDate', 'futureDate'].includes(conditions[element]['type'])
                    )
                        return false;
                    if (
                        conditions[element]['value'] !== null &&
                        conditions[element]['value'] !== '' &&
                        ((conditions[element]['type'] === null &&
                            conditions[element]['type'] === '') ||
                            (conditions[element]['field_type'] === null &&
                                conditions[element]['field_type'] === null))
                    )
                        return false;
                }

                return true;
            });

            return data;
        },
        saveJourneyFromMain() {
            this.checkRequiredFields();
            if (Object.keys(this.componentErrorList).length > 0) {
                this.openErrorDialog = true;
            } else {
                this.loading = true;
                const payload = {
                    formData: this.journeyState,
                    uuid: this.journeyUuid,
                };
                this.$store.dispatch('journey/saveJourneyData', payload).then(
                    () => {
                        this.loading = false;
                        notificationsUtility.pushNotifications([
                            { message: 'Journey saved successfully', type: 'success' },
                        ]);
                    },
                    (error) => {}
                );
            }
        },
        saveJourney() {
            if (this.openedComponent) {
                this.saveJourneyState = !this.saveJourneyState;
            } else {
                this.saveJourneyFromMain();
            }
        },
        afterSaveJourney(value) {
            if (value === 'saved') {
                this.openedComponent = false;
                this.saveJourneyState = false;
                this.saveJourneyFromMain();
            }
            if (value === 'deleted') {
                this.openedComponent = false;
                this.saveJourneyState = false;
            }
            if (value === 'saved-failed') {
                this.saveJourneyState = false;
            }
        },
        async getJourneyCount() {
            await commonService.getSiteSpecificJourneyCount().then((response) => {
                this.journeyCount = response.data.data;
            });
        },
    },
    computed: {
        journeyState: () => {
            return store.state.journey.journey;
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        isAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return ['ADMIN'].includes(this.currentUser.role[0].name);
            }

            return false;
        },
    },
};
</script>
