<template>
    <div class="my-4">
        <quill-editor :content="content" :options="editorOption" @change="onEditorChange($event)" />
        <v-switch label="Admin Only" hide-details class="mt-4"></v-switch>
    </div>
</template>

<script>
export default {
    name: 'TextEditor',
    data() {
        return {
            content: '<h2>I am Example</h2>',
            editorOption: {},
        };
    },
    methods: {
        onEditorChange({ quill, html, text }) {
            this.content = html;
        },
    },
};
</script>
