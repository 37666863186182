<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="secondary-font pb-0">
            <v-row>
                <v-col cols="6">
                    <p class="caption mb-0">Module</p>
                    <v-combobox
                        solo
                        flat
                        outlined
                        dense
                        :items="systemMessageModules"
                        item-text="text"
                        v-model="systemMessage.module"
                        :rules="[validations.required]"
                    ></v-combobox>
                </v-col>
                <v-col cols="6">
                    <p class="caption mb-0">Sub Module</p>
                    <v-combobox
                        solo
                        flat
                        outlined
                        dense
                        :items="systemMessageSubModules"
                        item-text="text"
                        v-model="systemMessage.sub_module"
                        :rules="[validations.required]"
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Message Type</p>
                    <v-text-field v-model="systemMessage.type" dense outlined readonly>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Name</p>
                    <v-text-field
                        v-model="systemMessage.name"
                        dense
                        outlined
                        :rules="[validations.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="caption mb-0">Text</p>
                    <v-textarea
                        v-model="systemMessage.message"
                        dense
                        outlined
                        rows="2"
                        :rules="[validations.required]"
                    >
                    </v-textarea>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary mr-2" @click="handleFormSubmit" elevation="0"> Save</v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
import { systemMessageModules } from '../../utilities/constants-utility';
import validations from '../../utilities/validations-utility';
export default {
    name: 'SystemMessageForm',
    props: ['systemMessage', 'handleSave'],
    data() {
        return {
            validations,
            systemMessageModules,
            valid: true,
            quillOptions: {
                placeholder: null,
            },
        };
    },
    computed: {
        systemMessageSubModules() {
            return this.systemMessage.module ? this.systemMessage.module.subModules : [];
        },
        systemMessageType() {
            return this.systemMessage.sub_module ? this.systemMessage.sub_module.type : null;
        },
    },
    watch: {
        systemMessageSubModules() {
            if (this.systemMessageSubModules && this.systemMessage.sub_module) {
                const current = this.systemMessageSubModules.find((submodule) => {
                    return submodule.value === this.systemMessage.sub_module.value;
                });
                this.systemMessage.sub_module = current ? current : this.systemMessageSubModules[0];
            } else {
                this.systemMessage.sub_module = this.systemMessageSubModules
                    ? this.systemMessageSubModules[0]
                    : [];
            }
        },
        systemMessageType() {
            this.systemMessage.type = this.systemMessage.sub_module
                ? this.systemMessage.sub_module.type
                : null;
        },
        systemMessage() {
            this.$refs.form.resetValidation();
        },
    },
    methods: {
        handleFormSubmit() {
            if (this.$refs.form.validate()) {
                this.handleSave(this.systemMessage);
            }
        },
    },
};
</script>
