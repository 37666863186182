<template>
    <v-dialog :value="dialog" :width="dialogWidth" @click:outside="resetForm">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 primary--text font-weight-black mb-0">Edit Email Notification</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="resetForm">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <EmailNotificationForm :handleSave="handleSave" :notification="formData" />
        </v-card>
    </v-dialog>
</template>

<script>
import EmailNotificationForm from './EmailNotificationForm.component.vue';
import { emailModules } from '../../utilities/constants-utility';
import NotificationService from '../../services/notification-service';
import notificationsUtility from '../../utilities/notifications-utility';

export default {
    name: 'EditEmailNotificationPopUp',
    components: {
        EmailNotificationForm,
    },
    props: ['emailTemplate', 'handleClose'],
    data() {
        return {
            dialogWidth: 1000,
            formData: {
                module: null,
                name: null,
                sub_module: null,
                subject: null,
                email_body: null,
                signature: null,
            },
        };
    },
    methods: {
        handleSave() {
            NotificationService.editEmailFormat({
                ...this.formData,
                module: this.formData.module.value,
                sub_module: this.formData.sub_module.value,
            })
                .then((response) => {
                    this.$store.commit('notification/editEmailFormat', response.data.data);
                    this.resetForm();
                    notificationsUtility.showNotifications(response.data.meta);
                })
                .catch((error) => {
                    notificationsUtility.showNotifications(error.response.data);
                });
        },
        resetForm() {
            Object.assign(this.$data, this.$options.data());
            this.handleClose();
        },
    },
    watch: {
        emailTemplate() {
            if (this.emailTemplate) {
                this.formData = { ...this.emailTemplate };
                this.formData.module = emailModules.find(
                    (module) => this.emailTemplate.module === module.value
                );
                const subModules = ['APPLICATION', 'PAYMENT'].includes(this.formData.module.value)
                    ? this.journeySubModules
                    : this.formData.module.subModules;
                this.formData.sub_module = subModules.find(
                    (subModule) => this.emailTemplate.sub_module === subModule.value
                );
            } else {
                Object.assign(this.$data, this.$options.data());
            }
        },
    },
    computed: {
        dialog() {
            return this.emailTemplate && this.emailTemplate.uuid;
        },
        journeySubModules() {
            return this.$store.getters['journey/journeySubModules'];
        },
    },
};
</script>
