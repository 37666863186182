<template>
    <div>
        <v-btn
            role="link"
            @click="
                if (component.component_default_value) {
                    openInNewTab(component.component_default_value, component.is_open_on_tab);
                }
            "
            :color="component.button_color ? component.button_color.hex : 'primary'"
        >
            {{ component.component_label }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'ButtonComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
        isNewTab: {
            type: Boolean,
            default: true,
        },
        isButtonColor: {
            type: String,
            default: 'primary',
        },
    },
    data() {
        return {};
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
        isNewTab() {
            this.appearanceInputChanged();
        },
        isButtonColor() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        openInNewTab(url, newTab) {
            url = url.replace(/'/g, ``);
            if (newTab) {
                window.open(url, '_blank', 'noreferrer');
            } else {
                window.open(url, '_self');
            }
        },
        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.$refs[this.component.component_name]['internalValue'],
                is_disable: this.enability,
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.$refs[this.component.component_name]['internalValue'],
                is_open_on_tab: this.isNewTab,
                button_color: this.isButtonColor,
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },
};
</script>
