<template>
    <v-container>
        <h1 class="primary--text">Manage Users</h1>
        <v-card class="mx-auto rounded-lg mt-5" elevation="0">
            <v-card-text class="secondary-font pa-3">
                <v-row>
                    <v-col cols="12" xs="12" sm="3">
                        <p class="caption mb-0">Membership</p>
                        <v-combobox
                            solo
                            flat
                            outlined
                            dense
                            multiple
                            :items="memberships"
                            item-text="text"
                            v-model="filters.membership"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3">
                        <p class="caption mb-0">Role Status</p>
                        <v-combobox
                            solo
                            flat
                            outlined
                            dense
                            multiple
                            :items="roles"
                            item-text="text"
                            v-model="filters.role"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" xs="12" md="4" xl="5">
                        <v-text-field
                            label="Search ..."
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            prepend-inner-icon="mdi-magnify"
                            class="mt-5"
                            v-model="filters.keyword"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" md="2" xl="1">
                        <v-btn
                            color="secondary"
                            class="mt-5"
                            dark
                            elevation="0"
                            @click="
                                () => {
                                    this.page = 1;
                                    getUsers();
                                }
                            "
                            >Search</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mx-auto rounded-lg mt-6" elevation="0">
            <v-card-text class="secondary-font pa-3">
                <v-data-table
                    :headers="headers"
                    :items="filteredUsers"
                    class="elevation-0 noborder-table body-primary"
                    hide-default-footer
                    :page.sync="page"
                >
                    <template v-slot:[`item.status`]="{ item }">
                        <v-switch
                            :input-value="isActive(item)"
                            @click="updateUserStatus(item)"
                        ></v-switch>
                    </template>
                    <template v-slot:[`item.role`]="{ item }">
                        <v-select
                            :items="roles"
                            :value="item.role[0].name"
                            outlined
                            dense
                            :disabled="!isActive(item)"
                            :input-value="isAdmin(item)"
                            @change="(value) => updateUserRole(item, value)"
                            hide-details
                        ></v-select>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn
                            icon
                            v-if="isAdmin(user) && item.uuid !== user.uuid"
                            color="secondary"
                            dark
                            elevation="0"
                            @click="changePassword(item)"
                        >
                            <v-icon>mdi-lock</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            v-if="isAdmin(user) && item.uuid !== user.uuid"
                            color="secondary"
                            dark
                            elevation="0"
                            @click="changeEmail(item)"
                        >
                            <v-icon>mdi-email</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="
                                isAdmin(user) &&
                                item.status &&
                                isStudent(item) &&
                                item.uuid !== user.uuid
                            "
                            :disabled="item.contact_id === loggedAsUser || !isActive(item)"
                            color="secondary"
                            dark
                            @click="setLogAs(item)"
                            class="text-capitalize"
                            elevation="0"
                        >
                            Log as
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <div class="d-flex justify-end mt-2" v-if="pageCount">
            <v-pagination
                v-model="page"
                :length="pageCount"
                class="secondary-font"
                :total-visible="10"
            ></v-pagination>
        </div>
        <confirm-alert ref="confirm" />
        <v-dialog
            :value="changePasswordUser"
            :max-width="dialogMaxWidth"
            persistent
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center secondary-font">
                    <p class="h6 primary--text font-weight-bold mb-0">Reset Password</p>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" text icon color="primary" @click="closechangePasswordPopup">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text
                    ><v-form ref="form" class="transparent-inputs">
                        <v-container>
                            <v-row>
                                <v-col cols="12 my-0 py-0">
                                    <v-text-field
                                        type="password"
                                        label="New Password"
                                        solo
                                        flat
                                        outlined
                                        dense
                                        validate-on-blur
                                        name="password"
                                        v-model="formData.password"
                                        :rules="[validations.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12 my-0 py-0">
                                    <v-text-field
                                        type="password"
                                        label="Confirm New Password"
                                        solo
                                        flat
                                        outlined
                                        dense
                                        validate-on-blur
                                        name="password_confirmation"
                                        v-model="formData.password_confirmation"
                                        :rules="[validations.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-btn
                                color="secondary"
                                @click="resetPassword"
                                elevation="0"
                                class="text-capitalize mt-6"
                                large
                                block
                            >
                                Reset Password
                            </v-btn>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            :value="changeEmailUser"
            :max-width="dialogMaxWidth"
            persistent
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center secondary-font">
                    <p class="h6 primary--text font-weight-bold mb-0">Reset Email</p>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" text icon color="primary" @click="closechangeEmailPopup">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text
                    ><v-form ref="form" class="transparent-inputs">
                        <v-container>
                            <v-row>
                                <v-col cols="12 my-0 py-0">
                                    <v-text-field
                                        type="email"
                                        label="New Email"
                                        solo
                                        flat
                                        outlined
                                        dense
                                        validate-on-blur
                                        name="email"
                                        v-model="formData.email"
                                        :rules="[validations.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12 my-0 py-0">
                                    <v-text-field
                                        type="email"
                                        label="Confirm New Email"
                                        solo
                                        flat
                                        outlined
                                        dense
                                        validate-on-blur
                                        name="email_confirmation"
                                        v-model="formData.email_confirmation"
                                        :rules="[validations.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-btn
                                color="secondary"
                                @click="resetEmail"
                                elevation="0"
                                class="text-capitalize mt-6"
                                large
                                block
                            >
                                Reset Email
                            </v-btn>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { roles, memberships } from '../../utilities/constants-utility';
import ConfirmAlert from '../../components/alert/ConfirmAlert.componet';
import validations from '../../utilities/validations-utility';
import authService from '../../services/auth-service';
import notificationsUtility from '../../utilities/notifications-utility';
import userService from '../../services/user-service';

export default {
    name: 'Payment',
    components: { ConfirmAlert },
    data() {
        return {
            headers: [
                { text: 'Name', value: 'name', sortable: false },
                { text: 'E - mail', value: 'email', sortable: false },
                { text: 'Membership', value: 'status', sortable: false },
                { text: 'Role', value: 'role', sortable: false },
                { text: '', value: 'action', sortable: false },
            ],
            formData: {
                password: null,
                password_confirmation: null,
                email: null,
                email_confirmation: null,
            },
            roles,
            memberships,
            filters: {
                role: [],
                membership: [],
                keyword: '',
            },
            loggedAsUser: null,
            page: 1,
            pageCount: 0,
            changePasswordUser: null,
            changeEmailUser: null,
            dialogMaxWidth: '500px',
            validations,
            filteredUsers: [],
        };
    },
    methods: {
        getColor(status) {
            if (status == 'pending') return 'orange';
            else return 'green';
        },
        isAdmin(user) {
            return user.role.filter((role) => role.name === 'ADMIN').length > 0;
        },
        isStudent(user) {
            return user.role.filter((role) => role.name === 'STUDENT').length > 0;
        },
        isActive(user) {
            return user.status === 'ACTIVE';
        },
        updateUserStatus(user) {
            const status = this.isActive(user) ? 'INACTIVE' : 'ACTIVE';
            this.$store.dispatch('user/updateStatus', { user, status });
        },
        updateUserRole(user, role) {
            this.$store.dispatch('user/updateRole', { user, role });
        },
        setLogAs(user) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to log as this user ?', {
                    color: 'primary',
                })
                .then((confirm) => {
                    if (confirm) {
                        localStorage.removeItem('log_as_user');
                        localStorage.setItem('log_as_user', JSON.stringify(user));
                        localStorage.setItem('log_as_user_status', 'true');
                        this.loggedAsUser = user.contact_id;
                        location.reload();
                    }
                });
        },
        changePassword(selectedUser) {
            this.changePasswordUser = selectedUser;
        },
        changeEmail(selectedUser) {
            this.changeEmailUser = selectedUser;
        },
        closechangeEmailPopup() {
            this.changeEmailUser = null;
            this.formData = {
                email: null,
                email_confirmation: null,
                uuid: null,
            };
        },
        closechangePasswordPopup() {
            this.changePasswordUser = null;
            this.formData = {
                password: null,
                password_confirmation: null,
            };
        },
        resetEmail() {
            if (this.$refs.form.validate()) {
                this.$refs.confirm
                    .open(
                        'Are you sure',
                        `Do you want to reset ${this.changeEmailUser.name}'s email ?`,
                        {
                            color: 'primary',
                        }
                    )
                    .then((confirm) => {
                        if (confirm) {
                            authService
                                .changeUserEmail({
                                    ...this.formData,
                                    uuid: this.changeEmailUser.uuid,
                                    current_email: this.changeEmailUser.email,
                                })
                                .then((response) => {
                                    notificationsUtility.showNotifications(response.data);
                                    this.closechangeEmailPopup();
                                })
                                .catch((error) => {
                                    notificationsUtility.showNotifications(error.response.data);
                                });
                        }
                    });
            }
        },
        resetPassword() {
            if (this.$refs.form.validate()) {
                this.$refs.confirm
                    .open(
                        'Are you sure',
                        `Do you want to reset ${this.changePasswordUser.name}'s password ?`,
                        {
                            color: 'primary',
                        }
                    )
                    .then((confirm) => {
                        if (confirm) {
                            authService
                                .changeUserPassword({
                                    ...this.formData,
                                    email: this.changePasswordUser.email,
                                })
                                .then((response) => {
                                    notificationsUtility.showNotifications(response.data);
                                    this.closechangePasswordPopup();
                                })
                                .catch((error) => {
                                    notificationsUtility.showNotifications(error.response.data);
                                });
                        }
                    });
            }
        },
        getUsers() {
            userService.getUsers({ filters: this.filters, page: this.page }).then((response) => {
                this.filteredUsers = response.data.data;
                this.pageCount = response.data.pageCount;
            });
        },
    },
    mounted() {
        this.filters.role = this.roles;
        this.filters.membership = this.memberships;
        this.getUsers();
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },
    watch: {
        page(value) {
            this.getUsers({ filters: this.filters, page: value });
        },
    },
    created() {
        this.loggedAsUser =
            localStorage.getItem('log_as_user_status') &&
            localStorage.getItem('log_as_user_status') === 'true'
                ? JSON.parse(localStorage.getItem('log_as_user')).contact_id
                : null;
    },
};
</script>
