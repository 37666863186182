exports.roles = [
    { text: 'Admin', value: 'ADMIN' },
    { text: 'Editor', value: 'EDITOR' },
    { text: 'Applicant', value: 'STUDENT' },
];

exports.memberships = [
    { text: 'Active', value: 'ACTIVE' },
    { text: 'Removed', value: 'INACTIVE' },
];

exports.emailModules = [
    {
        text: 'User Management',
        value: 'USER_MANAGEMENT',
        subModules: [{ text: 'Assign Admin Role', value: 'ASSIGN_ADMIN_ROLE' }],
    },
    {
        text: 'Authentication',
        value: 'AUTHENTICATION',
        subModules: [
            { text: 'Email Verification', value: 'EMAIL_VERIFICATION' },
            { text: 'Create An Account', value: 'CREATE_AN_ACCOUNT' },
            { text: 'Forgot Password', value: 'FORGOT_PASSWORD' },
        ],
    },
    {
        text: 'Application Submit',
        value: 'APPLICATION',
    },
    {
        text: 'Change Password',
        value: 'CHANGE_PASSWORD',
    },
];

exports.systemMessageModules = [
    {
        text: 'Create Account',
        value: 'CREATE_ACCOUNT',
        subModules: [
            { text: 'Success Message', value: 'CREATE_ACCOUNT_SUCCESS_MESSAGE', type: 'SUCCESS' },
            {
                text: 'User Email Already Exists Error',
                value: 'USER_EMAIL_ALREADY_EXISTS_ERROR',
                type: 'ERROR',
            },
        ],
    },
    {
        text: 'Forgot Password',
        value: 'FORGOT_PASSWORD',
        subModules: [
            {
                text: 'Success Message',
                value: 'FORGOT_PASSWORD_SUCCESS_MESSAGE',
                type: 'SUCCESS',
            },
            { text: 'Wrong Email Address', value: 'WRONG_EMAIL_ADDRESS_ERROR', type: 'ERROR' },
        ],
    },
    {
        text: 'Login',
        value: 'LOGIN',
        subModules: [
            {
                text: 'Invalid Username Or Password Error',
                value: 'INVALID_USERNAME_OR_PASSWORD_ERROR',
                type: 'ERROR',
            },
            {
                text: 'User Membership Status Removed Error',
                value: 'USER_MEMBERSHIP_STATUS_REMOVED_ERROR',
                type: 'ERROR',
            },
            {
                text: 'Session time out message',
                value: 'SESSION_TIME_OUT_MESSAGE',
                type: 'INFO',
            },
        ],
    },
    {
        text: 'Update Personal Data',
        value: 'UPDATE_PERSONAL_DATA',
        subModules: [
            {
                text: 'Success Message',
                value: 'UPDATE_PERSONAL_DATA_SUCCESS_MESSAGE',
                type: 'SUCCESS',
            },
        ],
    },
    {
        text: 'Change email address',
        value: 'CHANGE_EMAIL',
        subModules: [
            {
                text: 'Success Message',
                value: 'CHANGE_EMAIL_SUCCESS_MESSAGE',
                type: 'SUCCESS',
            },
            {
                text: 'Invalid Current email',
                value: 'INVALID_CURRENT_EMAIL',
                type: 'ERROR',
            },
        ],
    },
    {
        text: 'Change Password',
        value: 'CHANGE_PASSWORD',
        subModules: [
            {
                text: 'Success Message',
                value: 'CHANGE_PASSWORD_SUCCESS_MESSAGE',
                type: 'SUCCESS',
            },
            {
                text: 'Invalid Current Password',
                value: 'INVALID_CURRENT_PASSWORD',
                type: 'ERROR',
            },
        ],
    },
];
