import { HTTP } from './http-common';

export default {
    getSites() {
        return HTTP.get('site');
    },
    saveSite(data) {
        return HTTP.post('site', data);
    },
    updateSite(data, uuid) {
        return HTTP.put(`site/${uuid}`, data);
    },
    deleteSite(uuid) {
        return HTTP.delete(`site/${uuid}`);
    },
};
