<template>
    <v-dialog v-model="openDialog" persistent width="600px" height="600px">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 red--text font-weight-black mb-0">Validation Errors</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="secondary-font pb-5">
                <ul class="secondary-font">
                    <li v-for="(item, key) in errorList" :value="key" :key="key">
                        {{ item }}
                    </li>
                </ul>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'FormValidationPopup.component',
    props: {
        openDialog: Boolean,
        errorList: Array,
    },
    data: () => ({}),
    methods: {
        close() {
            this.$emit('close-error-dialog');
        },
    },
};
</script>

<style scoped></style>
