import { HTTP } from './http-common';

export default {
    saveProfileForm(data) {
        return HTTP.post('profile/update-form-data', data);
    },
    getCreateProfileForm() {
        return HTTP.get('profile/form-data');
    },
    getUserProfileData() {
        return HTTP.get('profile/edit-profile');
    },
    updateProfileData(data) {
        return HTTP.put('profile/update-profile', data);
    },
};
