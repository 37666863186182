import store from '../store';

export default {
    pushNotifications(errorList) {
        store.commit('common/SET_NOTIFICATIONS', errorList);
    },
    showNotifications(data) {
        let errorList = [];
        if (data.status_code && data.status_code === 422) {
            if (data.message) {
                Object.keys(data.message).forEach((key) => {
                    errorList.push({ message: data.message[key][0], type: 'error' });
                });
            }
        } else if (data) {
            errorList.push({
                message: data.message,
                type: data.status_code >= 200 && data.status_code < 300 ? 'success' : 'error',
            });
        }
        this.pushNotifications(errorList);
    },
};
