<template>
    <v-row>
        <v-col cols="12" xs="12" sm="3" md="3">
            <v-switch
                label="Show Label"
                v-model="componentValues.is_show_label"
                hide-details
                class="mr-4 mt-0"
                dense
            ></v-switch>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="4">
            <v-switch
                label="Hide Component"
                v-model="componentValues.is_hide_component"
                hide-details
                class="mr-4 mt-0"
                dense
            ></v-switch>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'FileUpload',
    props: {
        isShowLabel: Boolean,
        isHideComponent: Boolean,
    },
    data() {
        return {
            componentValues: {
                is_show_label: true,
                is_hide_component: true,
            },
        };
    },
    methods: {
        setInitials() {
            this.componentValues.is_show_label = this.isShowLabel === true ? true : false;
            this.componentValues.is_hide_component = this.isHideComponent === true ? true : false;
        },
    },
    watch: {
        'componentValues.is_show_label'() {
            this.$emit('update-values', this.componentValues);
        },
        'componentValues.is_hide_component'() {
            this.$emit('update-values', this.componentValues);
        },
    },
    mounted() {
        this.setInitials();
    },
};
</script>

<style scoped></style>
