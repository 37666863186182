<template>
    <div>
        <v-row>
            <v-col cols="12" xs="12" sm="3" md="3">
                <v-checkbox label="Hidden" outlined hide-details dense class="mt-2"></v-checkbox>
            </v-col>
            <v-col cols="12" xs="12" sm="9" md="9">
                <v-text-field
                    label="Default Value"
                    solo
                    flat
                    outlined
                    hide-details
                    dense
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'Textfield',
};
</script>
