<template>
    <div>
        <v-container>
            <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
                <h1 class="primary--text">Manage Universities</h1>

                <div>
                    <v-btn
                        class="text-capitalize mr-2"
                        elevation="0"
                        v-on:click="exportUniversities"
                        outlined
                    >
                        Export
                    </v-btn>

                    <v-dialog v-model="importDialog" content-class="small-dialog">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="secondary"
                                dark
                                class="text-capitalize"
                                elevation="0"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Import
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title
                                class="d-flex justify-space-between align-center secondary-font"
                            >
                                <p class="h6 primary--text font-weight-bold mb-0">
                                    Import Universities
                                </p>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="ma-2"
                                    text
                                    icon
                                    color="primary"
                                    @click="(importDialog = false), (fileUpload = null)"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <p class="caption mb-0 ml-8">Universities Excel File</p>
                                <v-form ref="form" v-model="valid">
                                    <v-file-input
                                        type="file"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        hide-details
                                        outlined
                                        flat
                                        dense
                                        v-model="fileUpload"
                                        @change="readExcel()"
                                        :rules="[rules.required]"
                                        required
                                    ></v-file-input>
                                    <small class="red--text ml-8 secondary-font" v-if="invalidFile">
                                        Invalid FIle!
                                    </small>
                                    <div class="d-flex justify-end">
                                        <v-btn
                                            color="secondary"
                                            elevation="0"
                                            class="text-capitalize mt-2 text-end"
                                            large
                                            dark
                                            @click="uploadUniversities"
                                            :disabled="!valid"
                                        >
                                            Import Data
                                        </v-btn>
                                    </div>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </div>
            </div>

            <div>
                <v-radio-group
                    v-model="universityOption"
                    row
                    hide-details
                    @change="getUniversities"
                >
                    <v-radio label="Universities" value="universities"></v-radio>
                    <v-radio label="Competitors" value="competitors"></v-radio>
                </v-radio-group>
            </div>

            <v-card class="mx-auto rounded-lg mt-5" elevation="0">
                <v-card-text class="secondary-font pa-3">
                    <v-data-table
                        :headers="headers"
                        :items="universities"
                        class="elevation-0 noborder-table body-primary"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        :page.sync="page"
                    >
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip
                                small
                                :color="getColor(item.status)"
                                class="text-capitalize lighten-1"
                                dark
                            >
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>

            <div class="d-flex justify-end mt-2" v-if="pageCount">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="10"
                    class="secondary-font"
                ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
import commonService from '../../services/common-service';
import notificationsUtility from '../../utilities/notifications-utility';
import XLSX from 'xlsx';

export default {
    name: 'University',
    data() {
        return {
            importDialog: false,
            valid: false,
            invalidFile: false,

            rules: {
                required: (value) => !!value || 'This is required',
            },

            fileUpload: null,
            headers: [
                { text: 'University Name', value: 'name', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
            ],

            universities: [],
            uploadedUniversities: [],

            page: 1,
            pageCount: 0,

            universityOption: 'universities',
        };
    },
    methods: {
        getColor(status) {
            if (status == 'INACTIVE') return 'red';
            else return 'green';
        },

        getUniversities() {
            let payload = {
                status: 'all',
                universityOption: this.universityOption,
            };

            commonService
                .getUniversities(payload)
                .then((response) => {
                    this.universities = response.data;
                })
                .catch((error) => {
                    this.universities = [];
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        readExcel() {
            if (this.fileUpload) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    var data = new Uint8Array(e.target.result);
                    var workbook = XLSX.read(data, { type: 'array' });
                    let sheetName = workbook.SheetNames[0];
                    let worksheet = workbook.Sheets[sheetName];
                    this.uploadedUniversities = XLSX.utils.sheet_to_json(worksheet);
                };
                reader.readAsArrayBuffer(this.fileUpload);
            }
        },

        uploadUniversities() {
            if (
                this.uploadedUniversities.length > 0 &&
                this.uploadedUniversities[0]['University'] &&
                this.uploadedUniversities[0]['Status']
            ) {
                this.invalidFile = false;

                let payload = {
                    universities: this.uploadedUniversities,
                    competitor: this.universityOption === 'competitors' ? true : false,
                };

                commonService
                    .uploadUniversities(payload)
                    .then((response) => {
                        this.universities = response.data;
                        this.importDialog = false;
                        this.fileUpload = null;

                        notificationsUtility.pushNotifications([
                            {
                                message: 'Universities imported successfully.',
                                type: 'success',
                            },
                        ]);
                    })
                    .catch((error) => {
                        this.universities = [];
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            } else {
                this.invalidFile = true;
            }
        },

        exportUniversities() {
            let exportArray = [];

            this.universities.forEach((university) => {
                let data = {
                    University: university.name,
                    Status: university.status,
                };

                exportArray.push(data);
            });
            var universities = XLSX.utils.json_to_sheet(exportArray);

            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, universities, 'Universities');
            XLSX.writeFile(workbook, 'Universities.xlsx');
        },
    },
    mounted() {
        this.getUniversities();
    },
};
</script>
