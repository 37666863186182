<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="6">
                <v-card flat class="rounded-0">
                    <v-card-text class="pb-0">
                        <p class="text-h4 primary--text mb-10 text-center">Create Account</p>
                        <v-form ref="form" v-model="valid">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="6">
                                        <p class="caption mb-0 required">Email Address</p>
                                        <v-text-field
                                            solo
                                            flat
                                            outlined
                                            dense
                                            v-model="user.email"
                                            :rules="[rules.required, rules.emailValid]"
                                            required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6">
                                        <p class="caption mb-0 required">Confirm Email Address</p>
                                        <v-text-field
                                            solo
                                            flat
                                            outlined
                                            dense
                                            v-model="user.confirm_email"
                                            :rules="[
                                                rules.required,
                                                rules.emailValid,
                                                rules.emailConfirmation,
                                            ]"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" xs="12" sm="6">
                                        <p class="caption mb-0 required">First Name</p>
                                        <v-text-field
                                            solo
                                            flat
                                            outlined
                                            dense
                                            v-model="user.first_name"
                                            :rules="[rules.required]"
                                            required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6">
                                        <p class="caption mb-0 required">Last Name</p>
                                        <v-text-field
                                            solo
                                            flat
                                            outlined
                                            dense
                                            v-model="user.last_name"
                                            :rules="[rules.required]"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12">
                                        <p class="caption mb-0 required">Country of Origin</p>
                                        <v-autocomplete
                                            class="no-arrow"
                                            :items="countries"
                                            :search-input.sync="countrySearch"
                                            hide-no-data
                                            hide-selected
                                            item-text="name"
                                            item-value="id"
                                            placeholder="Start typing to Search"
                                            solo
                                            flat
                                            outlined
                                            dense
                                            return-object
                                            v-model="user.country"
                                            :rules="[rules.required]"
                                            required
                                            clearable
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-card
                                    flat
                                    class="rounded-lg overflow-y-auto"
                                    color="whiteSmoke"
                                    max-height="200"
                                >
                                    <v-card-text class="primary--text secondary-font">
                                        <p class="font-weight-bold subtitle-1">Data Protection</p>
                                        <p class="font-weight-bold subtitle-2 text-uppercase mb-0">
                                            01. IDENTIFICATION OF THE DOMAIN OWNER
                                        </p>
                                        <p class="text-justify">
                                            Following article 10 of Spanish law 34/2002, of July 11,
                                            concerning information society and e-commerce services,
                                            we state that the owner of the website is the Barcelona
                                            Graduate School of Economics, Private Foundation (BSE)
                                            based at C/Ramon Trias Fargas 25-27, Barcelona 08005,
                                            with VAT number: G64296569. Email contact: info@bse.eu
                                        </p>
                                        <p class="font-weight-bold subtitle-2 text-uppercase mb-0">
                                            02. RIGHT OF INFORMATION
                                        </p>
                                        <p class="text-justify mb-1">
                                            Following Spanish Law 03/2018 of December 5 , regarding
                                            the protection of personal data and the guarantee of
                                            digital rights, we inform you that the data supplied via
                                            the forms on the website will be incorporated in the
                                            same format as you present them to us within a Customer
                                            Relationship Management system of which BSE takes full
                                            responsibility.
                                            <br />
                                            The users of this data will be those who physically or
                                            legally are necessary for the service that is being
                                            requested by the supplier of the data. In all cases, the
                                            right to consult, access, change, or cancel will be
                                            respected following national laws.
                                        </p>
                                        <p class="font-weight-bold subtitle-2 text-uppercase mb-0">
                                            03. AIM
                                        </p>
                                        <p class="text-justify">
                                            The data supplied by the user will be used to manage
                                            their application and to keep the user permanently
                                            updated.
                                        </p>
                                        <p class="font-weight-bold subtitle-2 text-uppercase mb-0">
                                            04. CONSENT OF THE USER
                                        </p>
                                        <p class="text-justify">
                                            It is understood that the applicant accepts these
                                            conditions when they click on New User Registration and
                                            “Submit my application”. All personal data is kept
                                            securely.
                                        </p>
                                        <p class="font-weight-bold subtitle-2 text-uppercase mb-0">
                                            05. USER RIGHTS
                                        </p>
                                        <p class="text-justify">
                                            The users of this data will be those who physically or
                                            legally are necessary for the service that is being
                                            requested by the supplier of the data. In all cases, the
                                            right to consult, access, change, or cancel will be
                                            respected following national and European laws. Claims
                                            for access should be directed in writing to;
                                            <br />
                                            &#183; Legal Department <br />
                                            &#183; Barcelona School of Economics <br />
                                            <br />
                                            &#183; C/Ramon Trias Fargas 22-25 <br />
                                            &#183; 08005 Barcelona <br />
                                        </p>
                                        <p class="font-weight-bold subtitle-2 text-uppercase mb-0">
                                            06. SECURITY
                                        </p>
                                        <p class="text-justify">
                                            BSE has a security protocol in place that describes the
                                            security measures for protecting personal data. Data is
                                            protected following national and European legislation.
                                        </p>
                                    </v-card-text>
                                </v-card>

                                <v-checkbox
                                    label="I have read and agree to the terms of use"
                                    hide-details
                                    v-model="agreed"
                                    :rules="[rules.required]"
                                    required
                                ></v-checkbox>

                                <!-- Recaptcha -->
                                <vue-recaptcha
                                    v-if="agreed && !user.isGoogleBlocked"
                                    class="mt-4"
                                    :sitekey="siteKey"
                                    :loadRecaptchaScript="true"
                                    ref="recaptcha"
                                    @verify="onCaptchaVerified"
                                    @expired="onCaptchaExpired"
                                    type="invisible"
                                    recaptchaHost="www.recaptcha.net"
                                >
                                </vue-recaptcha>

                                <!-- End - Recaptcha -->

                                <p class="primary--text subtitle-2 mt-5">
                                    * You will be able to change your password when you log in
                                </p>
                                <v-btn
                                    color="secondary"
                                    elevation="0"
                                    class="text-capitalize mt-5 mb-5"
                                    large
                                    block
                                    :disabled="!valid || (!user.isGoogleBlocked && !user.recaptcha)"
                                    @click="handleRegister"
                                >
                                    Create Account
                                </v-btn>

                                <v-row v-if="validationErrors.length > 0">
                                    <v-col cols="12">
                                        <v-alert
                                            dense
                                            text
                                            color="red"
                                            outlined
                                            class="outlined-alert text-center secondary-font"
                                            v-for="(error, index) in validationErrors"
                                            :key="index"
                                        >
                                            {{ error }}
                                        </v-alert>
                                    </v-col>
                                </v-row>

                                <p class="primary--text mt-4 text-center secondary-font">
                                    Already have an account?
                                    <a
                                        v-on:click="gotoLogin"
                                        class="secondary--text text-decoration-none"
                                    >
                                        Sign In
                                    </a>
                                </p>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import commonService from '../../services/common-service';
import authService from '../../services/auth-service';
import notificationsUtility from '../../utilities/notifications-utility';
import VueRecaptcha from 'vue-recaptcha';
import Ping from 'ping-url';

export default {
    name: 'Register',
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    data() {
        return {
            logo_url: require('@/assets/logo/BSE_logo_horizontal_dark.png'),

            valid: false,
            validationErrors: [],

            rules: {
                required: (value) => !!value || 'This is required',
                emailValid: (value) => /.+@.+\..+/.test(value) || 'E-mail must be valid',
                emailConfirmation: (value) =>
                    value === this.user.email || 'Email confirmation does not match.',
            },

            user: {
                email: null,
                confirm_email: null,
                first_name: null,
                last_name: null,
                country: null,
                recaptcha: null,
                isGoogleBlocked: false,
            },

            agreed: false,

            countries: [],
            isCountryLoading: false,
            countrySearch: null,

            siteKey: process.env.RECAPTCHA_SITE_KEY,
        };
    },
    watch: {
        countrySearch() {
            this.searchCountries();
        },
    },
    computed: {
        utmData() {
            return this.$store.state.auth.utmData;
        },
        site() {
            return this.$store.state.common.site;
        },
    },
    methods: {
        gotoLogin() {
            this.$router.push(`/${this.site}/login`);
        },

        searchCountries() {
            if (this.isCountryLoading) return;

            this.isCountryLoading = true;

            let payload = {
                query: this.countrySearch,
                conditions: null,
            };

            commonService
                .post(`countries`, payload)
                .then((response) => {
                    this.isCountryLoading = false;
                    this.countries = response.data.list;
                })
                .catch((error) => {
                    this.isCountryLoading = false;
                    this.countries = [];
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },

        handleRegister() {
            if (this.valid) {
                authService
                    .register({ ...this.user, ...this.utmData })
                    .then((response) => {
                        notificationsUtility.showNotifications(response.data);
                        this.$store.commit('auth/setUtmData', {});
                        this.$router.push(`/${this.site}/account/create-response`);
                    })
                    .catch((error) => {
                        if (
                            error.response.data.status_code &&
                            error.response.data.status_code === 422
                        ) {
                            notificationsUtility.pushNotifications(
                                error.response.data.errors.map((error) => ({
                                    message: error,
                                    type: 'error',
                                }))
                            );
                        } else if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },

        onCaptchaVerified(recaptchaToken) {
            this.user.recaptcha = recaptchaToken;
            this.validateCaptcha = true;
        },

        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
            this.user.recaptcha = null;
        },
    },
    mounted() {
        Ping.check('https://www.google.com/').then(
            (res) => {
                this.user.isGoogleBlocked = !res.status;
            },
            (res) => {
                this.user.isGoogleBlocked = true;
            }
        );
    },
};
</script>
