import { HTTP } from './http-common';

export default {
    getUniversities(payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.universityOption, payload)
                .then((res) => {
                    if (res && res.data && res.data.status_code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => reject(err));
        });
    },

    uploadUniversities(payload) {
        return new Promise((resolve, reject) => {
            HTTP.post('university/upload', payload)
                .then((res) => {
                    if (res && res.data && res.data.status_code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => reject(err));
        });
    },

    get(url, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(url, payload)
                .then((res) => {
                    if (res && res.data && res.data.status_code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => reject(err));
        });
    },

    post(url, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(url, payload)
                .then((res) => {
                    if (res && res.data && res.data.status_code === 200) {
                        resolve(res.data);
                    } else {
                        reject(res.data);
                    }
                })
                .catch((err) => reject(err));
        });
    },
    saveSettings(payload, headers) {
        return HTTP.post('settings/general', payload, headers);
    },
    loadSettings() {
        return HTTP.get('settings/general');
    },
    getSiteSpecificJourneyCount() {
        return HTTP.get('common/get-site-specific-journey-count');
    },
};
