<template>
    <v-container>
        <div class="d-sm-flex d-xs-block justify-space-between align-center text-center">
            <h1 class="primary--text">Manage Application Journey</h1>
            <v-dialog v-model="dialog" persistent content-class="small-dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="secondary"
                        dark
                        class="text-capitalize mt-2"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="createJourney"
                    >
                        Create New Journey
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-space-between align-center secondary-font">
                        <p class="h6 primary--text font-weight-bold mb-0">{{ dialogTitle }}</p>
                        <v-spacer></v-spacer>
                        <v-btn class="ma-2" text icon color="primary" @click="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card-text class="secondary-font pb-0">
                            <v-row>
                                <v-col cols="12" class="pb-0 mb-0">
                                    <p class="caption mb-0">Journey name</p>
                                    <v-text-field
                                        v-model="journey.journey_name"
                                        dense
                                        outlined
                                        :rules="[(v) => !!v || 'Journey name is required']"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" class="pb-0 mb-0">
                                    <p class="caption mb-0">Period</p>
                                    <v-select
                                        :items="academicPeriods"
                                        v-model="journey.academic_year_id"
                                        @change="getProgramNames"
                                        :rules="[(v) => !!v || 'Period is required']"
                                        dense
                                        outlined
                                        item-text="period"
                                        item-value="uuid"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" class="pb-0 mb-0">
                                    <p class="caption mb-0">Journey Type</p>
                                    <v-select
                                        :items="journeyTypes"
                                        v-model="journey.journey_type"
                                        @change="getProgramNames"
                                        :rules="[(v) => !!v || 'Journey Type is required']"
                                        dense
                                        outlined
                                        item-text="journey_type"
                                        item-value="uuid"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    v-if="journeyPrograms.length > 0"
                                    class="pb-0 mb-0"
                                >
                                    <p class="caption mb-0">Program Name</p>
                                    <v-select
                                        :items="journeyPrograms"
                                        v-model="journey.program_id"
                                        dense
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" class="pb-0 mb-0">
                                    <p class="caption mb-0">Entity Type</p>
                                    <v-select
                                        :items="entityTypes"
                                        v-model="journey.entity_type_id"
                                        :rules="[(v) => !!v || 'Entity Type is required']"
                                        dense
                                        outlined
                                        item-text="name"
                                        item-value="uuid"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" class="pb-0 mb-0">
                                    <p class="caption mb-0">Theme</p>
                                    <v-select
                                        :items="themeTypes"
                                        v-model="journey.theme"
                                        :rules="[(v) => !!v || 'Entity Type is required']"
                                        dense
                                        outlined
                                        item-text="text"
                                        item-value="color"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" class="pt-0 mt-0">
                                    <v-checkbox
                                        label="Import Journey"
                                        class="pa-0 mb-3"
                                        hide-details
                                        v-model="copiedJourneyArea"
                                    ></v-checkbox>
                                    <div v-if="copiedJourneyArea">
                                        <v-textarea
                                            v-model="copiedJourney"
                                            dense
                                            outlined
                                            :rules="[(v) => !!v || 'Journey json is required']"
                                        >
                                        </v-textarea>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" xs="12" sm="4">
                                    <v-switch
                                        v-if="isUserAdmin"
                                        label="Admin Only"
                                        v-model="isAdmin"
                                        flat
                                        hide-details
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" xs="12" sm="8">
                                    <v-switch
                                        v-if="isMultipleParticipation"
                                        label="Allow multiple submissions"
                                        v-model="journey.is_allow_for_multiple_submit"
                                        flat
                                        hide-details
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary mr-2" @click="save" elevation="0"> Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>

        <v-card class="mx-auto mt-5 rounded-12" flat>
            <v-card-text>
                <v-text-field
                    label="Search ..."
                    @keyup="searchJourney"
                    v-model="keyword"
                    on
                    solo
                    flat
                    outlined
                    hide-details
                    dense
                    clearable
                    prepend-inner-icon="mdi-magnify"
                    @click:clear="getJourneys"
                ></v-text-field>
            </v-card-text>
        </v-card>

        <v-card class="mx-auto mt-5 rounded-12" flat>
            <v-card-text>
                <v-checkbox
                    v-show="false"
                    label="Show Deleted Journeys"
                    v-model="showDeleted"
                    color="primary"
                    hide-details
                    @change="showDeletedList"
                    class="lable-primary-color mt-0 mb-3"
                ></v-checkbox>
                <v-list dense class="draggable-list" v-if="journeys.length > 0">
                    <v-list-item v-for="(journey, i) in journeys" :key="i">
                        <v-list-item-content>
                            <v-list-item-title>
                                <div
                                    class="d-sm-flex d-xs-block justify-space-between align-center"
                                >
                                    <div class="d-flex align-center mt-1">
                                        <div class="mr-3" v-if="!showDeleted">
                                            <v-btn
                                                :disabled="i === 0"
                                                small
                                                text
                                                icon
                                                color="darkGray"
                                                @click="changeOrder('up', journey.uuid)"
                                            >
                                                <v-icon small>mdi-arrow-up</v-icon>
                                            </v-btn>
                                            <v-btn
                                                small
                                                text
                                                icon
                                                color="darkGray"
                                                @click="changeOrder('down', journey.uuid)"
                                            >
                                                <v-icon small>mdi-arrow-down</v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="d-flex">
                                            <span class="circle mr-3"> {{ journey.order }} </span>
                                            <span class="font-weight-black">
                                                {{ journey.journey_name }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="mt-1" v-if="showDeleted">
                                        <v-btn
                                            class="ml-8"
                                            small
                                            color="secondary"
                                            @click="restoreJourney(journey)"
                                        >
                                            Restore
                                        </v-btn>
                                    </div>

                                    <div class="mt-1" v-if="!showDeleted">
                                        <v-btn
                                            class="ml-8"
                                            small
                                            color="secondary"
                                            @click="copyJourney(journey)"
                                        >
                                            Copy Journey
                                        </v-btn>
                                        <v-btn
                                            class="ml-4"
                                            small
                                            text
                                            icon
                                            color="darkGray"
                                            @click="cloneJourney(journey)"
                                        >
                                            <v-icon small>mdi-content-copy</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            text
                                            icon
                                            color="darkGray"
                                            :to="`/${site}/journey/edit/${journey.uuid}`"
                                        >
                                            <v-icon small>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            text
                                            icon
                                            color="darkGray"
                                            @click="deleteJourney(journey.uuid)"
                                        >
                                            <v-icon small>mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <div class="text-center" v-if="journeys.length === 0">
                    <p class="text-center">No Active Journeys right now.</p>
                </div>
            </v-card-text>
        </v-card>
        <delete-alert ref="confirm" />
        <CloneJourneyPopup
            :dialog="cloneJourneyDialog"
            :journeyTypes="journeyTypes"
            :academicPeriods="academicPeriods"
            :entityTypes="entityTypes"
            :selectedJourney="selectedJourney"
            :handleClose="closeCloneJourneyDialog"
            :handleSave="saveCloneJourney"
        />
    </v-container>
</template>

<script>
import JourneyService from '../../services/journey-service';
import commonData from '../../constant/common-data';
import SectionModel from '../../models/Component';
import DeleteAlert from '../../components/alert/ConfirmAlert.componet';
import notificationsUtility from '../../utilities/notifications-utility';
import CloneJourneyPopup from '../../components/journey/CloneJourneyPopup.component';
import journeyService from '../../services/journey-service';
export default {
    name: 'Journey',
    components: {
        DeleteAlert,
        CloneJourneyPopup,
    },
    data() {
        return {
            dialog: false,
            showDeleted: false,
            keyword: '',
            cloneJourneyDialog: false,
            dialogDelete: false,
            isAdmin: true,
            journey: {
                form_data: [],
                journey_name: '',
                academic_year_id: null,
                program_id: null,
                journey_type: null,
                entity_type_id: null,
                status: 'INACTIVE',
                theme: null,
                is_allow_for_only_applied: false,
                is_allow_for_multiple_submit: false,
            },
            copiedJourney: '',
            copiedJourneyArea: false,
            defaultJourney: {
                form_data: [],
                journey_name: '',
                academic_year_id: null,
                program_id: null,
                journey_type: null,
                entity_type_id: null,
                status: 'INACTIVE',
                theme: null,
                is_allow_for_only_applied: false,
                is_allow_for_multiple_submit: false,
            },
            dialogTitle: 'Create Journey',
            isEdited: true,
            journeys: [],
            journeyTypes: [],
            journeyPrograms: [],
            themeTypes: commonData.themes,
            academicPeriods: [],
            entityTypes: [],
            selectedItem: 1,
            valid: true,
            selectedJourney: null,
            deletedJourneys: null,
            isMultipleParticipation: false,
        };
    },
    mounted() {
        this.getJourneys();
        this.getJourneyTypes();
        this.getAcademicYears();
        this.getEntityTypes();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        isAdmin() {
            this.journey.status = this.isAdmin === true ? 'INACTIVE' : 'ACTIVE';
        },
        selectedJourney() {
            this.cloneJourneyDialog = this.selectedJourney !== null;
        },
        journey: {
            handler() {
                let selectedEntityType = this.entityTypes.filter(
                    (entityType) => entityType.uuid == this.journey.entity_type_id
                );
                this.isMultipleParticipation =
                    selectedEntityType && selectedEntityType.length ? true : false;

                if (!this.isMultipleApplication) {
                    this.journey.is_allow_for_multiple_submit = false;
                }
            },
            deep: true,
        },
    },
    methods: {
        copyJourney(journey) {
            try {
                navigator.clipboard.writeText(journey.form_data);
                notificationsUtility.pushNotifications([
                    {
                        message: 'Journey Copied',
                        type: 'success',
                    },
                ]);
            } catch (e) {
                notificationsUtility.pushNotifications([
                    {
                        message: 'Oops, unable to copy journey',
                        type: 'error',
                    },
                ]);
            }
            navigator.clipboard.writeText(journey.form_data);
        },

        getProgramNames() {
            if (this.journey.academic_year_id !== null && this.journey.journey_type !== null) {
                this.journey.program_id = null;
                const params = {
                    academicYear: this.journey.academic_year_id,
                    journeyTypeId: this.journey.journey_type,
                };
                JourneyService.getPrograms(params)
                    .then((response) => {
                        this.journeyPrograms = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },
        restoreJourney(journey) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to restore this journey ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        JourneyService.restoreDeleteJourney(journey)
                            .then((response) => {
                                if (response.data.meta.status_code === 200) {
                                    this.journeys = response.data.data;
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Journey restored successfully',
                                            type: 'success',
                                        },
                                    ]);
                                } else {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Journey restore failed.',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            })
                            .catch((error) => {
                                if (error.response.status !== 503) {
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    }
                });
        },
        showDeletedList() {
            this.keyword = '';
            if (this.showDeleted) {
                JourneyService.getDeletedJourneys(this.keyword)
                    .then((response) => {
                        this.journeys = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            } else {
                this.getJourneys();
            }
        },
        searchJourney() {
            if (this.showDeleted) {
                JourneyService.searchDeletedJourneys(this.keyword)
                    .then((response) => {
                        this.journeys = response.data.data;
                        this.$store.commit('journey/setJourneysList', response.data.data);
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            } else {
                JourneyService.searchJourneys(this.keyword)
                    .then((response) => {
                        this.journeys = response.data.data;
                        this.$store.commit('journey/setJourneysList', response.data.data);
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },
        getJourneys() {
            console.log('getJourneys');
            JourneyService.getJourneys()
                .then((response) => {
                    this.journeys = response.data.data;
                    this.$store.commit('journey/setJourneysList', response.data.data);
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        getJourneyTypes() {
            JourneyService.getJourneyTypes()
                .then((response) => {
                    this.journeyTypes = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        // TODO IMPLEMENT
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        getAcademicYears() {
            JourneyService.getAcademicYears()
                .then((response) => {
                    this.academicPeriods = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        // TODO IMPLEMENT
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        getEntityTypes() {
            JourneyService.getEntityTypes()
                .then((response) => {
                    this.entityTypes = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        // TODO IMPLEMENT
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        getColor(status) {
            if (status == 'pending') return 'orange';
            else return 'green';
        },

        close() {
            this.dialog = false;
            this.journeyPrograms = [];
            this.copiedJourney = '';
            this.copiedJourneyArea = false;
            this.$refs.form.resetValidation();
            (this.isAdmin = true),
                this.$nextTick(() => {
                    this.journey = Object.assign({}, this.defaultJourney);
                });
        },
        createJourney() {
            this.dialogTitle = 'Create Journey';
            this.isEdited = false;
        },
        checkJourneyJson() {
            try {
                JSON.parse(this.copiedJourney);
            } catch (e) {
                notificationsUtility.pushNotifications([
                    {
                        message: 'Not a valid json',
                        type: 'error',
                    },
                ]);
                return false;
            }
            return true;
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.isEdited) {
                    JourneyService.updateJourney(this.journey)
                        .then((response) => {
                            if (response.data.data) {
                                const journeyIndex = this.journeys.findIndex(
                                    (x) => x.uuid === response.data.data.uuid
                                );
                                this.journeys[journeyIndex] = this.journey;
                                this.dialog = false;
                            }
                        })
                        .catch((error) => {
                            if (error.response.status !== 503) {
                                // TODO IMPLEMENT
                                notificationsUtility.pushNotifications([
                                    {
                                        message:
                                            'Something went wrong. Please try contact server admin',
                                        type: 'error',
                                    },
                                ]);
                            }
                        });
                } else {
                    if (this.copiedJourneyArea) {
                        let check = this.checkJourneyJson();
                        if (check === true) {
                            this.journey.form_data = JSON.parse(this.copiedJourney);
                            this.sendJourneytoSave();
                        }
                    } else {
                        let defaultEntity = this.entityTypes.filter((item) => {
                            return item.uuid === this.journey.entity_type_id;
                        });
                        let form_data = {
                            status: 'ACTIVE',
                            is_enable: true,
                            is_hidden: false,
                            sections: [new SectionModel()],
                            tab_name: 'untitledTab',
                            tab_display_name: 'Untitled Tab',
                            read_only: false,
                            is_submit: false,
                            button_text: 'Save & Next',
                            eligible_submit: false,
                            tab_entity: defaultEntity[0].entity_type,
                            is_required: false,
                            is_advance_condition: false,
                            advance_validation_rules: {
                                is_required: false,
                                field_type: null,
                                enability_rules: [],
                                visibility_rules: [],
                            },
                        };
                        form_data.sections[0].section_entity = defaultEntity[0].entity_type;
                        form_data.sections[0].components[0].component_entity =
                            defaultEntity[0].entity_type;
                        this.journey.form_data.push(form_data);
                        this.sendJourneytoSave();
                    }
                }
            }
        },
        sendJourneytoSave() {
            JourneyService.saveJourney(this.journey)
                .then((response) => {
                    if (response.data.data) {
                        notificationsUtility.pushNotifications([
                            { message: 'Journey created successfully', type: 'success' },
                        ]);
                        this.journeys = [];
                        this.getJourneys();
                        this.$refs.form.resetValidation();
                        this.dialog = false;
                    }
                })
                .catch((error) => {
                    if (error.response.data.status === 0) {
                        notificationsUtility.pushNotifications([
                            {
                                message: error.response.data.message,
                                type: 'error',
                            },
                        ]);
                    } else if (error.response.status !== 503) {
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        deleteJourney(uuid) {
            this.$refs.confirm
                .open('Are you sure', 'Do you want to delete this journey ?', {
                    color: 'red',
                })
                .then((confirm) => {
                    if (confirm) {
                        JourneyService.deleteJourney(uuid)
                            .then((response) => {
                                if (response.data.status === 1) {
                                    this.journeys = [];
                                    this.getJourneys();
                                    notificationsUtility.pushNotifications([
                                        {
                                            message: 'Journey deleted successfully',
                                            type: 'success',
                                        },
                                    ]);
                                }
                            })
                            .catch((error) => {
                                if (error.response.status !== 503) {
                                    // TODO IMPLEMENT
                                    notificationsUtility.pushNotifications([
                                        {
                                            message:
                                                'Something went wrong. Please try contact server admin',
                                            type: 'error',
                                        },
                                    ]);
                                }
                            });
                    }
                });
        },
        changeOrder(action, uuid) {
            JourneyService.changeOrder(action, uuid)
                .then((response) => {
                    if (response.data.data) {
                        this.journeys = response.data.data;
                    }
                })
                .catch(() => {
                    // TODO IMPLEMENT
                });
        },
        cloneJourney(journey) {
            this.selectedJourney = journey;
        },
        closeCloneJourneyDialog() {
            this.selectedJourney = null;
        },
        saveCloneJourney(journey) {
            journeyService
                .cloneJourney(journey)
                .then((response) => {
                    this.$store.commit('journey/addNewJourney', response.data.data);
                    notificationsUtility.showNotifications(response.data.meta);
                    this.closeCloneJourneyDialog();
                })
                .catch((error) => {
                    notificationsUtility.showNotifications(error.response.data);
                });
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isUserAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return ['ADMIN'].includes(this.currentUser.role[0].name);
            }

            return false;
        },
        sites() {
            return this.$store.state.common.sites;
        },
        site() {
            return this.$store.state.common.site;
        },
    },
};
</script>
