<template>
    <v-dialog :value="dialog" :width="dialogWidth" @click:outside="resetForm">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 primary--text font-weight-black mb-0">Create Email Notification</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="resetForm">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <EmailNotificationForm :handleSave="handleSave" :notification="formData" />
        </v-card>
    </v-dialog>
</template>

<script>
import EmailNotificationForm from './EmailNotificationForm.component.vue';
import NotificationService from '../../services/notification-service';
import notificationsUtility from '../../utilities/notifications-utility';

export default {
    name: 'CreateEmailNotificationPopUp',
    components: {
        EmailNotificationForm,
    },
    props: ['dialog', 'handleClose'],
    data() {
        return {
            dialogWidth: 1000,
            formData: {
                module: null,
                name: null,
                sub_module: null,
                subject: null,
                email_body: null,
                signature: null,
            },
        };
    },
    methods: {
        handleSave() {
            NotificationService.addEmailFormat({
                ...this.formData,
                module: this.formData.module.value,
                sub_module: this.formData.sub_module.value,
            })
                .then((response) => {
                    this.$store.commit('notification/addEmailFormat', response.data.data);
                    notificationsUtility.showNotifications(response.data.meta);
                    this.resetForm();
                })
                .catch((error) => {
                    notificationsUtility.showNotifications(error.response.data);
                });
        },
        resetForm() {
            Object.assign(this.$data, this.$options.data());
            this.handleClose();
        },
    },
};
</script>
