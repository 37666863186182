<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-combobox
            v-if="component.is_frontend_insertion"
            :ref="component.component_name"
            :items="componentData"
            :search-input.sync="search"
            hide-selected
            persistent-hint
            item-text="name"
            item-value="name"
            solo
            flat
            outlined
            dense
            @change="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            clearable
            :disabled="enability"
            :placeholder="component.component_placeholder"
            :value="setDefaultValue(component.component_default_value)"
        >
            <!-- <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            No results matching " <strong>{{ search }}</strong> ". Press
                            <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template> -->
        </v-combobox>

        <v-select
            v-else
            :ref="component.component_name"
            :items="componentData"
            item-text="name"
            item-value="name"
            solo
            flat
            outlined
            dense
            @change="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            clearable
            :disabled="enability"
            :placeholder="component.component_placeholder"
            :value="setDefaultValue(component.component_default_value)"
        ></v-select>
    </div>
</template>

<script>
import commonService from '../../../services/common-service';

export default {
    name: 'CompetitorComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            componentData: [],
            search: null,
        };
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        setDefaultValue(defaultValue) {
            if (this.componentData && this.componentData.length > 0) {
                let filteredValue = this.componentData.filter((value) => {
                    return value.name === defaultValue;
                });

                if (filteredValue && filteredValue.length > 0) {
                    return filteredValue && filteredValue.length > 0 ? filteredValue[0] : null;
                }
            }

            return null;
        },

        getComponentData() {
            commonService
                .get(this.component.db_field, {})
                .then((response) => {
                    this.componentData = response.data;
                })
                .catch(() => {
                    this.componentData = [];
                });
        },

        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },

        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },
    mounted() {
        if (this.component && this.component.db_field) {
            this.getComponentData();
        }
    },
};
</script>
