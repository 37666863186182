import AuthService from '../../../services/auth-service';

export default {
    login({ commit }, payload) {
        return AuthService.login(payload).then(
            (user) => {
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            (error) => {
                commit('loginFailure');
                return Promise.reject(error);
            }
        );
    },
    logout({ commit }) {
        AuthService.logout();
        commit('logout');
    },
    register({ commit }, payload) {
        return AuthService.register(payload).then(
            (response) => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            },
            (error) => {
                commit('registerFailure');
                return Promise.reject(error);
            }
        );
    },
    changeUser({ commit }, payload) {
        commit('loginAsUser', payload);
    },
};
