<template>
    <div class="d-flex section-card">
        <v-card class="rounded-12 section-card" flat outlined>
            <v-card-text class="px-6">
                <v-row>
                    <v-col cols="12" xs="12" sm="7" md="7">
                        <p class="caption mb-0">Untitled Field</p>
                        <v-text-field solo flat outlined hide-details dense></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="5">
                        <p class="caption mb-0">Input Type</p>
                        <v-select
                            v-model="inputType"
                            :items="inputTypes"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="inputType !== 'Text editor'">
                    <v-col cols="12" xs="12" sm="7" md="7">
                        <p class="caption mb-0">Label</p>
                        <v-text-field solo flat outlined hide-details dense></v-text-field>
                    </v-col>
                    <v-col cols="12" xs="12" sm="5" md="5">
                        <p class="caption mb-0">Placeholder</p>
                        <v-text-field solo flat outlined hide-details dense></v-text-field>
                    </v-col>
                </v-row>

                <Dropdown v-if="inputType == 'Dropdown'" />
                <Textfield v-if="inputType == 'Textfield'" />
                <ExistingDatabase v-if="inputType == 'Existing database'" />
                <TextEditor v-if="inputType == 'Text editor'" />

                <Conditions v-if="inputType !== 'Text editor'" />
            </v-card-text>
        </v-card>
        <div class="floating-icons">
            <v-card class="rounded-12" flat>
                <v-card-text class="pa-1 d-flex flex-column">
                    <v-btn text icon color="darkGray">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="darkGray">
                        <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="darkGray">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="darkGray">
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import Dropdown from './input-types/Dropdown';
import Textfield from './input-types/Textfield';
import Conditions from './input-types/Conditions';
import ExistingDatabase from './input-types/ExistingDatabase';
import TextEditor from './input-types/TextEditor';

export default {
    name: 'JourneySection',
    components: {
        Dropdown,
        Textfield,
        Conditions,
        ExistingDatabase,
        TextEditor,
    },
    data() {
        return {
            tab: null,
            inputTypes: [
                'Dropdown',
                'Checkbox',
                'Radio',
                'Date',
                'Time',
                'Textfield',
                'Textarea',
                'File upload',
                'Text editor',
                'Existing database',
                'CRM dropdown',
            ],
            inputType: 'Dropdown',
        };
    },
};
</script>
