<template>
    <div>
        <div class="d-none d-sm-block">
            <div class="d-flex justify-space-between align-center">
                <a @click="triggerGotoHome" v-show="logoUrl">
                    <img :src="logoUrl" alt="logo" height="70" class="main-logo" />
                </a>
                <p class="display-1 primary--text mb-0" color="primary">{{ applicationHeading }}</p>
            </div>
        </div>
        <div class="d-sm-none">
            <div class="d-flex flex-column align-center">
                <img :src="logoUrl" alt="logo" height="70" class="main-logo" v-show="logoUrl" />
                <p class="display-1 primary--text mt-3 mb-10" color="primary">
                    {{ applicationHeading }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppBarCommon',
    props: {
        logoUrl: {
            type: String,
            default: null,
        },
    },
    methods: {
        triggerGotoHome() {
            this.$emit('gotoHome');
        },
    },
    computed: {
        applicationHeading() {
            const applicationHeading = this.$store.getters['common/applicationHeading'];
            const isSystemSettingsLoaded = this.$store.state.common.isSystemSettingsLoaded;
            return isSystemSettingsLoaded
                ? applicationHeading && applicationHeading.value !== ''
                    ? applicationHeading.value
                    : 'Application System'
                : undefined;
        },
    },
};
</script>
