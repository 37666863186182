<template>
    <div>
        <input-label :component="component" :isRequired="isRequired" />

        <v-text-field
            v-bind:class="{ 'mt-5': !component.is_show_label }"
            :ref="component.component_name"
            solo
            flat
            outlined
            dense
            v-on:input="inputChanged"
            :rules="!$attrs.hidden && !enability ? validations : []"
            :required="isRequired"
            :value="component.component_default_value"
            :disabled="enability"
            :placeholder="component.component_placeholder"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    name: 'TextFieldComponent',
    props: {
        component: {
            type: [Object, Array],
            default: null,
        },
        validations: {
            type: [Object, Array],
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        enability: {
            type: Boolean,
            default: true,
        },
        visibility: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
    watch: {
        enability() {
            this.appearanceInputChanged();
        },
        visibility() {
            this.appearanceInputChanged();
        },
    },
    methods: {
        inputChanged() {
            let inputValue = {
                key: this.component.component_name,
                value: this.$refs[this.component.component_name]['internalValue'],
                is_disable: this.enability,
            };

            this.$emit('inputChanged', inputValue);
            this.appearanceInputChanged();
        },
        appearanceInputChanged() {
            let conditions = {
                component_name: this.component.component_name,
                is_disable: this.enability,
                is_hidden: this.visibility,
                value: this.$refs[this.component.component_name]['internalValue'],
            };

            this.$emit('appearanceInputChanged', conditions);
        },
    },

    mounted() {
        this.appearanceInputChanged();
    },
};
</script>
