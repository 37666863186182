<template>
    <v-container class="boundry height100">
        <v-row class="d-flex align-center justify-center height100">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="4" class="primary--text text-center">
                <v-icon class="icon-200 mb-8" color="success">
                    mdi-checkbox-marked-circle-outline
                </v-icon>
                <br />
                <p class="display-1">Password Reset!</p>
                <p class="secondary-font">Your new password has been sent to {{ email }}.</p>
                <v-btn
                    color="secondary"
                    elevation="0"
                    class="text-capitalize"
                    large
                    :to="`/${site}/login`"
                >
                    Sign In
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'PasswordResetResponse',
    data() {
        return {
            logo_url: require('@/assets/logo/BSE_logo_horizontal_dark.png'),
        };
    },
    computed: {
        email() {
            return this.$store.state.auth.passwordResetEmail;
        },
    },
};
</script>
